import React from 'react';
import ReactDOM from 'react-dom';
import localforage from 'localforage';

import './index.css';

import App from './App';

import { createStore } from "./model/store"
import { getSubDomain } from './utils/global';

const store = createStore({ key: '' });
/*
store.setSize(300, 460);

store.addPage();
store.activePage.addElement({
  type: 'text',
  text: 'Polotno tips',
  x: 50,
  y: 100,
  fontSize: 30,
  width: 200,
  align: 'center',
  fontFamily: 'Amatic SC',
});

store.activePage.addElement({
  type: 'text',
  text: 'by Anton Lavrenov',
  x: 50,
  y: 350,
  fontSize: 20,
  width: 200,
  align: 'center',
  fontFamily: 'Amatic SC',
});
*/
window.store = store;

var stateString = 'pure-electric-state';

var sub = getSubDomain();
if(sub=="daikindesign") stateString = 'pe-daikindesign';
if(sub=="batteriesdesign") stateString = 'pe-batteriesdesign';
if(sub=="solargrounddesign") stateString = 'pe-solargroundesign';
if(sub=="evchargingdesign") stateString = 'pe-evchargingdesign';

localforage.getItem(stateString, function (err, json) {
  if (json) {
    store.loadJSON(json);
  }
  if (!store.pages.length) {
    store.addPage();
  }
});

store.on('change', () => {
  try {
    const json = store.toJSON();
    localforage.setItem(stateString, json);
  } catch (error) {
    console.error(error);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById('root')
);
