import Konva from "konva";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Html } from "react-konva-utils";
import { decrementLoader, incrementLoader } from "../utils/loader";
import { applyFilter } from "./apply-filters";
import { Highlighter } from "./highlighter";
import { useFadeIn } from "./use-fadein";
import { Group, Text } from 'react-konva';
import { StoreType, TextElementType } from '../model/store';
/*type ShapeProps = {
    store: StoreType;
    element: TextElementType;
};*/
const styleNode = document.createElement("style");
styleNode.type = "text/css";
document.head.appendChild(styleNode);

const initialStyles = {
    border: "none",
    padding: "0px",
    overflow: "hidden",
    background: "none",
    outline: "none",
    resize: "none" ,
    overflowWrap: "break-word" ,
    whiteSpace: "normal",
    userSelect: "text" ,
    wordBreak: "normal"
}

const TextInput = observer(
    ({ textNodeRef, element, onBlur, selectAll, cursorPosition } ) => {
        const [style, setStyle] = useState(initialStyles);
        const nodeRef = textNodeRef.current;
        useLayoutEffect((() => {
            const eleStyle  = {};
            eleStyle.width = nodeRef.width() - 2 * nodeRef.padding() + "px", 
            eleStyle.height = nodeRef.height() - 2 * nodeRef.padding() + 10 + "px", 
            eleStyle.fontSize = nodeRef.fontSize() + "px", 
            eleStyle.lineHeight = nodeRef.lineHeight() + .01, 
            eleStyle.fontFamily = '"' + nodeRef.fontFamily() + '"', 
            eleStyle.textAlign = nodeRef.align(), 
            eleStyle.color = nodeRef.fill();
            //@ts-ignore
            const xt = `\n        .polotno-input::placeholder {\n          color: ${style.color};\n          opacity: 0.6;\n        }\n      `;
            styleNode.innerHTML = "", 
            styleNode.appendChild(document.createTextNode(element)), 
            JSON.stringify(eleStyle) !== JSON.stringify(style) && setStyle(eleStyle)
        }))
        const selection = useRef(null);
        useEffect((() => {
            var current;
            const selectionCurrent = selection.current;
            if (!selectionCurrent) return;
            null === (current = selection.current) || void 0 === current || current.focus();
            const r = cursorPosition || selectionCurrent.value.length;
            selectionCurrent.selectionStart = selectionCurrent.selectionEnd = r, 
            selectAll && (null == selectionCurrent || selectionCurrent.select(), 
            document.execCommand("selectAll", !1, null))
        }), []);

        return <Html><textarea
            className = "polotno-input"
            ref= {selection}
            style= {{...initialStyles, ...style}}
            value= {element.text}
            onChange= {e => {
                element.set({
                    text: e.target.value
                })
            }}
            placeholder= {element.placeholder}
            onBlur= {onBlur}
        /></Html>
})

const useEditor = editor => {
    const [enable, setEnable] = useState(false), 
    selectAll = useRef(!1);
    useEffect((() => {
        var enabled = true;
        return setTimeout((() => {
            enabled && (editor._editModeEnabled && (selectAll.current = !0), 
            setEnable(true), 
            setTimeout((() => {
                selectAll.current = !1
            }), 50))
        }), 50), 
            () => {
            enabled = false
        }
    }), []);
    return {
        editorEnabled: enable && editor._editModeEnabled,
        selectAll: selectAll.current
    }
}

const useFontLoader = (e, t) => {
    const [r, n] = useState(!1);
    return useLayoutEffect((() => {
        let o = !0;
        return (async () => {
            r && n(!1), 
            incrementLoader(), 
            await e.loadFont(t), Konva.Util.requestAnimFrame(decrementLoader), 
            o && n(!0)
        })(), 
        () => {
            o = !1
        }
    }), [t]), [r]
}

const getLineHeight = ({
    fontLoaded: e,
    fontFamily: t,
    fontSize: r,
    lineHeight: n
}) => React.useMemo((() => {
    if ("number" == typeof n) return n;
    const e = document.createElement("div");
    e.style.fontFamily = t, e.style.fontSize = r + "px", e.style.lineHeight = n, e.innerText = "Test text", document.body.appendChild(e);
    const o = e.offsetHeight;
    return document.body.removeChild(e), o / r
}), [e, t, r, n]);

function getRelativePointerPosition(e) {
    var t = e.getAbsoluteTransform().copy();
    t.invert();
    var r = e.getStage().getPointerPosition();
    return t.point(r)
}

function getCursorPosition(e) {
    var t;
    const r = e.target,
        n = getRelativePointerPosition(r),
        o = r.textArr,
        a = Math.floor(n.y / (r.fontSize() * r.lineHeight())),
        i = o.slice(0, a).reduce(((e, t) => e + t.text.length), a),
        l = null !== (t = o[a]) && void 0 !== t ? t : o[0];
    let s = 0;
    "right" === r.align() ? s = r.width() - l.width : "center" === r.align() && (s = r.width() / 2 - l.width / 2);
    return i + Math.round((n.x - s) / l.width * l.text.length)
}

export const TextElement = observer(({ element, store }/*: ShapeProps*/) => {
    const r = useRef(null);
    const {
        editorEnabled,
        selectAll
    } = useEditor(element);
    const [a, i] = useState(!1);
    const l = store.selectedElements.indexOf(element) >= 0;
    useEffect((() => {
        if (element.width) return;
        const t = r.current;
        t.width(600), 
        element.set({
            width: 1.4 * t.getTextWidth()
        })
    }), []);

    useEffect((() => {
        const t = r.current;
        element.height !== t.height() && element.set({
            height: t.height()
        })
    }))

    useLayoutEffect((() => autorun((() => {
        const t = r.current;
        applyFilter(t, element)
    }))));
    const [s] = useFontLoader(store, element.fontFamily);
    useLayoutEffect((() => {
        const t = r.current;
        t && (t.width(t.width() + 1e-8), t._setTextData(), 
        applyFilter(t, element))
    }), [s]);
    
    const d = useRef(null),
        c = useRef(0),
        u = r => {
            store.selectedElements.find((t => t === element)) && !element.locked && (c.current = getCursorPosition(r), 
            element.toggleEditMode())
        },
        f = !element.text && element.placeholder,
        g = element._editModeEnabled ? 0 : f ? .6 : element.opacity;
    useFadeIn(r, g);
    const h = getLineHeight({
        fontLoaded: s,
        fontFamily: element.fontFamily,
        fontSize: element.fontSize,
        lineHeight: element.lineHeight
    });
    //@ts-ignore
    return <Fragment> 
        <Text
            ref= {r}
            id= {element.id}
            name= "element"
            x= {element.x}
            y= {element.y}
            rotation= {element.rotation}
            width= {element.width}
            text= {element.text || element.placeholder}
            fill= {element.fill}
            stroke= {element.stroke}
            strokeWidth= {element.strokeWidth}
            fillAfterStrokeEnabled= {!0}
            fontSize= {element.fontSize}
            fontFamily= {element.fontFamily}
            fontStyle= {element.fontStyle + " " + element.fontWeight}
            textDecoration= {element.textDecoration}
            align= {element.align}
            draggable= {!element.locked}
            opacity= {g}
            shadowEnabled= {element.shadowEnabled}
            shadowBlur= {element.shadowBlur}
            lineHeight= {h}
            letterSpacing= {element.letterSpacing * element.fontSize}
            onDragStart= {() => {
                store.history.startTransaction()
            }}
            onDragEnd= {r => {
                element.set({
                    x: r.target.x(),
                    y: r.target.y()
                }), store.history.endTransaction()
            }}
            onMouseEnter= {() => {
                i(!0)
            }}
            onMouseLeave= {() => {
                i(!1)
            }}
            onClick= {u}
            onTap= {u}
            onTransformStart= {() => {
                store.history.startTransaction()
            }}
            onTransform= {t => {
                var r;
                const n = (null === (r = t.target.getStage()) || void 0 === r ? void 0 : r.findOne("Transformer")).getActiveAnchor();
                if ("middle-left" === n || "middle-right" === n) {
                    const r = t.target.scaleX(),
                        n = t.target.width() * r,
                        o = element.fontSize;
                    let a = n;
                    n < o && (a = o, d.current && t.target.position(d.current)), t.target.width(a), t.target.scaleX(1), applyFilter(t.target, element)
                }
                //@ts-ignore
                t.target.strokeWidth(element.strokeWidth / t.target.scaleX()), d.current = t.target.position()
            }}
            onTransformEnd= {r => {
                const n = r.target.scaleX();
                //@ts-ignore
                r.target.scaleX(1), r.target.scaleY(1), r.target.strokeWidth(element.strokeWidth), element.set({
                    fontSize: element.fontSize * n,
                    width: r.target.width() * n,
                    x: r.target.x(),
                    y: r.target.y(),
                    rotation: r.target.rotation()
                }), store.history.endTransaction()
            }}
        /> 
    
        {editorEnabled && <Group
            x= {element.x}
            y= {element.y}
            rotation= {element.rotation}>
            <TextInput
                //@ts-ignore
                textNodeRef= {r}
                element= {element}
                selectAll= {selectAll}
                cursorPosition= {c.current}
                onBlur= {() => {
                    element.toggleEditMode(!1)
                }}
            />
        </Group>
        }
    
    {!l && a && <Highlighter
        element= {element}
    />
    }
    </Fragment>
})