import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { localFileToURL } from "../utils/file";
import { getImageSize } from "../utils/image";
import { t as translate } from "../utils/l10n";
import { ImagesGrid } from "./images-grid";
let uploadFunc = async e => localFileToURL(e);

function setUploadFunc(e) {
    uploadFunc = e
}

let filesCache = [];

export const UploadPanel = ({
    store: e
}) => {
    const [t, l] = useState(filesCache);
    const [a, i] =  useState(!1);
    useEffect(() => {
        filesCache = t
    }, [t]);
    return <div
        style= {{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
        <div style= {{ height: "45px", paddingTop: "5px" }}>
            {translate("sidePanel.uploadTip")}
        </div>
        <div style= {{ marginBottom: "20px" }}>
            <label htmlFor= "input-file">
                <Button
                    icon= "upload"
                    style= {{
                        width: "100%"
                    }}
                    onClick= {() => {
                        var e;
                        null === (e = document.querySelector("#input-file")) || void 0 === e || e.click()
                    }}>
                        {translate("sidePanel.uploadImage")}
                </Button>
                <input
                    type= "file"
                    id= "input-file"
                    style= {{
                        display: "none"
                    }}
                    onChange= {async e => {
                        const {
                            target: t
                        } = e;
                        i(!0);
                        for (const e of t.files) {
                            const t = await uploadFunc(e);
                            l((e => e.concat([t])))
                        }
                        i(!1), t.value = null
                    }}
                    multiple= {!0}>
                </input>

            </label>
        </div>
        {/*@ts-ignore*/}
        <ImagesGrid
            images= {t}
            isLoading= {a}
            getPreview= {e => e}
            onSelect= {async (t, l) => {
                var a;
                //@ts-ignore
                let { width: i, height: n } = await getImageSize(t);
                const r = t.indexOf("svg+xml") >= 0 || t.indexOf(".svg") >= 0 ? "svg" : "image",
                    o = Math.min(e.width / i, e.height / n, 1);
                i *= o, n *= o;
                const u = ((null == l ? void 0 : l.x) || e.width / 2) - i / 2,
                    c = ((null == l ? void 0 : l.y) || e.height / 2) - n / 2;
                null === (a = e.activePage) || void 0 === a || a.addElement({
                    type: r,
                    src: t,
                    x: u,
                    y: c,
                    width: i,
                    height: n
                })
            }}>
        </ImagesGrid>
    </div>
}