import { observer } from "mobx-react-lite";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { handleHotkey } from "./hotkeys";
import  Page  from "./page"
import { StoreType } from '../model/store';

const ZERO_SIZE_WARNING = "Polotno warning: <Workspace /> component can not automatically detect its size.\nWidth or height of parent elements is equal 0.\nPlease make sure it has non-zero size. You may need to adjust it with your styles. <Workspace /> will automatically fit into parent container.\nFor simpler debugging here is the log of the parent element:";

const useSaveScrollOnScaleChange = (contentRef) => {
   
    const dimensionRef = useRef({
            width: contentWidth,
            height: contentHeight
        }),
    positionRef = useRef({
            top: 0,
            left: 0
        });
    useEffect((() => {
        const contentEle = contentRef.current,
        scrollHandler = () => {
            positionRef.current = {
                top: contentEle.scrollTop,
                left: contentEle.scrollLeft
            }
        };
        contentEle.addEventListener("scroll", scrollHandler)
            return  () => {
                contentEle.removeEventListener("scroll", scrollHandler)
            }
        }), []
    ), 
    useLayoutEffect((() => {
        if (!contentRef.current) return;
        if( !store.activePage ) return;
        const xPadding = Math.max(5, (dimensionRef.current.width - page.activePage.width * page.activePage.scale) / 2);
        const yPadding = Math.max(45, (dimensionRef.current.height - page.activePage.height * page.activePage.scale) / 2);
        const contentWidth = store.activePage.width * store.activePage.scale + 2 * xPadding;
        const contentHeight = store.activePage.height * store.activePage.scale + 2 * yPadding;

        const contentElement = contentRef.current,
            scaleWidth = (positionRef.current.left + contentElement.offsetWidth / 2) / dimensionRef.current.width,
            scaleHeight = (positionRef.current.top + contentElement.offsetHeight / 2) / dimensionRef.current.height;
            contentElement.scrollLeft = scaleWidth * contentWidth - contentElement.offsetWidth / 2, 
            contentElement.scrollTop = scaleHeight * contentHeight - contentElement.offsetHeight / 2, 
            dimensionRef.current = {
                width: contentWidth,
                height: contentHeight
            }
    }), [store.activePage.scale])
}

const useScrollOnActiveChange = (contentRef, contentHeight, store) => {
    const isScrolled = useRef(!1),
        timeoutRef = useRef(0);
    useEffect((() => {
        const contentElement = contentRef.current,
        scrollHandler = () => {
            isScrolled.current = !0, 
            clearTimeout(timeoutRef.current), 
            timeoutRef.current = setTimeout((() => {
                isScrolled.current = !1
            }), 300)
        };
        return contentElement.addEventListener("scroll", scrollHandler), () => {
            contentElement.removeEventListener("scroll", scrollHandler)
        }
    }), []), 
    
    useLayoutEffect((() => {
        if (!store.activePage) return;
        if (!contentRef.current) return;
        if (isScrolled.current) return;
        const content = contentRef.current,
            offsetHeight = store.pages.indexOf(store.activePage) * contentHeight;
        if(Math.abs(offsetHeight - content.scrollTop) > .9 * contentHeight )
            content.scrollTop = offsetHeight
    }), [store.activePage])
};

export const Workspace = observer(
    ({ store,  pageControlsEnabled}/* : {
        store: StoreType;
        pageControlsEnabled?: boolean | undefined;
    }*/) => {

    const [dimension, setDimension] = useState({
        width: 100,
        height: 100
    })
    const clientRectRef = useRef(null);
    const scrollableContentRef = useRef(null);

    /*const setScaleRatio = () => {
        if (null === clientRectRef.current) return;
        if( null == store.pages ) return;
        
        const clientRect = clientRectRef.current.getBoundingClientRect();
        0 !== clientRect.width && 0 !== clientRect.height || (
            console.warn(ZERO_SIZE_WARNING), 
            console.log(clientRectRef.current)
        );
        setDimension({
            width: clientRect.width,
            height: clientRect.height
        });
        store.pages.forEach( page => {
            const scaleWidth = (clientRect.width - 10) / page.width,
            scaleHeight = (clientRect.height - 90) / page.height,
            scaleRatio = Math.min(scaleWidth, scaleHeight);
    
            page.setScale(scaleRatio);
            page._setScaleToFit(scaleRatio);
        })
    };*/

    //useEffect((setScaleRatio), [store.pages]);

    const setDimensionHandler = () => {
        if (null === clientRectRef.current) return;
        
        const clientRect = clientRectRef.current.getBoundingClientRect();
        0 !== clientRect.width && 0 !== clientRect.height || (
            console.warn(ZERO_SIZE_WARNING), 
            console.log(clientRectRef.current)
        );
        setDimension({
            width: clientRect.width,
            height: clientRect.height
        });
    }

    useEffect(setDimensionHandler, [store.pages]);

    useEffect((() => (window.addEventListener("resize", setDimensionHandler), () => window.removeEventListener("resize", setDimensionHandler))), []);
    
    useEffect((() => {
        const t = t => {
            handleHotkey(t, store)
        };
        return window.addEventListener("keydown", t), () => window.removeEventListener("keydown", t)
    }), []);

    //useSaveScrollOnScaleChange(scrollableContentRef);
    //useScrollOnActiveChange(scrollableContentRef, store.height * store.scale + 2 * yPadding, store);

    return <div
        ref= {clientRectRef}
        style= {{
            width: "100%",
            height: "100%",
            position: "relative",
            outline: "none",
            flex: 1
        }}
        tabIndex= {0}>
            <div 
                ref= {scrollableContentRef}
                onScroll= {t => {
                    const offsetHeight = t.currentTarget.childNodes[0].childNodes[0].offsetHeight,
                        scrollTop = t.currentTarget.scrollTop,
                        numberOffset = Math.floor((scrollTop + 100) / offsetHeight),
                        scrolledPage = store.pages[numberOffset];
                        scrolledPage && scrolledPage.select()
                }}
                style= {{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
            {   
                store.pages.map((page => <Page
                    key= {page.id}
                    page={page}
                    store={store}
                    dimension = {dimension}
                    pageControlsEnabled={pageControlsEnabled}
                />))
            }
            {
                0 === store.pages.length && <div
                    style= {{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center"
                    }}>
                    <p> {"There are no pages yet... "}</p> 
                    <button onClick= {() => {
                        store.addPage()
                    }}> {"Add page"}
                    </button>
                </div>
            }
            </div>
        </div>
})

export default Workspace;