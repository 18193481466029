
import React from "react"
import Konva from "konva";

let fadeInEnabled = !0;

export const toggleFadeInAnimation = (e = !fadeInEnabled) => {
    fadeInEnabled = e
};

export const isAnimationUsed = () => fadeInEnabled;

export function useFadeIn(e, t?) {
    const n = React.useRef();
    React.useLayoutEffect((() => {
        //@ts-ignore
        if(n.current) n.current.destroy()
    }), [t]);
    React.useLayoutEffect((() => {
        if (!fadeInEnabled) return;
        const t = e.current.opacity();
        if(t) {
            e.current.opacity(0), 
            //@ts-ignore
            n.current = new Konva.Tween({
                node: e.current,
                opacity: t,
                onFinish: () => {
                    //@ts-ignore
                    n.current.destroy();
                }
            });
            //@ts-ignore
            n.current.play();
            
            return () => {
                //@ts-ignore
                n.current.destroy();
            }
        
        } else 
            return 
    }), [])
}