
import { StoreType } from '../model/store';
import React from 'react';
import { Tooltip2 } from '@blueprintjs/popover2';
import {t as translate} from "../utils/l10n";
import { Button } from '@blueprintjs/core';
export const RemoveButton= (({ store:e }) => {
    const t = e.selectedElements.length > 0;
    return <Tooltip2
        content= {translate("toolbar.removeElements")}
        disabled= {!t}> 
    <Button
        icon= "trash"
        minimal= {!0}
        onClick= {() => {
            e.deleteElements(e.selectedElementsIds)
        }}
        disabled= {!t}
        style= {{
            marginLeft: "auto"
        }}
    />
    </Tooltip2>
})