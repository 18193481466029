import { Alignment, Button, Navbar } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { StoreType } from "../model/store";
export const HistoryButtons = observer(({
    //@ts-ignore
    store
}) => <Navbar.Group align= {Alignment.LEFT}> 
    <Button
        icon= "undo"
        minimal= {!0}
        onClick= {() => {
            store.history.undo()
        }}
        disabled= {!store.history.canUndo}
    /> 
    <Button
        icon= "redo"
        minimal= {!0}
        onClick= {() => {
            store.history.redo()
        }}
        disabled= {!store.history.canRedo}
    /></Navbar.Group>
);