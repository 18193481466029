import { getSvgSize } from "./svg";

export const getImageSize = (e) => {
    return new Promise(resolver => {
        const img = document.createElement("img");
        img.onload = async () => {
            0 === img.width || 0 === img.height ? resolver(await getSvgSize(e)) : resolver({
                width: img.width,
                height: img.height
            })
        };
        img.src = e;
    })
} 