
import React from 'react';
import { observer } from 'mobx-react-lite';

import styled from "./utils/styled";
import { DownloadButton } from "./toolbar/download-button"
import {
    Button,
    Navbar,
    Alignment,
    Divider,
    Dialog,
    Classes,
} from '@blueprintjs/core';
import { downloadFile } from './utils/download';

const NavbarContainer = styled('div')
`
  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`;

const NavInner = styled('div')`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;


export default observer(({ store }) => {
    const inputRef = React.useRef();
    const [faqOpened, toggleFaq] = React.useState(false);

    return (
        <NavbarContainer className="bp3-navbar">
          <NavInner>
            <Navbar.Group align={Alignment.LEFT}>
              <Button
                icon="new-object"
                minimal
                onClick={() => {
                  const ids = store.pages
                    .map((page) => page.children.map((child) => child.id))
                    .flat();
                  const hasObjects = ids.length;
                  if (hasObjects) {
                    if (!window.confirm('Remove all content for a new design?')) {
                      return;
                    }
                  }
                  const pagesIds = store.pages.map((p) => p.id);
                  store.deletePages(pagesIds);
                  store.addPage();
                }}>
                New
              </Button>
              <label htmlFor="load-project">
                <Button
                  icon="folder-open"
                  minimal
                  onClick={() => {
                    document.querySelector('#load-project').click();
                  }}
                >
                  Open
                </Button>
                <input
                  type="file"
                  id="load-project"
                  accept=".json,.polotno"
                  ref={inputRef}
                  style={{ width: '180px', display: 'none' }}
                  onChange={(e) => {
                    var input = e.target;
    
                    if (!input.files.length) {
                      return;
                    }
    
                    var reader = new FileReader();
                    reader.onloadend = function () {
                      var text = reader.result;
                      let json;
                      try {
                        json = JSON.parse(text);
                      } catch (e) {
                        alert('Can not load the project.');
                      }
    
                      if (json) {
                        store.loadJSON(json);
                      }
                    };
                    reader.onerror = function () {
                      alert('Can not load the project.');
                    };
                    reader.readAsText(input.files[0]);
                  }}
                />
              </label>
              <Button
                icon="floppy-disk"
                minimal
                onClick={() => {
                  const json = store.toJSON();
    
                  const url =
                    'data:text/json;base64,' +
                    window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));
                  downloadFile(url, 'polotno.json');
                }}
              >
                Save
              </Button>
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
              <Divider />
              <DownloadButton store={store} />
            </Navbar.Group>
            <Dialog
              icon="info-sign"
              onClose={() => toggleFaq(false)}
              title="About Pure Electric Studio"
              isOpen={faqOpened}
              style={{
                width: '80%',
                maxWidth: '700px',
              }}
            >
              <div className={Classes.DIALOG_BODY}>
                <h2>What is Pure Electric Studio?</h2>
                <p>
                  <strong>Pure Electric Studio</strong> - is a web application to create
                  graphical designs. You can mix image, text and illustrations to
                  make positioning furnitures in your house.
                </p>
                <h2>Is it Open Source?</h2>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button onClick={() => toggleFaq(false)}>Close</Button>
                </div>
              </div>
            </Dialog>
          </NavInner>
        </NavbarContainer>
      );
})