import { StoreType } from '../model/store';
import React from 'react';
import { observer } from 'mobx-react-lite';
import {t as translate} from "../utils/l10n";
import { Tooltip2 } from '@blueprintjs/popover2';
import { Button } from '@blueprintjs/core';

export const LockButton= observer((/*{ store: e }: {
    store: StoreType;
}*/ {store} ) => {
    const t = store.selectedElements.length > 0,
        o = store.selectedElements[0],
        r = null == o ? void 0 : o.locked,
        l = r ? "lock" : "unlock",
        c = r ? translate("toolbar.lockedDescription") : translate("toolbar.unlockedDescription");
    return <Tooltip2
        content= {c}
        disabled= {!t}
    ><Button
        minimal= {!0}
        disabled= {!t}
        icon= {l}
        onClick= {() => {
            store.selectedElements.forEach((e => e.set({
                locked: !e.locked
            })))
        }}
    /></Tooltip2>
})