import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { observer } from "mobx-react-lite";
import { t as translate } from "../utils/l10n";
import React from "react";
import { Button, Position, Slider } from "@blueprintjs/core";

import MdOpacity from "@meronex/icons/md/MdOpacity";
import { StoreType } from "../model/store";


export const OpacityPicker = observer((({
    store
}) => {
    var t;
    const r = store.selectedElements.length > 0;
    return <Popover2
        disabled= {!r}
        minimal= {!1}
        content= {<div style= {{ padding: "10px 20px" }}> 
        <div style= {{ textAlign: "center" }}> {translate("toolbar.transparency")}</div>
            <Slider
            value= {100 * (null === (t = store.selectedElements[0]) || void 0 === t ? void 0 : t.opacity)}
            labelRenderer= {!1}
            onChange= {t => {
                store.selectedElements.forEach((e => {
                    e.set({
                        opacity: t / 100
                    })
                }))
            }}
            min= {0}
            max= {100}
            />
        </div>}
        position= {Position.BOTTOM}> 
        <Tooltip2
            content= {translate("toolbar.transparency")}
            disabled= {!r}>
            <Button
                minimal= {!0}
                disabled= {!r}> 
                <MdOpacity
                    className= "bp3-icon"
                    style= {{
                        fontSize: "20px"
                    }}
                />
            </Button>
        </Tooltip2>
    </Popover2>
}));