import { InputGroup } from "@blueprintjs/core";
import { isAlive } from "mobx-state-tree";
import React, { Fragment, useEffect, useRef, useState } from "react";
import swr, { useSWRInfinite } from "swr";
import { API } from "../utils/base-url";
import { getImageSize } from "../utils/image";
import { urlToBase64 } from "../utils/svg";
import { getKey } from "../utils/validate-key";
import { ImagesGrid } from "./images-grid";

const Iconscout = ({query, store}) => {
    const {
        data,
        error,
        mutate,
        size,
        setSize,
        isValidating
    } = useSWRInfinite(t => `${API}/get-iconscout?query=${query}&page=${t+1}&KEY=${getKey()}`);
    
    useEffect(() => {
        mutate()
    }, [query]);
    const isLoading = !data && !error ||  ( size > 0 && data && undefined === data[size - 1]);
    var firstData = data == null ? undefined : data[0];
    var responseItems = data ? data[data.length - 1].response.items.data: undefined;
    const isAllLoad = firstData === null ? undefined :
                    data.length == 0 ? undefined :
                    responseItems === null ? undefined :
                    data.length < 20
    return <Fragment>
        <p style={{textAlign: "center"}}>{"Icons by"} {" "} <a
            href= "https://iconscout.com/"
            target= "_blank"
        >{"iconscout"}</a> </p>
        {/*@ts-ignore*/}
        <ImagesGrid
            shadowEnabled= {!1}
            rowsNumber= {3}
            images= {null == data ? void 0 : data.map((e => e.response.items.data)).flat()}
            getPreview= {e => e.urls.png_128}
            isLoading= {isLoading}
            loadMore= {() => {
                if(!isAllLoad)  setSize(size + 1)
            }}
            onSelect= {async (e, a) => {
                //@ts-ignore
                const { width , height } = await getImageSize(e.urls.png_128);
                store.history.transaction((async () => {
                    var l;
                    const s = ((null == a ? void 0 : a.x) || store.width / 2) - width / 2,
                        n = ((null == a ? void 0 : a.y) || store.height / 2) - height / 2,
                        u = null === (l = store.activePage) || void 0 === l ? void 0 : l.addElement({
                            type: "svg",
                            width: width,
                            height: height,
                            x: s,
                            y: n
                        }),
                        {uuid: o} = e,
                        c = await fetch(`${API}/download-iconscout?uuid=${o}&KEY=${getKey()}`),
                        {
                            url: d
                        } = await c.json();
                    if (!d) return void alert("Image loading is failed. Please try again later.");
                    const base64 = await urlToBase64(d);
                    isAlive(u) && await u.set({
                        src: base64
                    })
                }))
            }
            }
        />
    </Fragment>
}

const BasicShapes = ({
    store
}) => {
    const {
        data,
        error,
        isValidating,
    } = swr(`${API}/get-basic-shapes?KEY=${getKey()}`);
    //@ts-ignore
    return <ImagesGrid
        shadowEnabled= {!1}
        rowsNumber= {3}
        images= {null == data ? void 0 : data.items}
        getPreview= {e => e.url}
        isLoading= {!data}
        itemHeight= {100}
        onSelect= {async (t, a) => {
            var r;
            //@ts-ignore
            const { width: i, height: l } = await getImageSize(t.url), 
            s = await urlToBase64(t.url), n = ((null == a ? void 0 : a.x) || store.width / 2) - i / 2, u = ((null == a ? void 0 : a.y) || store.height / 2) - l / 2;
            null === (r = store.activePage) || void 0 === r || r.addElement({
                type: "svg",
                width: i,
                height: l,
                x: n,
                y: u,
                src: s,
                keepRatio: !1
            })
        }}
    />
}
export const ElementsPanel = ({
    store: e
}) => {
    const t = useRef(),
        [a, r] = useState(""),
        [i, l] = useState(a);
    //@ts-ignore
    useEffect((() => (t.current = setTimeout((() => {
        l(a)
    }), 500), () => {
        clearTimeout(t.current)
    })), [a]);
    const s = !!a;
    return <div
        style= {{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}
    > 
    <InputGroup
        leftIcon= "search"
        placeholder= "Search..."
        onChange= {e => {
            r(e.target.value)
        }}
        style= {{
            marginBottom: "20px"
        }}
    /> 
    
    {s && <Iconscout
        query= {i}
        store= {e}
    />}
    
    {!s && <BasicShapes
        store= {e}
    />
    }
    </div>
};