
import AiOutlineFacebook from "@meronex/icons/ai/AiOutlineFacebook"
import AiOutlineInstagram from "@meronex/icons/ai/AiOutlineInstagram"
import AiOutlineYoutube from "@meronex/icons/ai/AiOutlineYoutube"
import AiOutlineVideoCamera from "@meronex/icons/ai/AiOutlineVideoCamera"
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Alignment, Button, Icon, NumericInput, Position, Switch } from "@blueprintjs/core";
import {t as translate} from "../utils/l10n"
import { Tooltip2 } from "@blueprintjs/popover2";
export const DEFAULT_SIZES = [
    ["Instagram Post", 1080, 1080, React.createElement(AiOutlineInstagram, null)],
    ["Instagram Story", 1080, 1920, React.createElement(AiOutlineInstagram, null)],
    ["Instagram Ad", 1080, 1080, React.createElement(AiOutlineInstagram, null)],
    ["Facebook Post", 940, 788, React.createElement(AiOutlineFacebook, null)],
    ["Facebook Cover", 851, 315, React.createElement(AiOutlineFacebook, null)],
    ["Facebook Ad", 1200, 628, React.createElement(AiOutlineFacebook, null)],
    ["Youtube Thumbnail", 1280, 720, React.createElement(AiOutlineYoutube, null)],
    ["Youtube Channel", 2560, 1440, React.createElement(AiOutlineYoutube, null)],
    ["Full HD", 1920, 1080, React.createElement(AiOutlineVideoCamera, null)]];


export const SizePanel = observer(({
    //@ts-ignore
    store: e
}) => {
    const [t, a] = useState(!0);
    return <div
        style= {{
            height: "100%",
            overflow: "auto"
        }}>
        <div
            style= {{
                paddingBottom: "15px"
            }}> 
            <Switch
                checked= {t}
                onChange= {e => {
                    //@ts-ignore
                    a(e.target.checked)
                }}
                alignIndicator= {Alignment.RIGHT}
                style= {{
                    marginTop: "8px",
                    marginBottom: "25px"
                }}
            > 
            {translate("sidePanel.useMagicResize")} {" "}
            
            <Tooltip2
                content= {translate("sidePanel.magicResizeDescription")}
                position= {Position.BOTTOM}> 
                <Icon icon= "help" />
            </Tooltip2>
            </Switch> 
            
            <div
                style= {{
                    width: "50%",
                    display: "inline-block"
                }}>{"Width"}
            </div>  
            <div
                style= {{ width: "50%",display: "inline-block" }}> 
                <NumericInput
                    fill= {!0}
                    value= {e.width}
                    onValueChange= {a => e.setSize(a, e.height, t)}
                    min= {10}
                    max= {1e4}
                    buttonPosition= "none"
                    selectAllOnFocus= {!0}
                />
            </div>
        </div> 
            <div
                style= {{ paddingBottom: "15px" }}>
                <div style= {{ width: "50%", display: "inline-block" }}> 
                    {"Height"}
                </div> 
                
                <div
                style= {{
                    width: "50%",
                    display: "inline-block"
                }}>
                <NumericInput
                    fill= {!0}
                    value= {e.height}
                    onValueChange= {a => e.setSize(e.width, a, t)}
                    min= {10}
                    max= {1e4}
                    buttonPosition= {"none"}
                    selectAllOnFocus= {!0}
                />
            </div>
        </div> 
            {DEFAULT_SIZES.map(([a, l, i, n]) => <Button
            //@ts-ignore
                key= {a}
                style= {{
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "16px"
                }}
                minimal= {!0}
                onClick= {() => {
                    e.setSize(l, i, t)
                }}
            //@ts-ignore
                icon= {n}
                alignText= "left"
            > {a}
            <span
                style= {{
                    fontSize: "0.7rem",
                    paddingLeft: "20px"
                }}
            > {l} {" x "} {i}
            </span>
            </Button>)}
        </div>
    }
)