import { isAnimationUsed } from "../canvas/use-fadein";

let loadCount = 0, listeners = [];

export function incrementLoader() {
    loadCount += 1
}

export function decrementLoader() {
    loadCount -= 1;
    if(0 === loadCount) {
        listeners.forEach(((e:any) => e()));
        listeners = []
    }
}
export function whenLoadedCallback(e) {
    0 === loadCount ? e() : listeners.push(e)
}

export function whenLoaded() {
    return new Promise((e => whenLoadedCallback((() => {
        isAnimationUsed() ? setTimeout(e, 300) : e(!0)
    }))))
}