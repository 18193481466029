import  styled  from "../utils/styled";
import React from "react";
const TabContainer = styled("div")
`
  width: 72px;
  height: 72px;
  padding-top: 15px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;

  .bp3-dark &:hover,
  .bp3-dark &.active {
    color: #48aff0 !important;
  }

  &:hover,
  &.active {
    background-color: rgba(19, 124, 189, 0.2);
  }

  @media screen and (max-width: 500px) {
    height: 54px;
    padding-top: 9px;
  }
`
interface SectionTabProps {
    children: any ;
    name: string; 
    onClick:any ;
    active: boolean;
    iconSize?: number;
}


export const SectionTab = ({ children, name, onClick, active, iconSize, }: SectionTabProps) => {
    return  <TabContainer 
                onClick= {onClick}
                className= {"polotno-side-panel-tab" + (active ? " active" : "")}>
                <div style= {{ fontSize: (iconSize || 14) + "px"}}>
                    {children}
                </div>
                <div style= {{ paddingTop: "5px"}}>
                    {name}
                </div>
            </TabContainer>
}