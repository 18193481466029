
import { Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import React, { useState } from 'react';
import {t as translate} from "../utils/l10n";
import { StoreType } from '../model/store';
export const DownloadButton = (({ store }/*: {
    store: StoreType;
}*/) => {
    const [loading, setLoading] = useState(!1);
    return <Popover2
        content= {<Menu>
            <MenuItem
                icon= "export"
                text= "Upload To CRM"
                onClick= {async () => {
                    setLoading(!0);
                    await store.pages.forEach((  page => {
                         store.uploadAsImage({
                            page: page
                        })
                    }));
                    setLoading(!1)
                }}
            />
            <MenuItem
                icon= "media"
                text= {translate("toolbar.saveAsImage")}
                onClick= {async () => {
                    store.pages.forEach((page => {
                        store.saveAsImage({
                            page: page
                        })
                    }))
                }}
            />
            <MenuItem
                icon= "document"
                text= {translate("toolbar.saveAsPDF")}
                onClick= {async () => {
                    setLoading(!0), await store.saveAsPDF(), setLoading(!1)
                }}
            />
            <MenuItem
                icon= "document"
                text= {translate("toolbar.saveAsHDPDF")}
                onClick= {async () => {
                    setLoading(!0), 
                    await store.saveAsPDF({
                        pixelRatio: 3
                    }), 
                    setLoading(!1)
                }}
            />
        </Menu>}
        position= {Position.BOTTOM}>
        <Button
            icon= "import"
            text= {translate("toolbar.download")}
            minimal= {!0}
            loading= {loading}
        />
    </Popover2>
})
