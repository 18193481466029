import { useEffect, useState } from "react";

export const MOBILE_BREAKPOINT = 500

export const isMobile = () => window.innerWidth <= MOBILE_BREAKPOINT;

export const useMobile = () => {
    const [e, t] = useState(isMobile());
    useEffect((() => {
        const e = () => {
            t(isMobile())
        };
        return window.addEventListener("resize", e), () => {
            window.removeEventListener("resize", e)
        }
    }), []);
    return e
};