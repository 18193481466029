import styled from "../utils/styled";
import React  from "react";
import { registerNextDomDrop } from "../canvas/page";
import { Spinner } from "@blueprintjs/core";
import { t } from "../utils/l10n";
const ImagesListContainer  = styled("div")
`
  height: 100%;
  overflow: auto;
`;

const ImagesRow = styled("div")
`
  width: 33%;
  float: left;
`;
const ImgWrapDiv = styled("div")
`
  padding: 5px;
  width: 100%;
  &:hover .credit {
    opacity: 1;
  }
  @media screen and (max-width: 500px) {
    .credit {
      opacity: 1;
    }
  }
`
const ImgContainerDiv = styled("div")
`
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  box-shadow: ${e=>e["data-shadowenabled"]?"0 0 5px rgba(16, 22, 26, 0.3)":""};
`;
const Img = styled("img")
`
  width: 100%;
  cursor: pointer;
  display: block;
`;
const CreditWrap = styled("div")
`
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 10px;
  padding: 3px;
  padding-top: 10px;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.6)
  );
  width: 100%;
  opacity: 0;
  color: white;
`
const NoResults = styled("p")
`
  text-align: center;
  padding: 30px;
`

const Image = ({
    url,
    credit,
    onSelect,
    crossOrigin,
    shadowEnabled,
    itemHeight,
    className
  }) => {
    const shadowenabled = shadowEnabled ==null || shadowEnabled;
       
    return <ImgWrapDiv
        onClick = {() => onSelect() }
        className= "pe-close-panel">
            <ImgContainerDiv
                data-shadowenabled= {shadowenabled}>
                <Img
                    className= {className}
                    style= {{
                      height: null != itemHeight ? itemHeight : "auto"
                    }}
                    src= {url}
                    draggable= {true}
                    crossOrigin= {crossOrigin}

                    onDragStart= {() => {
                        registerNextDomDrop((({
                          x: e,
                          y: t
                        }, r) => {
                          onSelect({
                            x: e,
                            y: t
                          }, r)
                        }))
                      }
                    }
                    onDragEnd= {() => {
                        registerNextDomDrop(null)
                      }
                    }
                ></Img>

                <CreditWrap className= "credit">{credit}</CreditWrap>
            </ImgContainerDiv>
        </ImgWrapDiv>
  }

export const ImagesGrid = ({
    images,
    onSelect,
    isLoading,
    getPreview,
    loadMore,
    getCredit,
    getImageClassName,
    rowsNumber,
    crossOrigin,
    shadowEnabled,
    itemHeight
}) => {
const rows = rowsNumber ? rowsNumber : 2;
const listImages = [];

for (var m = 0; m < rows; m++) listImages.push((images || []).filter(((e, t) => t % rows === m)));

return <ImagesListContainer
    onScroll= {e => {
        //@ts-ignore
        const t = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight;
        if(loadMore && !isLoading && t < 200 ) loadMore();
    }}>
    {listImages.map(((e, l) => <ImagesRow
          key= {l}
          style= {{
          width: 100 / rows + "%"
          }}
          >
          {e.map((e => <Image
                  url= {getPreview(e)}
                  onSelect= {(a, r) => onSelect(e, a, r)}
                  key= {getPreview(e)}
                  credit= {getCredit && getCredit(e)}
                  crossOrigin= {crossOrigin}
                  shadowEnabled= {shadowEnabled}
                  itemHeight={itemHeight}
                  className= {getImageClassName && getImageClassName(e)}
              />))
          }
      </ImagesRow>)  
      )
    }

    {isLoading && <div
        style= {{
          padding: "30px"
        }}> 
        <Spinner /></div>
    }
    {  !isLoading && (!images || !images.length) && <NoResults>{t("sidePanel.noResults")}</NoResults> }
    </ImagesListContainer>

  }
