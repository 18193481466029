import { observer } from "mobx-react-lite";
import React from "react";
import { StoreType, ElementType } from "../model/store";
import { ImageElement } from "./image-element";
import { TextElement } from "./text-element";

/*type ShapeProps = {
    store: StoreType;
    element: ElementType;
    onClick: Function;
};*/

const SHAPES_TYPES = {
    text: TextElement,
    image: ImageElement,
    svg: ImageElement
};

export const registerShapeComponent = (type/*: string*/, component/*: JSX.Element*/) => {
    SHAPES_TYPES[type] = component
}

export default observer( (props/*: ShapeProps*/) => {
    const ShapeType = SHAPES_TYPES[props.element.type];
    return ShapeType ? <ShapeType {...props} /> : (console.error("Can not find component for " + props.element.type), null)
})