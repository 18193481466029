import { API } from "./base-url";

let API_KEY = "";

export const getKey = () => API_KEY || "";

const ATTEMPTS = 5,
    TIMEOUT = 3e3,
    NO_KEY_MESSAGE = "Polotno API is initialized without API key. It may lead to unexpected behavior and stop working. Please create API key here: https://polotno.dev/cabinet",
    NO_VALID_MESSAGE = "Polotno API key is not valid. Please get new API key here: https://polotno.dev/cabinet",
    ERROR_MESSAGE = "Can not validate Polotno API key. Please report to anton@polotno.dev immediately.",
    NOT_PAID_MESSAGE = "%cPolotno Free Version. Development or non-commercial usage only. https://polotno.dev/",
    BAD_DOMAIN_MESSAGE = `Polotno error! Current domain is not allowed. It may lead to unexpected behavior and stop working. Please add "${location.origin}" here: https://polotno.dev/cabinet`,
    isPolotnoNode = navigator.userAgent.indexOf("HeadlessChrome") >= 0;

export async function validateKey(e) {
    return {is_valid:true};
    API_KEY = e;
    for (let o = 0; o < 5; o++) try {
        const o = await fetch(API+ "/validate-key", {
            method: "POST",
            body: JSON.stringify({
                key: e,
                site: location.host + location.pathname
            })
        });
        if (!e) return console.warn(NO_KEY_MESSAGE), !1;
        if (200 !== o.status) {
            await new Promise((e => setTimeout(e, 3e3)));
            continue
        }
        const t = await o.json();
        return t.is_valid || console.warn(NO_VALID_MESSAGE), t.is_paid || console.log(NOT_PAID_MESSAGE, "background: rgb(0, 161, 255); color: white; padding: 5px; margin: 5px;"), t.is_domain_valid || isPolotnoNode || console.warn(BAD_DOMAIN_MESSAGE), t.is_paid || !1
    } catch (e) {
        await new Promise((e => setTimeout(e, 3e3)))
    }
    return console.error(ERROR_MESSAGE), !0
}