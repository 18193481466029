import { Button, Menu, MenuItem, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import {t as translation} from "../utils/l10n"
import React from "react"
export const FlipButton = ({
    element: e
}) => <Popover2
    content= {
        <Menu>
            <MenuItem
                shouldDismissPopover= {!1}
                icon= "arrows-horizontal"
                text= {translation("toolbar.flipHorizontally")}
                onClick= {() => {
                    e.set({
                        flipX: !e.flipX
                    })
                }}
            /> 
            <MenuItem
                shouldDismissPopover= {!1}
                text= {translation("toolbar.flipVertically")}
                icon= "arrows-vertical"
                onClick= {() => {
                    e.set({
                        flipY: !e.flipY
                    })
                }}
            />
        </Menu>
        }
    position= {Position.BOTTOM}> 
    <Button
        text= {translation("toolbar.flip")}
        minimal= {!0}
    />
</Popover2>
export default FlipButton;