import { StoreType } from '../model/store';
import styled from "../utils/styled"
import { t as translate } from "../utils/l10n"
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Alignment, Button, Navbar } from '@blueprintjs/core';

const Container = styled("div")
`
  position: absolute;
  bottom: 5px;
  width: auto;
  left: 5px;
  opacity: 0.8;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 4px lightgrey;

  &:hover {
    opacity: 1;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`
const FACTOR = 1.2;

/*type Props = {
    store: StoreType;
};*/

export const ZoomGroup= observer(({ store }/*: Props*/) => {
    
    const [disableZoomIn, setDisableZoomIn] = useState(true); //store.activePage.scale < 2,
    const [disableZoomOut, setDisableZoomOut] = useState(true);// store.activePage.scale > t;

    useEffect(()=>{
        if(!store.activePage) return;
        const scaleToFit = Math.min(.5, store.activePage.scaleToFit);
        setDisableZoomIn(store.activePage.scale < 2);
        setDisableZoomOut(store.activePage.scale > scaleToFit);
      }, 
      [store.activePage]
    )
    return <Navbar.Group
            align = {Alignment.LEFT}>
            <Button
                icon= "zoom-out"
                minimal= {!0}
                onClick= {() => {
                    if(store.activePage.scale)
                      store.activePage.setScale(store.activePage.scale / 1.2)
                }}
                disabled= {!disableZoomOut}
            />
            <Button
                minimal= {!0}
                onClick= {() => {
                  if(store.activePage.scale)
                    store.activePage.setScale(store.activePage.scaleToFit)
                }}> 
                {translate("scale.reset")}
            </Button>

            <Button
                icon= "zoom-in"
                minimal= {!0}
                onClick= {() => {
                  if(store.activePage.scale)
                    store.activePage.setScale(1.2 * store.activePage.scale)
                }}
                disabled= {!disableZoomIn}
            />
        </Navbar.Group>

})

export const ZoomButtons = observer(({
    store
  }/*:Props*/) => 
  <Container>
    <Navbar>
      <ZoomGroup store= {store}/>
    </Navbar>
  </Container>
)

export default ZoomButtons