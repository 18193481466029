import * as mobx from "mobx";

let GOOGLE_FONTS = mobx.observable(["Roboto", "Amatic SC", "Press Start 2P", "Marck Script", "Rubik Mono One"]);

let googleFontsChanged = !1;

export var globalFonts = mobx.observable([]);
export function isGoogleFontChanged() {
    return googleFontsChanged
}

export function setGoogleFonts(t) {
    googleFontsChanged = !0, GOOGLE_FONTS.splice(0, GOOGLE_FONTS.length), GOOGLE_FONTS.push(...t)
}

export function getFontsList() {
    return GOOGLE_FONTS
}

export function addGlobalFont(t) {
    globalFonts.push(t)
}

var TEXT_TEXT = "Some test text;?#D-ПРИВЕТ!",
    canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d");


function measureFont(t) {
    ctx.font = `normal 40px '${t}', Arial`;
    return ctx.measureText(TEXT_TEXT).width
}
function measureArial() {
    return measureFont("Arial")
}
const MAX_ATTEMPTS = 100,
    loadedFonts = {
        Arial: !0
    };

export async function loadFont(t) {
    var e;
    if (loadedFonts[t]) return;
    // @ts-ignore
    const o = !!(null === (e = document.fonts) || void 0 === e ? void 0 : e.load),
        n = measureArial();
    if (o) try {
        // @ts-ignore
        await document.fonts.load(`16px '${t}'`);
        const e = measureFont(t);
        if (n !== e) {
            loadedFonts[t] = !0; return;
        } 
    } catch (t) {}
    const s = measureFont(t);
    for (let e = 0; e < 100; e++) {
        const e = measureFont(t);
        if (e !== s || e !== n) { loadedFonts[t] = !0; return }
        await new Promise((t => setTimeout(t, 60)))
    }
    console.warn(`Timeout for loading font "${t}". Looks like polotno can't load it. Is it a correct font family?`)
}

const registeredGoogleFonts = {};

export function injectGoogleFont(t) {
    if (registeredGoogleFonts[t]) return;
    const e = `https://fonts.googleapis.com/css?family=${t.replace(/ /g,"+")}`,
        o = document.createElement("link");
    o.type = "text/css";
    o.href = e; 
    o.rel = "stylesheet"; 
    document.getElementsByTagName("head")[0].appendChild(o);
    registeredGoogleFonts[t] = !0
}

const registeredCustomFonts = {};

export function injectCustomFont(t) {
    const e = t.fontFamily;
    if (registeredCustomFonts[e]) return;
    if (!t.url && !t.styles) return;
    const o = document.createElement("style");
    o.type = "text/css";
    const n = t.styles || (t.url ? [{
        src: `url("${t.url}")`
    }] : []);
    let s = "";
    n.forEach((t => {
        s += `\n      @font-face {\n        font-family: '${e}';\n        src: ${t.src};\n        font-style: ${t.fontStyle||"normal"};\n        font-weight: ${t.fontWeight||"normal"};\n      }\n    `
    }));
    o.innerHTML = s;
    document.getElementsByTagName("head")[0].appendChild(o); 
    registeredCustomFonts[t.fontFamily] = !0
}