import { Button, Menu, MenuItem, Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import React from "react";
export const PagesPanel = observer(({
    //@ts-ignore
    store
}) => {
    const [t, a] = useState({}), 
    r = () => {
        const t = {};
        store.pages.forEach((a => {
            const r = store.toDataURL({
                pageId: a.id,
                pixelRatio: .2
            });
            t[a.id] = r
        })), a(t)
    };
    useEffect((() => {
        r();
        const e = setInterval(r, 5e3);
        return () => clearInterval(store)
    }), []);
    
    return  <div>
        {store.pages.map((a => <div
            key= {a.id}
            style= {{
                width: "100%",
                padding: "20px",
                height: "100px",
                backgroundImage: `url(${t[a.id]})`
            }}
        ><Popover2
            content= {<Menu> 
                <MenuItem
                    icon= "export"
                    text= "Upload To Suite"
                    onClick= {() => {
                        store.pages.forEach((t => {
                            store.saveAsImage({
                                pageId: t.id
                            })
                        }))
                    }}
                /> 
                <MenuItem
                    icon= "media"
                    text= "Save as Image"
                    onClick= {() => {
                        store.pages.forEach((t => {
                            store.saveAsImage({
                                pageId: t.id
                            })
                        }))
                    }}
                /> 
                <MenuItem
                    icon= "document"
                    text= "Save as PDF"
                    onClick= {() => {
                        store.pages.forEach((t => {
                            store.saveAsPDF({
                                pageId: t.id
                            })
                        }))
                    }}
                />
                <MenuItem
                    icon= "document"
                    text= "Save as High Quality PDF"
                    onClick= {() => {
                        store.pages.forEach((t => {
                            store.saveAsPDF({
                                pageId: t.id,
                                pixelRatio: 3
                            })
                        }))
                    }}
                /></Menu>
                }
            position= {Position.BOTTOM}>
            <Button
                icon= "import"
                text= "Download"
                minimal= {!0}
            /></Popover2></div>))}
        </div>
})
