import konva from "konva";


export const getCorner = (t, e, n, r, o) => {
    const i = Math.sqrt(n * n + r * r);
    o += Math.atan2(r, n);
    return {
        x: t + i * Math.cos(o),
        y: e + i * Math.sin(o)
    }
}

export const  getClientRect = (t) => {
    const {
        x: e,
        y: n,
        width: r,
        height: o
    } = t, 
    i = konva.Util.degToRad(t.rotation), 
    a = getCorner(e, n, 0, 0, i), 
    h = getCorner(e, n, r, 0, i), 
    u = getCorner(e, n, r, o, i), 
    x = getCorner(e, n, 0, o, i), 
    s = Math.min(a.x, h.x, u.x, x.x), 
    c = Math.min(a.y, h.y, u.y, x.y);
    return {
        x: s,
        y: c,
        width: Math.max(a.x, h.x, u.x, x.x) - s,
        height: Math.max(a.y, h.y, u.y, x.y) - c
    }
}

