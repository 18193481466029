
import  styled  from '../utils/styled';
import { StoreType } from '../model/store';
import React, { useState } from 'react';
import { SketchPicker } from "react-color"
import { isGradient, parseColor } from '../utils/gradient';
import { Slider, Tab, Tabs } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

const DEFAULT_COLORS = ["#D0021B", "#F5A623", "#F8E71C", "#8B572A", "#7ED321", "#417505", "#BD10E0", "#9013FE", "#4A90E2", "#50E3C2", "#B8E986", "#000000", "#4A4A4A", "#9B9B9B", "#FFFFFF"].reverse();
const getUsedColors = store => {
    const filledColor = [];
    store.pages.forEach((page => {
      page.children.forEach((element => {
        "text" === element.type && filledColor.push(element.fill), 
        "svg" === element.type && filledColor.push(...element.colorsReplace.values())
      }))
    }));
    const parseColors = [];
    filledColor.forEach((e => {
      if (isGradient(e)) {
        const [t, a, o] = parseColor(e);
        parseColors.push(a, o)
      } else parseColors.push(e)
    }));
    return [...new Set(parseColors)]
  }
const SolidContainer = styled("div")
`
  padding: 5px;

  & .sketch-picker {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
  }

  & .flexbox-fix {
    border-top: none !important;
  }

  .bp3-dark & .sketch-picker {
    background-color: #394b59;
  }

  .bp3-dark & .sketch-picker label {
    color: #f5f8fa !important;
  }
`;

const SolidInput = ({style, ...rest}  ) => {
  
    return <SolidContainer
      style= {style}
    >
        <SketchPicker {...rest} />
    </SolidContainer>
}


const LinearGradient = ({
    value: e,
    onChange: t,
    store: r
  }) => {
    const [a, o, l] = parseColor(e);
    return <div> <div  style= {{ width: "200px", height: "150px"}}>
        <div
        style= {{
            height: "100%",
            background: e
        }}
        />
        </div> 
        <div
            style= {{
            display: "flex",
            paddingBottom: "20px",
            paddingTop: "20px",
            justifyContent: "space-between"
            }}> 
            <ColorPicker
                value= {o}
                onChange= {e => {
                    t(`linear-gradient(${a}deg,${e},${l})`)
                }}
                store= {r}
                style= {{
                    margin: ""
                }}
            /> 
            <ColorPicker
                value= {l}
                onChange= {e => {
                    t(`linear-gradient(${a}deg,${o},${e})`)
                }}
                store= {r}
                style= {{
                    margin: ""
                }}
            />
        </div> 
        <div
            style= {{
                padding: "10px"
            }}>
                <Slider
                    min= {0}
                    max= {360}
                    value= {a}
                    showTrackFill= {!1}
                    labelRenderer= {!1}
                    onChange= {e => {
                        t(`linear-gradient(${e}deg,${o},${l})`)
                    }}
                    />
        </div>
    </div>
  }
const  VariantColorInput = ({
    value: e,
    onChange: t,
    preset: r,
    store: a
  }) => {
    const [o, l] = useState(isGradient(e) ? "gradient" : "solid");
    return <div
      style= {{
        padding: "5px"
      }}
    > 
    <Tabs
        id= {"TabsExample"}
        selectedTabId= {o}
        //@ts-ignore
        onChange= {e => l(e)}> 
        
        <Tab
            id= {"solid"}
            title= {"Solid"}
            panel= {<SolidInput
                color= {e}
                onChangeComplete= {e => {
                    const {
                        r: r,
                        g: a,
                        b: o,
                        a: l
                    } = e.rgb;
                    t(`rgba(${r},${a},${o},${l})`)
                    }}
                presetColors= {r}
                style= {{
                    padding: "0"
                }}
            />
            }/>
        <Tab
            id= "gradient"
            title= "Linear"
            panel= {<LinearGradient
                value= {e}
                onChange= {t}
                store= {a}
            />}
            panelClassName= "ember-panel"
        />
    </Tabs>
    </div>
}

export const ColorPicker = ({
    value: e,
    onChange: t,
    size: r,
    store: a,
    gradientEnabled: o,
    children: l,
    style: n
  }   ) => {
    const i = r || 30,
      c = a ? getUsedColors(a).slice(0, 8) : [],
      d = c.concat(DEFAULT_COLORS.slice(0, 16 - c.length).reverse());
    return <Popover2
        content= {o ? <VariantColorInput
            preset= {d}
            value= {e}
            onChange= {t}
            store= {a}
        /> : 
        <SolidInput
            color= {e}
            onChangeComplete= {e => {
                const {
                    r: r,
                    g: a,
                    b: o,
                    a: l
                } = e.rgb;
                t(`rgba(${r},${a},${o},${l})`)
                }
            }
            presetColors= {d}
        />}>
        {l || <div
        style= {{
            width: i + "px",
            height: i + "px",
            background: e,
            marginLeft: "10px",
            borderRadius: "2px",
            boxShadow: "0 0 2px grey",
            marginBottom: "-4px"
        }} 
        {...n}
        />}
    </Popover2>
};