import { Util, Transform } from '../Util.js';
import { Factory } from '../Factory.js';
import { Node } from '../Node.js';
import { Shape } from '../Shape.js';
import { Rect } from './Rect.js';
import { Group } from '../Group.js';
import { Konva } from '../Global.js';
import { getBooleanValidator, getNumberValidator } from '../Validators.js';
import { _registerNode } from '../Global.js';
var EVENTS_NAME = 'tr-konva';
var ATTR_CHANGE_LIST = [
    'enabledAnchorsChange',
    'anchorSizeChange',
    'borderEnabledChange',
    'borderStrokeChange',
    'borderStrokeWidthChange',
    'borderDashChange',
    'anchorStrokeChange',
    'anchorStrokeWidthChange',
    'anchorFillChange',
    'anchorCornerRadiusChange',
    'ignoreStrokeChange',
]
    .map((e) => e + `.${EVENTS_NAME}`)
    .join(' ');
var NODES_RECT = 'nodesRect';
var TRANSFORM_CHANGE_STR = [
    'widthChange',
    'heightChange',
    'scaleXChange',
    'scaleYChange',
    'skewXChange',
    'skewYChange',
    'rotationChange',
    'offsetXChange',
    'offsetYChange',
    'transformsEnabledChange',
    'strokeWidthChange',
]
    .map((e) => e + `.${EVENTS_NAME}`)
    .join(' ');
var ANGLES = {
    'top-left': -45,
    'top-center': 0,
    'top-right': 45,
    'middle-right': -90,
    'middle-left': 90,
    'bottom-left': -135,
    'bottom-center': 180,
    'bottom-right': 135,
};
const TOUCH_DEVICE = 'ontouchstart' in Konva._global;
function getCursor(anchorName, rad) {
    if (anchorName === 'rotater') {
        return 'crosshair';
    }
    rad += Util.degToRad(ANGLES[anchorName] || 0);
    var angle = ((Util.radToDeg(rad) % 360) + 360) % 360;
    if (Util._inRange(angle, 315 + 22.5, 360) || Util._inRange(angle, 0, 22.5)) {
        return 'ns-resize';
    }
    else if (Util._inRange(angle, 45 - 22.5, 45 + 22.5)) {
        return 'nesw-resize';
    }
    else if (Util._inRange(angle, 90 - 22.5, 90 + 22.5)) {
        return 'ew-resize';
    }
    else if (Util._inRange(angle, 135 - 22.5, 135 + 22.5)) {
        return 'nwse-resize';
    }
    else if (Util._inRange(angle, 180 - 22.5, 180 + 22.5)) {
        return 'ns-resize';
    }
    else if (Util._inRange(angle, 225 - 22.5, 225 + 22.5)) {
        return 'nesw-resize';
    }
    else if (Util._inRange(angle, 270 - 22.5, 270 + 22.5)) {
        return 'ew-resize';
    }
    else if (Util._inRange(angle, 315 - 22.5, 315 + 22.5)) {
        return 'nwse-resize';
    }
    else {
        Util.error('Transformer has unknown angle for cursor detection: ' + angle);
        return 'pointer';
    }
}
var ANCHORS_NAMES = [
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
];
var MAX_SAFE_INTEGER = 100000000;
function getCenter(shape) {
    return {
        x: shape.x +
            (shape.width / 2) * Math.cos(shape.rotation) +
            (shape.height / 2) * Math.sin(-shape.rotation),
        y: shape.y +
            (shape.height / 2) * Math.cos(shape.rotation) +
            (shape.width / 2) * Math.sin(shape.rotation),
    };
}
function rotateAroundPoint(shape, angleRad, point) {
    const x = point.x +
        (shape.x - point.x) * Math.cos(angleRad) -
        (shape.y - point.y) * Math.sin(angleRad);
    const y = point.y +
        (shape.x - point.x) * Math.sin(angleRad) +
        (shape.y - point.y) * Math.cos(angleRad);
    return Object.assign(Object.assign({}, shape), { rotation: shape.rotation + angleRad, x,
        y });
}
function rotateAroundCenter(shape, deltaRad) {
    const center = getCenter(shape);
    return rotateAroundPoint(shape, deltaRad, center);
}
function getSnap(snaps, newRotationRad, tol) {
    let snapped = newRotationRad;
    for (let i = 0; i < snaps.length; i++) {
        const angle = Konva.getAngle(snaps[i]);
        const absDiff = Math.abs(angle - newRotationRad) % (Math.PI * 2);
        const dif = Math.min(absDiff, Math.PI * 2 - absDiff);
        if (dif < tol) {
            snapped = angle;
        }
    }
    return snapped;
}
export class PerspectiveTransformer extends Group {
    constructor(config) {
        super(config);
        this._transforming = false;
        this._createElements();
        this._handleMouseMove = this._handleMouseMove.bind(this);
        this._handleMouseUp = this._handleMouseUp.bind(this);
        this.update = this.update.bind(this);
        this.on(ATTR_CHANGE_LIST, this.update);
        if (this.getNode()) {
            this.update();
        }
    }
    attachTo(node) {
        this.setNode(node);
        return this;
    }
    setNode(node) {
        Util.warn('tr.setNode(shape), tr.node(shape) and tr.attachTo(shape) methods are deprecated. Please use tr.nodes(nodesArray) instead.');
        return this.setNodes([node]);
    }
    getNode() {
        return this._nodes && this._nodes[0];
    }
    setNodes(nodes = []) {
        if (this._nodes && this._nodes.length) {
            this.detach();
        }
        this._nodes = nodes;
        if (nodes.length === 1 && this.useSingleNodeRotation()) {
            this.rotation(nodes[0].getAbsoluteRotation());
        }
        else {
            this.rotation(0);
        }
        this._nodes.forEach((node) => {
            const additionalEvents = node._attrsAffectingSize
                .map((prop) => prop + 'Change.' + EVENTS_NAME)
                .join(' ');
            const onChange = () => {
                this._resetTransformCache();
                if (!this._transforming && !this.isDragging()) {
                    this.update();
                }
            };
            node.on(additionalEvents, onChange);
            node.on(TRANSFORM_CHANGE_STR, onChange);
            node.on(`absoluteTransformChange.${EVENTS_NAME}`, onChange);
            node.on(`xChange.${EVENTS_NAME} yChange.${EVENTS_NAME}`, onChange);
            this._proxyDrag(node);
        });
        this._resetTransformCache();
        var elementsCreated = !!this.findOne('.top-left');
        if (elementsCreated) {
            this.update();
        }
        return this;
    }
    _proxyDrag(node) {
        let lastPos;
        node.on(`dragstart.${EVENTS_NAME}`, (e) => {
            lastPos = node.getAbsolutePosition();
            if (!this.isDragging() && node !== this.findOne('.back')) {
                this.startDrag(e, false);
            }
        });
        node.on(`dragmove.${EVENTS_NAME}`, (e) => {
            if (!lastPos) {
                return;
            }
            const abs = node.getAbsolutePosition();
            const dx = abs.x - lastPos.x;
            const dy = abs.y - lastPos.y;
            this.nodes().forEach((otherNode) => {
                if (otherNode === node) {
                    return;
                }
                if (otherNode.isDragging()) {
                    return;
                }
                const otherAbs = otherNode.getAbsolutePosition();
                otherNode.setAbsolutePosition({
                    x: otherAbs.x + dx,
                    y: otherAbs.y + dy,
                });
                otherNode.startDrag(e);
            });
            lastPos = null;
        });
    }
    getNodes() {
        return this._nodes || [];
    }
    getActiveAnchor() {
        return this._movingAnchorName;
    }
    detach() {
        if (this._nodes) {
            this._nodes.forEach((node) => {
                node.off('.' + EVENTS_NAME);
            });
        }
        this._nodes = [];
        this._resetTransformCache();
    }
    _resetTransformCache() {
        this._clearCache(NODES_RECT);
        this._clearCache('transform');
        this._clearSelfAndDescendantCache('absoluteTransform');
    }
    _getNodeRect() {
        return this._getCache(NODES_RECT, this.__getNodeRect);
    }
    __getNodeShape(node, rot = this.rotation(), relative) {
        var rect = node.getClientRect({
            skipTransform: true,
            skipShadow: true,
            skipStroke: this.ignoreStroke(),
        });
        var absScale = node.getAbsoluteScale(relative);
        var absPos = node.getAbsolutePosition(relative);
        var dx = rect.x * absScale.x - node.offsetX() * absScale.x;
        var dy = rect.y * absScale.y - node.offsetY() * absScale.y;
        const rotation = (Konva.getAngle(node.getAbsoluteRotation()) + Math.PI * 2) %
            (Math.PI * 2);
        const box = {
            x: absPos.x + dx * Math.cos(rotation) + dy * Math.sin(-rotation),
            y: absPos.y + dy * Math.cos(rotation) + dx * Math.sin(rotation),
            width: rect.width * absScale.x,
            height: rect.height * absScale.y,
            rotation: rotation,
        };
        return rotateAroundPoint(box, -Konva.getAngle(rot), {
            x: 0,
            y: 0,
        });
    }
    __getNodeRect() {
        var node = this.getNode();
        if (!node) {
            return {
                x: -MAX_SAFE_INTEGER,
                y: -MAX_SAFE_INTEGER,
                width: 0,
                height: 0,
                rotation: 0,
            };
        }
        const totalPoints = [];
        this.nodes().map((node) => {
            const box = node.getClientRect({
                skipTransform: true,
                skipShadow: true,
                skipStroke: this.ignoreStroke(),
            });
            var points = [
                { x: box.x, y: box.y },
                { x: box.x + box.width, y: box.y },
                { x: box.x + box.width, y: box.y + box.height },
                { x: box.x, y: box.y + box.height },
            ];
            var trans = node.getAbsoluteTransform();
            points.forEach(function (point) {
                var transformed = trans.point(point);
                totalPoints.push(transformed);
            });
        });
        const tr = new Transform();
        tr.rotate(-Konva.getAngle(this.rotation()));
        var minX, minY, maxX, maxY;
        totalPoints.forEach(function (point) {
            var transformed = tr.point(point);
            if (minX === undefined) {
                minX = maxX = transformed.x;
                minY = maxY = transformed.y;
            }
            minX = Math.min(minX, transformed.x);
            minY = Math.min(minY, transformed.y);
            maxX = Math.max(maxX, transformed.x);
            maxY = Math.max(maxY, transformed.y);
        });
        tr.invert();
        const p = tr.point({ x: minX, y: minY });
        return {
            x: p.x,
            y: p.y,
            width: maxX - minX,
            height: maxY - minY,
            rotation: Konva.getAngle(this.rotation()),
        };
    }
    getX() {
        return this._getNodeRect().x;
    }
    getY() {
        return this._getNodeRect().y;
    }
    getWidth() {
        return this._getNodeRect().width;
    }
    getHeight() {
        return this._getNodeRect().height;
    }
    _createElements() {
        this._createBack();
        ANCHORS_NAMES.forEach(function (name) {
            this._createAnchor(name);
        }.bind(this));
    }
    _createAnchor(name) {
        var anchor = new Rect({
            stroke: 'rgb(0, 161, 255)',
            fill: 'white',
            strokeWidth: 1,
            name: name + ' _anchor',
            dragDistance: 0,
            draggable: true,
            hitStrokeWidth: TOUCH_DEVICE ? 10 : 'auto',
        });
        var self = this;
        anchor.on('mousedown touchstart', function (e) {
            self._handleMouseDown(e);
        });
        anchor.on('dragstart', (e) => {
            anchor.stopDrag();
            e.cancelBubble = true;
        });
        anchor.on('dragend', (e) => {
            e.cancelBubble = true;
        });
        anchor.on('mouseenter', () => {
            var rad = Konva.getAngle(this.rotation());
            var cursor = getCursor(name, rad);
            anchor.getStage().content &&
                (anchor.getStage().content.style.cursor = cursor);
            this._cursorChange = true;
        });
        anchor.on('mouseout', () => {
            anchor.getStage().content &&
                (anchor.getStage().content.style.cursor = '');
            this._cursorChange = false;
        });
        this.add(anchor);
    }
    _getNodeQuadrilateral() {
        var node = this.getNode();
        if (!node)
            return;
        else if (node.attrs && node.attrs.quadrilateral) {
            return node.attrs.quadrilateral;
        }
        else {
            console.log(node.attrs);
        }
    }
    _setNodeQuadrilateral(quadrilateral) {
        var node = this.getNode();
        if (!node)
            return;
        node.setAttrs({ quadrilateral });
    }
    _createBack() {
        var back = new Shape({
            name: 'back',
            width: 0,
            height: 0,
            draggable: true,
            sceneFunc(ctx) {
                var tr = this.getParent();
                var padding = tr.padding();
                ctx.beginPath();
                var topLeft = tr.findOne('.top-left');
                var topRight = tr.findOne('.top-right');
                var bottomLeft = tr.findOne('.bottom-left');
                var bottomRight = tr.findOne('.bottom-right');
                ctx.moveTo(topLeft.x() - padding, topLeft.y() - padding);
                ctx.lineTo(topRight.x() + padding, topRight.y() - padding);
                ctx.lineTo(bottomRight.x() + padding, bottomRight.y() + padding);
                ctx.lineTo(bottomLeft.x() - padding, bottomLeft.y() + padding);
                ctx.lineTo(topLeft.x() - padding, topLeft.y() - padding);
                ctx.fillStrokeShape(this);
                ctx.closePath();
            },
            hitFunc: (ctx, shape) => {
                if (!this.shouldOverdrawWholeArea()) {
                    return;
                }
                var padding = this.padding();
                ctx.beginPath();
                ctx.moveTo(shape.x() - padding, shape.y() - padding);
                ctx.lineTo(shape.x() + padding, shape.y() - padding);
                ctx.lineTo(shape.x() + padding, shape.y() + padding);
                ctx.lineTo(shape.x() - padding, shape.y() + padding);
                ctx.lineTo(shape.x() - padding, shape.y() - padding);
                ctx.fillStrokeShape(shape);
                ctx.closePath();
            },
        });
        this.add(back);
        this._proxyDrag(back);
        back.on('dragstart', (e) => {
            e.cancelBubble = true;
        });
        back.on('dragmove', (e) => {
            e.cancelBubble = true;
        });
        back.on('dragend', (e) => {
            e.cancelBubble = true;
        });
        this.on('dragmove', (e) => {
            this.update();
        });
    }
    _handleMouseDown(e) {
        this._movingAnchorName = e.target.name().split(' ')[0];
        var attrs = this._getNodeRect();
        var width = attrs.width;
        var height = attrs.height;
        var hypotenuse = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
        this.sin = Math.abs(height / hypotenuse);
        this.cos = Math.abs(width / hypotenuse);
        if (typeof window !== 'undefined') {
            window.addEventListener('mousemove', this._handleMouseMove);
            window.addEventListener('touchmove', this._handleMouseMove);
            window.addEventListener('mouseup', this._handleMouseUp, true);
            window.addEventListener('touchend', this._handleMouseUp, true);
        }
        this._transforming = true;
        var ap = e.target.getAbsolutePosition();
        var pos = e.target.getStage().getPointerPosition();
        this._anchorDragOffset = {
            x: pos.x - ap.x,
            y: pos.y - ap.y,
        };
        this._fire('perspectiveTransformstart', { evt: e, target: this.getNode() });
        this._nodes.forEach((target) => {
            target._fire('perspectiveTransformstart', { evt: e, target });
        });
    }
    _handleMouseMove(e) {
        var anchorNode = this.findOne('.' + this._movingAnchorName);
        var stage = anchorNode.getStage();
        stage.setPointersPositions(e);
        const pp = stage.getPointerPosition();
        var newNodePos = {
            x: pp.x - this._anchorDragOffset.x,
            y: pp.y - this._anchorDragOffset.y,
        };
        const oldAbs = anchorNode.getAbsolutePosition();
        anchorNode.setAbsolutePosition(newNodePos);
        const newAbs = anchorNode.getAbsolutePosition();
        if (oldAbs.x === newAbs.x && oldAbs.y === newAbs.y) {
            return;
        }
        var topLeft = this.findOne('.top-left');
        var topRight = this.findOne('.top-right');
        var bottomLeft = this.findOne('.bottom-left');
        var bottomRight = this.findOne('.bottom-right');
        var quadrilateral = this._getNodeQuadrilateral();
        if (quadrilateral) {
            var node = this.getNode();
            const parentTransform = node.getParent().getAbsoluteTransform();
            var ratio = parentTransform.m[0];
            quadrilateral = {
                topLeftX: topLeft.x() / ratio,
                topLeftY: topLeft.y() / ratio,
                topRightX: topRight.x() / ratio,
                topRightY: topRight.y() / ratio,
                bottomLeftX: bottomLeft.x() / ratio,
                bottomLeftY: bottomLeft.y() / ratio,
                bottomRightX: bottomRight.x() / ratio,
                bottomRightY: bottomRight.y() / ratio
            };
            this._setNodeQuadrilateral(quadrilateral);
        }
    }
    _handleMouseUp(e) {
        this._removeEvents(e);
    }
    getAbsoluteTransform() {
        return this.getTransform();
    }
    _removeEvents(e) {
        if (this._transforming) {
            this._transforming = false;
            if (typeof window !== 'undefined') {
                window.removeEventListener('mousemove', this._handleMouseMove);
                window.removeEventListener('touchmove', this._handleMouseMove);
                window.removeEventListener('mouseup', this._handleMouseUp, true);
                window.removeEventListener('touchend', this._handleMouseUp, true);
            }
            var node = this.getNode();
            this._fire('transformend', { evt: e, target: node });
            if (node) {
                this._nodes.forEach((target) => {
                    target._fire('transformend', { evt: e, target });
                });
            }
            this._movingAnchorName = null;
        }
    }
    _fitNodesInto(newAttrs, evt) {
        return;
        var oldAttrs = this._getNodeRect();
        const minSize = 1;
        if (Util._inRange(newAttrs.width, -this.padding() * 2 - minSize, minSize)) {
            this.update();
            return;
        }
        if (Util._inRange(newAttrs.height, -this.padding() * 2 - minSize, minSize)) {
            this.update();
            return;
        }
        var t = new Transform();
        t.rotate(Konva.getAngle(this.rotation()));
        if (this._movingAnchorName &&
            newAttrs.width < 0 &&
            this._movingAnchorName.indexOf('left') >= 0) {
            const offset = t.point({
                x: -this.padding() * 2,
                y: 0,
            });
            newAttrs.x += offset.x;
            newAttrs.y += offset.y;
            newAttrs.width += this.padding() * 2;
            this._movingAnchorName = this._movingAnchorName.replace('left', 'right');
            this._anchorDragOffset.x -= offset.x;
            this._anchorDragOffset.y -= offset.y;
        }
        else if (this._movingAnchorName &&
            newAttrs.width < 0 &&
            this._movingAnchorName.indexOf('right') >= 0) {
            const offset = t.point({
                x: this.padding() * 2,
                y: 0,
            });
            this._movingAnchorName = this._movingAnchorName.replace('right', 'left');
            this._anchorDragOffset.x -= offset.x;
            this._anchorDragOffset.y -= offset.y;
            newAttrs.width += this.padding() * 2;
        }
        if (this._movingAnchorName &&
            newAttrs.height < 0 &&
            this._movingAnchorName.indexOf('top') >= 0) {
            const offset = t.point({
                x: 0,
                y: -this.padding() * 2,
            });
            newAttrs.x += offset.x;
            newAttrs.y += offset.y;
            this._movingAnchorName = this._movingAnchorName.replace('top', 'bottom');
            this._anchorDragOffset.x -= offset.x;
            this._anchorDragOffset.y -= offset.y;
            newAttrs.height += this.padding() * 2;
        }
        else if (this._movingAnchorName &&
            newAttrs.height < 0 &&
            this._movingAnchorName.indexOf('bottom') >= 0) {
            const offset = t.point({
                x: 0,
                y: this.padding() * 2,
            });
            this._movingAnchorName = this._movingAnchorName.replace('bottom', 'top');
            this._anchorDragOffset.x -= offset.x;
            this._anchorDragOffset.y -= offset.y;
            newAttrs.height += this.padding() * 2;
        }
        if (this.boundBoxFunc()) {
            const bounded = this.boundBoxFunc()(oldAttrs, newAttrs);
            if (bounded) {
                newAttrs = bounded;
            }
            else {
                Util.warn('boundBoxFunc returned falsy. You should return new bound rect from it!');
            }
        }
        const baseSize = 10000000;
        const oldTr = new Transform();
        oldTr.translate(oldAttrs.x, oldAttrs.y);
        oldTr.rotate(oldAttrs.rotation);
        oldTr.scale(oldAttrs.width / baseSize, oldAttrs.height / baseSize);
        const newTr = new Transform();
        newTr.translate(newAttrs.x, newAttrs.y);
        newTr.rotate(newAttrs.rotation);
        newTr.scale(newAttrs.width / baseSize, newAttrs.height / baseSize);
        const delta = newTr.multiply(oldTr.invert());
        this._nodes.forEach((node) => {
            var _a;
            const parentTransform = node.getParent().getAbsoluteTransform();
            const localTransform = node.getTransform().copy();
            localTransform.translate(node.offsetX(), node.offsetY());
            const newLocalTransform = new Transform();
            newLocalTransform
                .multiply(parentTransform.copy().invert())
                .multiply(delta)
                .multiply(parentTransform)
                .multiply(localTransform);
            const attrs = newLocalTransform.decompose();
            node.setAttrs(attrs);
            this._fire('transform', { evt: evt, target: node });
            node._fire('transform', { evt: evt, target: node });
            (_a = node.getLayer()) === null || _a === void 0 ? void 0 : _a.batchDraw();
        });
        this.rotation(Util._getRotation(newAttrs.rotation));
        this._resetTransformCache();
        this.update();
        this.getLayer().batchDraw();
    }
    forceUpdate() {
        this._resetTransformCache();
        this.update();
    }
    _batchChangeChild(selector, attrs) {
        const anchor = this.findOne(selector);
        anchor.setAttrs(attrs);
    }
    update() {
        var _a;
        var attrs = this._getNodeRect();
        this.rotation(Util._getRotation(attrs.rotation));
        var width = attrs.width;
        var height = attrs.height;
        var padding = this.padding();
        var anchorSize = this.anchorSize();
        var quadrilateral = this._getNodeQuadrilateral();
        if (!quadrilateral) {
            var node = this.getNode();
            if (node) {
                const parentTransform = node.getParent().getAbsoluteTransform();
                var ratio = parentTransform.m[0];
                quadrilateral = {
                    topLeftX: 0,
                    topLeftY: 0,
                    topRightX: width / ratio,
                    topRightY: 0,
                    bottomRightX: width / ratio,
                    bottomRightY: height / ratio,
                    bottomLeftX: 0,
                    bottomLeftY: height / ratio
                };
                this._setNodeQuadrilateral(quadrilateral);
            }
        }
        var anchorSize = this.anchorSize();
        var padding = this.padding();
        this.find('._anchor').forEach((node) => {
            node.setAttrs({
                width: anchorSize,
                height: anchorSize,
                offsetX: anchorSize / 2 + padding,
                offsetY: anchorSize / 2 + padding,
                stroke: this.anchorStroke(),
                strokeWidth: this.anchorStrokeWidth(),
                fill: this.anchorFill(),
                cornerRadius: this.anchorCornerRadius(),
            });
            var name = node.name().split(' ')[0];
            var nodeContent = this.getNode();
            var ratio = 1;
            if (nodeContent) {
                const parentTransform = nodeContent.getParent().getAbsoluteTransform();
                ratio = parentTransform.m[0];
            }
            if (quadrilateral) {
                var anchorPosition = name == 'top-left' ? {
                    x: quadrilateral.topLeftX * ratio,
                    y: quadrilateral.topLeftY * ratio,
                } :
                    name == 'top-right' ? {
                        x: quadrilateral.topRightX * ratio,
                        y: quadrilateral.topRightY * ratio,
                    } :
                        name == 'bottom-left' ? {
                            x: quadrilateral.bottomLeftX * ratio,
                            y: quadrilateral.bottomLeftY * ratio,
                        } :
                            name == 'bottom-right' ? {
                                x: quadrilateral.bottomRightX * ratio,
                                y: quadrilateral.bottomRightY * ratio,
                            } : {};
                node.setAttrs(anchorPosition);
            }
        });
        this._batchChangeChild('.back', {
            width: width,
            height: height,
            visible: this.borderEnabled(),
            stroke: this.borderStroke(),
            strokeWidth: this.borderStrokeWidth(),
            dash: this.borderDash(),
            x: 0,
            y: 0,
        });
        (_a = this.getLayer()) === null || _a === void 0 ? void 0 : _a.batchDraw();
    }
    isTransforming() {
        return this._transforming;
    }
    stopTransform() {
        if (this._transforming) {
            this._removeEvents();
            var anchorNode = this.findOne('.' + this._movingAnchorName);
            if (anchorNode) {
                anchorNode.stopDrag();
            }
        }
    }
    destroy() {
        if (this.getStage() && this._cursorChange) {
            this.getStage().content && (this.getStage().content.style.cursor = '');
        }
        Group.prototype.destroy.call(this);
        this.detach();
        this._removeEvents();
        return this;
    }
    toObject() {
        return Node.prototype.toObject.call(this);
    }
}
function validateAnchors(val) {
    if (!(val instanceof Array)) {
        Util.warn('enabledAnchors value should be an array');
    }
    if (val instanceof Array) {
        val.forEach(function (name) {
            if (ANCHORS_NAMES.indexOf(name) === -1) {
                Util.warn('Unknown anchor name: ' +
                    name +
                    '. Available names are: ' +
                    ANCHORS_NAMES.join(', '));
            }
        });
    }
    return val || [];
}
PerspectiveTransformer.prototype.className = 'PerspectiveTransformer';
_registerNode(PerspectiveTransformer);
Factory.addGetterSetter(PerspectiveTransformer, 'enabledAnchors', ANCHORS_NAMES, validateAnchors);
Factory.addGetterSetter(PerspectiveTransformer, 'flipEnabled', true, getBooleanValidator());
Factory.addGetterSetter(PerspectiveTransformer, 'anchorSize', 10, getNumberValidator());
Factory.addGetterSetter(PerspectiveTransformer, 'borderEnabled', true);
Factory.addGetterSetter(PerspectiveTransformer, 'anchorStroke', 'rgb(0, 161, 255)');
Factory.addGetterSetter(PerspectiveTransformer, 'anchorStrokeWidth', 1, getNumberValidator());
Factory.addGetterSetter(PerspectiveTransformer, 'anchorFill', 'white');
Factory.addGetterSetter(PerspectiveTransformer, 'anchorCornerRadius', 0, getNumberValidator());
Factory.addGetterSetter(PerspectiveTransformer, 'borderStroke', 'rgb(0, 161, 255)');
Factory.addGetterSetter(PerspectiveTransformer, 'borderStrokeWidth', 1, getNumberValidator());
Factory.addGetterSetter(PerspectiveTransformer, 'borderDash');
Factory.addGetterSetter(PerspectiveTransformer, 'centeredScaling', false);
Factory.addGetterSetter(PerspectiveTransformer, 'ignoreStroke', false);
Factory.addGetterSetter(PerspectiveTransformer, 'padding', 0, getNumberValidator());
Factory.addGetterSetter(PerspectiveTransformer, 'node');
Factory.addGetterSetter(PerspectiveTransformer, 'nodes');
Factory.addGetterSetter(PerspectiveTransformer, 'boundBoxFunc');
Factory.addGetterSetter(PerspectiveTransformer, 'shouldOverdrawWholeArea', false);
Factory.addGetterSetter(PerspectiveTransformer, 'useSingleNodeRotation', true);
Factory.backCompat(PerspectiveTransformer, {
    lineEnabled: 'borderEnabled',
    enabledHandlers: 'enabledAnchors',
});
