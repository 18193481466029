
import Konva from "konva";
import { isGradient, parseColor } from "./gradient";

const SVG_SHAPES = ["path", "rect", "circle"];

function isInsideDef(e) {
    for (; e.parentNode;) {
        if ("defs" === e.nodeName) return !0;
        e = e.parentNode
    }
    return !1
}

function getAllElementsWithColor(e){
    for (var t = [], r = e.getElementsByTagName("*"), o = 0, n = r.length; o < n; o++) {
        const e = r[o];
        isInsideDef(e) || (null !== e.getAttribute("fill") && t.push(e), (null !== e.getAttribute("stroke") || e.style && e.style.fill || SVG_SHAPES.indexOf(e.nodeName) >= 0) && t.push(e))
    }
    return t
}

function getElementColors(e) {
    const t = {
        fill: "",
        stroke: ""
    };
    e.getAttribute("fill") && "none" !== e.getAttribute("fill") && (t.fill = e.getAttribute("fill")), 
    !t.fill && e.style.fill && "none" !== e.style.fill && (t.fill = e.style.fill), 
    e.getAttribute("stroke") && (t.stroke = e.getAttribute("stroke")), 
    !t.stroke && e.style.stroke && (t.stroke = e.style.stroke), 
    t.stroke || t.fill || (t.fill = "black")
    return t
}

function createGradient(e, t, r, o) {
    var n = e.namespaceURI,
        i = document.createElementNS(n, "linearGradient");
    const s = Konva.Util.degToRad(r + 90),
        l = (Math.cos(s) + 1) / 2,
        a = (Math.sin(s) + 1) / 2,
        u = (Math.cos(s + Math.PI) + 1) / 2,
        c = (Math.sin(s + Math.PI) + 1) / 2;
    i.setAttribute("x1", 100 * l + "%"), i.setAttribute("y1", 100 * a + "%"), i.setAttribute("x2", 100 * u + "%"), i.setAttribute("y2", 100 * c + "%"), i.setAttribute("gradientUnits", "userSpaceOnUse"), i.setAttribute("id", t);
    for (var g = 0; g < o.length; g++) {
        var f = o[g],
            d = document.createElementNS(n, "stop");
        for (var p in f) f.hasOwnProperty(p) && d.setAttribute(p, f[p]);
        i.appendChild(d)
    }
    return (e.querySelector("defs") || e.insertBefore(document.createElementNS(n, "defs"), e.firstChild)).appendChild(i)
}

export async function urlToString(e) {
    const t = await fetch(e, {
        mode: "cors"
    });
    return await t.text()
}

export function replaceColors(e, t) {
    var r = (new DOMParser).parseFromString(e, "image/svg+xml");
    const o = getAllElementsWithColor(r);
    const n = Array.from(t.keys);
    

    o.forEach(((e:any) => {
        const {
            fill: o,
            stroke: i
        } = getElementColors(e);
        [{
            prop: "fill",
            color: o
        }, {
            prop: "stroke",
            color: i
        }].forEach((({
            prop: o,
            color: i
        }) => {
            const s = n.find(((e:any) => {
                return t = Konva.Util.colorToRGBA(e), !(!(r = Konva.Util.colorToRGBA(i)) || !r) && t.r === r.r && t.g === r.g && t.b === r.b && t.a === r.a;
                var t, r
            }));
            if (s) {
                const n = t.get(s);
                if (isGradient(n)) {
                    const [t, i, s] = parseColor(n), l = [{
                        offset: "0%",
                        "stop-color": i
                    }, {
                        offset: "100%",
                        "stop-color": s
                    }], a = "color" + Math.round(1e8 * Math.random());
                    createGradient(r.children[0], a, t, l), e.setAttribute(o, `url('#${a}')`)
                } else e.style[o] = t.get(s)
            }
        }))
    }));
}

export function fixSize(e){
    var t = (new DOMParser).parseFromString(e, "image/svg+xml");
    const r = t.documentElement.getAttribute("viewBox"),
        [o, n, i, s] = (null == r ? void 0 : r.split(" ")) || [];
    t.documentElement.getAttribute("width") || t.documentElement.setAttribute("width", i + "px"), t.documentElement.getAttribute("height") || t.documentElement.setAttribute("height", s + "px");
    return (new XMLSerializer).serializeToString(t)
}

export async function getSvgSize(e) {
    const t = await urlToString(e);
    const r = (new DOMParser).parseFromString(t, "image/svg+xml").documentElement.getAttribute("viewBox"),
        [o, n, i, s] = (null == r ? void 0 : r.split(" ")) || [];
    return {
        width: parseFloat(i),
        height: parseFloat(s)
    }
}
function svgToURL(e) {
    return "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(e)))
}
export async function urlToBase64(e) {
    const t = await fetch(e);
    return svgToURL(await t.text())
}

export function getColors(e) {
    const t = getAllElementsWithColor((new DOMParser).parseFromString(e, "image/svg+xml")),
        r = [];
    return t.forEach((e => {
        const {
            fill: t,
            stroke: o
        } = getElementColors(e);
        [t, o].forEach((e => {
            if (!e) return;
            Konva.Util.colorToRGBA(e) && -1 === r.indexOf(e) && r.push(e)
        }))
    })), r
}
