import { observer } from 'mobx-react-lite';
import { StoreType } from '../model/store';
import React from "react"
import { Alignment, Button, Navbar } from '@blueprintjs/core';
import FlipButton from './flip-button';
import FiltersPicker from './filters-picker';
import { t as translate } from '../utils/l10n';
import { ColorPicker } from './color-picker';
type Props = {
    store: StoreType;
};

export const SvgToolbar= observer(({ store: e }: Props) => {
    
    const t = e.selectedElements[0];
    return <Navbar.Group align= {Alignment.LEFT}> 
    <FlipButton
        element= {t}
    /> 
    <FiltersPicker
        //@ts-ignore
        element= {t}
        store= {e}
    /> 
    {t.maskSrc && <Button
        minimal= {!0}
        onClick= {() => {
            t.set({
                maskSrc: ""
            })
        }
        }>{translate("toolbar.removeMask")}
        </Button>
    }
    {/*@ts-ignore*/}
    {!t.maskSrc && t.colors.slice(0, 5).map((r => <ColorPicker 
        key= {r}
        value= {t.colorsReplace.get(r) || r}
        onChange= {e => {
            t.replaceColor(r, e)
        }}
        store= {e}
        gradientEnabled= {!0}
    />))
    }
    </Navbar.Group>
});