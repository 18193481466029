

import  SidePanel, { TextSection }  from "./side-panel/side-panel";
import { BackgroundSection, PhotosSection, SizeSection } from "./side-panel/side-panel"
import React from 'react';
import Toolbar from "./toolbar/toolbar"
import ZoomButtons from "./toolbar/zoom-buttons"
import Topbar from './topbar';
import Workspace from "./canvas/workspace";
import { loadJSONFile } from './file';
import styled from "./utils/styled";
//import { Preview } from './preview';
const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};



const PureElectricContainer = styled("div")
`
    display: flex;
    height: 100%;
    width: 100%;
    max-height: 100vh;

    @media screen and (max-width: 500px) {
        flex-direction: column-reverse;
    }
`;

const SidePanelWrap = styled("div")
`
    height: 100%;
    width: 400px;
    max-height: 100vh;

    @media screen and (max-width: 500px) {
        height: auto;
        width: 100vw;
    }
`

const WorkspaceWrap = styled("div")
`
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto;
  flex: 1;
  flex-direction: column;
  position: relative;
`;


const App = ({ store }) => {
  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          loadJSONFile(file, store);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        loadJSONFile(ev.dataTransfer.files[i], store);
      }
    }
  };
  const height = useHeight();
  const DEFAULT_SECTIONS = [ BackgroundSection, PhotosSection, TextSection, SizeSection];
  return (
    <div
      style={{
        width: '100vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onDrop={handleDrop}>
      <Topbar store={store} />
      <div style={{ height: 'calc(100% - 50px)' }}>

        <PureElectricContainer className="polotno-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={DEFAULT_SECTIONS} defaultSection="background"/>
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} />
            <Workspace store={store} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PureElectricContainer>
      </div>
    </div>
  );
};

export default App;
