import { types, getSnapshot, applySnapshot, resolvePath, onSnapshot } from "mobx-state-tree";

const UndoManager = types
    .model( "UndoManager", 
        {
            history: types.array(types.frozen()),
            undoIdx: -1,
            targetPath: ""
        }
    )
    .views((self) => ({
            get canUndo() {
              return self.undoIdx > 0
            },
            get canRedo() {
                return self.undoIdx < self.history.length - 1
            }
          })
        )
    .actions( ((self ) => {
        let e, o, r = !1,
            a = null,
            n = 0,
            s = !1;
    
        function d() {
            const t = getSnapshot(e);
            return {
                pages: t.pages,
                width: t.width,
                height: t.height
            }
        }
    
        function i(t) {
            applySnapshot(e.pages, t.pages), e.setSize(t.width, t.height)
        }
        return {
            startTransaction() {
                s = !0
            },
            endTransaction(t) {
                s = !1, t || this.requestAddState(d())
            },
            async ignore(e) {
                self.startTransaction();
                try {
                    await e()
                } catch (t) {
                    setTimeout((() => {
                        throw t
                    }))
                }
                self.endTransaction(!0), n = 0, clearTimeout(n)
            },
            async transaction(e) {
                await self.ignore(e), this.addUndoState()
            },
            requestAddState(t) {
                a = t, n || s || (r ? r = !1 : n = setTimeout((() => {
                    n = 0, clearTimeout(n), s || this.addUndoState()
                }), 100))
            },
            addUndoState() {
                if (r) return void(r = !1);
                const e = self.history[self.undoIdx];
                JSON.stringify(e) !== JSON.stringify(a) && (self.history.splice(self.undoIdx + 1), 
                self.history.push(a), self.undoIdx = self.history.length - 1)
            },
            afterCreate() {
                if (e = resolvePath(self, ".."), !e) throw new Error("Failed to find target store for UndoManager. Please provide `targetPath` property, or a `targetStore` in the environment");
                o = onSnapshot(e, (() => this.requestAddState(d()))), 0 === self.history.length && this.requestAddState(d())
            },
            clear() {
                clearTimeout(n), 
                n = 0, self.history.splice(0, self.history.length), 
                self.undoIdx = -1, self.addUndoState(d())
            },
            beforeDestroy() {
                o()
            },
            undo() {
                n && this.addUndoState(), self.canUndo ? (self.undoIdx--, r = !0, i(self.history[self.undoIdx])) : console.warn("No undo history. Please check `store.history.canUndo` before calling undo action.")
            },
            redo() {
                n && this.addUndoState(), self.canRedo ? (self.undoIdx++, r = !0, i(self.history[self.undoIdx])) : console.warn("No redo history. Please check `store.history.canRedo` before calling redo action.")
            },
            replaceState() {
                self.history[self.undoIdx] = d()
            }
        }
    }));

export default UndoManager;