
import { ElementType } from '../model/store';
import React from 'react';
import { Rect } from 'react-konva';
export const Highlighter = (({ element }/*: {
    element: ElementType;
} */) => {
    //@ts-ignore
    return <Rect
        name= "highlighter"
        x= {element.x}
        y= {element.y}
        rotation= {element.rotation}
        //@ts-ignore
        width= {element.width}
        //@ts-ignore
        height= {element.height}
        listening= {!1}
        stroke= "rgb(0, 161, 255)"
        strokeWidth= {2}
        strokeScaleEnabled= {!1}
    />
})
