export async function downloadFile(url, fileName, t) {
    const content = await (await fetch(url)).blob();

    const aTag = document.createElement("a"),
        href = URL.createObjectURL(content);
        aTag.href = href, 
        aTag.download = fileName, 
        document.body.appendChild(aTag), 
        aTag.click(), 
        setTimeout((function () {
            document.body.removeChild(aTag), 
            window.URL.revokeObjectURL(href)
        }), 0)
}