import Konva from 'konva';
import React from 'react';
import { useEffect } from 'react';
import { getClientRect } from '../utils/math';
var GUIDELINE_OFFSET = 5;

function getObjectSnappingEdges(e) {
    const t = e.__getNodeRect(),
        i = getClientRect(Object.assign(Object.assign({}, t), {
            rotation: Konva.Util.radToDeg(t.rotation)
        })),
        a = e.getAbsolutePosition();
    return {
        vertical: [{
            guide: i.x,
            offset: a.x - i.x,
            snap: "start"
        }, {
            guide: i.x + i.width / 2,
            offset: a.x - i.x - i.width / 2,
            snap: "center"
        }, {
            guide: i.x + i.width,
            offset: a.x - i.x - i.width,
            snap: "end"
        }],
        horizontal: [{
            guide: i.y,
            offset: a.y - i.y,
            snap: "start"
        }, {
            guide: i.y + i.height / 2,
            offset: a.y - i.y - i.height / 2,
            snap: "center"
        }, {
            guide: i.y + i.height,
            offset: a.y - i.y - i.height,
            snap: "end"
        }]
    }
}

function getGuides(e, t) {
    var i = [],
        a = [];
    e.vertical.forEach((e => {
        t.vertical.forEach((t => {
            var a = Math.abs(e - t.guide);
            a < GUIDELINE_OFFSET && i.push({
                lineGuide: e,
                diff: a,
                snap: t.snap,
                offset: t.offset
            })
        }))
    })), e.horizontal.forEach((e => {
        t.horizontal.forEach((t => {
            var i = Math.abs(e - t.guide);
            i < GUIDELINE_OFFSET && a.push({
                lineGuide: e,
                diff: i,
                snap: t.snap,
                offset: t.offset
            })
        }))
    }));
    var n = [],
        o = i.sort(((e, t) => e.diff - t.diff))[0],
        r = a.sort(((e, t) => e.diff - t.diff))[0];
    return o && n.push(Object.assign({
        orientation: "V"
    }, o)), r && n.push(Object.assign(Object.assign({}, r), {
        orientation: "H"
    })), n
}

export const useSnap = (e/*: {
    current: Konva.Transformer | null;
}*/) => {
    const t = e => e.hasName("element") || e.hasName("page-background");
    const i = i => {
        i.target.getLayer().find(".guid-line").forEach((e => e.destroy()));
        var a = getGuides(function (i) {
            var a;
            const n = null === (a = e.current) || void 0 === a ? void 0 : a.getStage();
            var o = [],
                r = [];
            return n.find(t).forEach((e => {
                if (!(i.indexOf(e) >= 0)) {
                    var t = e.getClientRect({
                        skipShadow: !0,
                        skipStroke: !0
                    });
                    o.push(t.x, t.x + t.width, t.x + t.width / 2), r.push(t.y, t.y + t.height, t.y + t.height / 2)
                }
            })), {
                vertical: o,
                horizontal: r
            }
        }(i.target.nodes()), getObjectSnappingEdges(i.target));
        if (!a.length) return;
        //@ts-ignore
        ! function (t) {
            var i;
            const a = null === (i = e.current) || void 0 === i ? void 0 : i.getLayer();
            t.forEach((e => {
                if ("H" === e.orientation) {
                    var t = new Konva.Line({
                        points: [-6e3, e.lineGuide, 6e3, e.lineGuide],
                        stroke: "rgb(0, 161, 255)",
                        strokeWidth: 1,
                        name: "guid-line",
                        dash: [4, 6]
                    });
                    a.add(t), a.batchDraw()
                } else "V" === e.orientation && (t = new Konva.Line({
                    points: [e.lineGuide, -6e3, e.lineGuide, 6e3],
                    stroke: "rgb(0, 161, 255)",
                    strokeWidth: 1,
                    name: "guid-line",
                    dash: [4, 6]
                }), a.add(t), a.batchDraw())
            }))
        }(a);
        const n = i.target.getAbsolutePosition(),
            o = Object.assign({}, n);
        a.forEach((e => {
            switch (e.snap) {
                case "start":
                case "center":
                case "end":
                    switch (e.orientation) {
                        case "V":
                            o.x = e.lineGuide + e.offset;
                            break;
                        case "H":
                            o.y = e.lineGuide + e.offset
                    }
            }
        }));
        const r = o.x - n.x,
            s = o.y - n.y;
        i.target.nodes().forEach((e => {
            const t = e.getAbsolutePosition();
            e.setAbsolutePosition({
                x: t.x + r,
                y: t.y + s
            })
        }))
    }

    const a = e => {
        const t = e.target.getLayer();
        t.find(".guid-line").forEach((e => e.destroy())), t.batchDraw()
    };
    useEffect(() => {
        e.current && (e.current.on("dragmove", i), e.current.on("dragend", a))
    })
}
