
import parse from "gradient-parser";
export const isGradient =  e => e.indexOf("linear-gradient") >= 0;

const stopToColor = e => "hex" === e.type ? "#" + e.value : "literal" === e.type ? e.value : `${e.type}(${e.value.join(",")})`;

export const parseColor = e => {
    if (!isGradient(e)) return [0, e, e];
    const r = parse(e)[0]
    const t = parseFloat(r.orientation.value)
    const  o = r.colorStops[0]
    const   s = stopToColor(o)
    const   a = r.colorStops[1];
    return [t, s, stopToColor(a)]
};