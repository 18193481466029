import { Boundary, Button, ButtonGroup, InputGroup, Menu, MenuItem, NumericInput, OverflowList, Position, Slider } from "@blueprintjs/core";
import { forwardRef, useEffect, useRef, useState } from "react";
import { getGoogleFontImage, getGoogleFontsListAPI } from "../utils/api";
import styled from "../utils/styled"
import React from "react";
import { getFontsList, globalFonts, isGoogleFontChanged } from "../utils/fonts";
import { Popover2 } from "@blueprintjs/popover2";
import swr from "swr"
import { FixedSizeList } from "react-window";
import { observer } from "mobx-react-lite";
import { ColorPicker } from "./color-picker";
import { MdcFormatLineSpacing } from "@meronex/icons/mdc";
import FiltersPicker from "./filters-picker";
import { StoreType, TextElementType } from '../model/store';
import { ReactElement } from "react";

const Image = styled("img")
`
  height: 20px;

  .bp3-dark & {
    filter: invert(1);
  }
`

const googleFonts = getFontsList();

const FontItem = ({
    fontFamily: e,
    handleClick: t,
    modifiers: n,
    store: o,
    isCustom: r
  }) => {
    useEffect((() => {
        r && o.loadFont(e)
    }), [e, r]);
  const a = r ? e : <Image
    src= {getGoogleFontImage(e)}
  />
  return <MenuItem
    text= {a}
    active= {n.active}
    disabled= {n.disabled}
    onClick= {t}
    style= {{
      fontFamily: '"' + e + '"'
    }}
  />
}

const SearchInput = ({
  onChange: e
}) => {
  const t = useRef(null);
  useEffect((() => {
    t.current && t.current.focus()
  }), [])
  return <InputGroup
    leftIcon= {"search"}
    inputRef= {t}
    onChange= {t => e(t.target.value)}
  />
}

const FontMenu = ({
    store,
    fonts,
    activeFont,
    activeFontLabel,
    onFontSelect
  }) => {
    const [a, l] = useState(""),
    i = fonts.filter((e => e.toLowerCase().indexOf(a.toLowerCase()) >= 0));
    return <Popover2
      content= {<div>
        <SearchInput
            onChange= {e => l(e)}
        /> 
        <div style= {{ paddingTop: "5px" }}> 
          <FixedSizeList
              //@ts-ignore
              innerElementType= {forwardRef(((e, t) => <Menu ref= {t} { ...e}/>))}
              height= {Math.min(400, 30 * i.length) + 10}
              width= {210}
              itemCount= {i.length}
              itemSize= {30}
              children= {({
                  index: t,
                  style: o
                  }) => {
                  const a = i[t];
                  return <div style= {o} >
                      <FontItem
                          key= {a}
                          fontFamily= {a}
                          modifiers= {{
                              active: activeFont === a
                          }}
                          handleClick= {() => onFontSelect(a)}
                          store= {store}
                          isCustom= {store.fonts.find((e => e.fontFamily === a)) || globalFonts.find((e => e.fontFamily === a))}
                      />
                      </div>
                  }
              }
          />
          </div>

          
        </div>
    }>
    <Button
          text= {activeFontLabel}
          rightIcon= "caret-down"
          minimal= {!0}
          style= {{
              marginRight: "5px",
              fontFamily: '"' + activeFont + '"',
              overflow: "hidden",
              whiteSpace: "nowrap"
          }}
      />
    </Popover2>
}

export const FontFamilyInput = observer(({ element, store}  ) => {
    const {
      data,
      mutate
    } = swr(getGoogleFontsListAPI(),
        { isPaused: () => isGoogleFontChanged(),
            initialData: []
        });
    useEffect((() => {
      mutate()
    }), [isGoogleFontChanged()]);

    const fonts = store.fonts.concat(globalFonts).map((e => e.fontFamily)).concat((null == data ? void 0 : data.length) ? data : googleFonts);
    let fontFamily = element.fontFamily;
    if(fontFamily.length > 15) fontFamily = element.fontFamily.slice(0, 15) + "...";
    return  <FontMenu
      fonts= {fonts}
      activeFont= {element.fontFamily}
      activeFontLabel= {fontFamily}
      store= {store}
      onFontSelect= {fontSelect => {
        element.set({
          fontFamily: fontSelect
        })
      }}
    />
    }
)

export const FontSizeInput = observer(({ element } ) => <NumericInput
    onValueChange= {t => {
      element.set({
        fontSize: t
      })
    }}
    value= {Math.round(element.fontSize)}
    style= {{
      width: "50px"
    }}
    min= {5}
/>);

const ALIGN_OPTIONS = ["left", "center", "right"];

export const FontStyleGroup = observer(({
    element
  } ) => <ButtonGroup> 
    <Button
        minimal= {!0}
        //@ts-ignore
        icon= {"align-" + element.align}
        onClick= {() => {
        const t = (ALIGN_OPTIONS.indexOf(element.align) + 1 + ALIGN_OPTIONS.length) % ALIGN_OPTIONS.length,
            n = ALIGN_OPTIONS[t];
            element.set({
                align: n
                })
            }}
    />

    <Button
        minimal= {!0}
        icon= "underline"
        active= {element.textDecoration.indexOf("underline") >= 0}
        onClick= {() => {
            let t = element.textDecoration.split(" ");
            t.indexOf("underline") >= 0 ? t = t.filter((e => "underline" !== e)) : t.push("underline"), element.set({
            textDecoration: t.join(" ")
            })
        }}
    />
</ButtonGroup>
)

export const FontColorInput = observer(({ element, store } ) => <ColorPicker
        value= {element.fill}
        onChange= {t => element.set({
            fill: t
        })}
        store= {store}
    />)


export const SpacingInput = observer(({  element }  ) => <Popover2
        content= {<div
            style= {{
            padding: "15px 25px",
            width: "200px"
          }}>
          <p> {"Line height"} </p>
          <Slider
              value= {"number" == typeof element.lineHeight ? 100 * element.lineHeight : 120}
              labelStepSize= {50}
              onChange= {t => {
                element.set({
                  lineHeight: t / 100
                  })
              }}
              min= {50}
              max= {250}
              stepSize= {10}
              showTrackFill= {!0}
          /> 
          <p>{"Letter spacing"}</p> 
          
          <Slider
              value= {100 * element.letterSpacing}
              labelStepSize= {50}
              onChange= {t => {
                  element.set({
                  letterSpacing: t / 100
                  })
              }}
              min= {-50}
              max= {250}
              stepSize= {10}
              showTrackFill= {!1}
          />
        </div>}
        position= {Position.BOTTOM}>
        <Button
          icon= {<MdcFormatLineSpacing
              className= "bp3-icon"
              style= {{
                  fontSize: "20px"
              }}
          />}
          minimal= {!0}
        />
    </Popover2>
);

const PROPS_MAP = {
    font: FontFamilyInput,
    fontSize: FontSizeInput,
    fontVariant: FontStyleGroup,
    filter: FiltersPicker,
    fontColor: FontColorInput,
    spacing: SpacingInput
}

const Container = styled("div")
`
  width: calc(100% - 450px);

  @media screen and (max-width: 500px) {
    width: auto;
  }
`;


type PageProps = {
    store: StoreType;
    hideTextSpacing?: boolean;
    hideTextEffects?: boolean;
    hideTextUnderline?: boolean;
    hideTextItalic?: boolean;
    hideTextBold?: boolean;
};
export const TextToolbar = observer(({store: e,
    hideTextEffects: t,
    hideTextSpacing: n,
    hideTextBold: o,
    hideTextItalic: r,
    hideTextUnderline: a
  }: PageProps) => {
    const l = e.selectedElements[0],
      i = ["fontColor", "font", "fontSize", "fontVariant", !n && "spacing", !t && "filter"].filter((e => !!e));
    return  <Container
    className= "bp3-navbar-group bp3-align-left"> 
    <OverflowList
    items= {i}
    style= {{
        width: "100%"
    }}
    visibleItemRenderer= {t => {
        const ItemRenderer = PROPS_MAP[t];
        return <ItemRenderer
            element= {l}
            store= {e}
            key= {t}
            hideTextBold= {o}
            hideTextItalic= {r}
            hideTextUnderline= {a}
            />
        }}
    collapseFrom= {Boundary.END}
    overflowRenderer= {t => <Popover2
        content= {
            <div style= {{ padding: "10px", display: "flex" }}> 
            {t.map((t => {
                const ItemForRender = PROPS_MAP[t];
                return <ItemForRender
                    key= {t}
                    element= {l}
                    store= {e}
                    hideTextBold= {o}
                    hideTextItalic= {r}
                    hideTextUnderline= {a}
                />
            }))}
            </div>
            }
            position= {Position.BOTTOM}> 
            <Button
                icon= "more"
                minimal= {!0}
                style= {{
                    marginLeft: "20px"
                }}
            />
        </Popover2> 
    }>
    
    </OverflowList>
  </Container>
})

export default TextToolbar;