import Konva from 'konva';
import { ElementType, StoreType } from '../model/store';

export const applyFilter = (e: ElementType, t: StoreType) => {
    var l;
    const r = {
        filters: []
    };
    //@ts-ignore
    t.brightnessEnabled && (r.filters.push(Konva.Filters.Brighten), 
    //@ts-ignore
    r.brightness = t.brightness), t.blurEnabled && (r.filters.push(Konva.Filters.Blur), 
    //@ts-ignore
    r.blurRadius = t.blurRadius), t.sepiaEnabled && r.filters.push(Konva.Filters.Sepia), 
    //@ts-ignore
    t.grayscaleEnabled && r.filters.push(Konva.Filters.Grayscale), e.setAttrs(r), 
    //@ts-ignore
    r.filters.length ? e.cache() : e.clearCache(), null === (l = e.getLayer()) || void 0 === l || l.batchDraw()
}