import { action, observable, toJS } from "mobx";

const translation = observable({
    toolbar: {
        opacity: "Opacity",
        effects: "Effects",
        blur: "Blur",
        brightness: "Brightness",
        sepia: "Sepia",
        grayscale: "Grayscale",
        textStroke: "Text Stroke",
        shadow: "Shadow",
        border: "Border",
        position: "Position",
        layering: "Layering",
        toForward: "To forward",
        up: "Up",
        down: "Down",
        toBottom: "To bottom",
        alignLeft: "Align left",
        alignCenter: "Align center",
        alignRight: "Align right",
        alignTop: "Align top",
        alignMiddle: "Align middle",
        alignBottom: "Align bottom",
        flip: "Flip",
        rotate: "Rotate",
        rotateLeft: "Rotate Left",
        rotateRight: "Rotate Right",
        
        flipHorizontally: "Flip horizontally",
        flipVertically: "Flip vertically",
        fitToBackground: "Fit to background",
        crop: "Crop",
        cropDone: "Done",
        cropCancel: "Cancel",
        removeMask: "Remove mask",
        transparency: "Transparency",
        lockedDescription: "Object is locked. Unlock it to allow changes from canvas.",
        unlockedDescription: "Object is unlocked. Lock it to prevent changes from canvas.",
        removeElements: "Remove elements",
        duplicateElements: "Duplicate elements",
        download: "Download",
        saveAsImage: "Save as image",
        saveAsPDF: "Save as PDF",
        saveAsHDPDF: "Save as High Quality PDF"
    },
    scale: {
        reset: "Reset"
    },
    sidePanel: {
        templates: "Templates",
        otherFormats: "Other formats",
        noResults: "No results",
        text: "Text",
        uploadFont: "Upload font",
        myFonts: "My fonts",
        photos: "Photos",
        elements: "Elements",
        upload: "Upload",
        uploadImage: "Upload Image",
        uploadTip: "Do you want to upload your own images?",
        background: "Background",
        resize: "Resize",
        useMagicResize: "Use magic resize",
        magicResizeDescription: "Magic resize will automatically resize and move all elements on the canvas"
    }
})

function get(e, t) {
    var o, a = t.split("."),
        s = e;
    for (o = 0; o < a.length; ++o) {
        if (null == s[a[o]]) return;
        s = s[a[o]]
    }
    return s
}
const isObject = e => e && "object" == typeof e;

function mergeDeep(e, t) {
    Object.keys(t).forEach((o => {
        const a = e[o],
            s = t[o];
        isObject(s) && isObject(a) ? mergeDeep(a, s) : e[o] = s
    }))
}

export const setTranslations = action(json => {
    mergeDeep(translation, json)
});

export const getTranslations = () => toJS(translation);
const WARNED = {};
export const t = path => {
    const t = get(translation, path);
    if (void 0 !== t) return t;
    WARNED[path] || (WARNED[path] = !0, console.warn(`Missing translation '${path}'`));
    const o = path.split(".");
    return o[o.length - 1]
};

