import { getQuoteId } from "./global";

export async function uploadFile(file , pageId = 1, isThumbnail = false, callback = () => {}) {
    fetch(`https://suitecrm.pure-electric.com.au/index.php?entryPoint=APIStudioTool&quoteId=${getQuoteId()}&pageId=${pageId}`, {
      // content-type header should not be specified!
      //&fileName=${fileName}&quote_id=${getQuoteId()}
      headers: {
        'Accept': 'application/json, text/plain, */*',
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
      method: 'POST',
      body: file,
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      callback();
    })
    .catch(error => {
      console.error(error)
    })
}