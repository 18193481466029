import { Tab, Tabs } from "@blueprintjs/core";
import React, { Fragment, useRef, useState } from "react"
import { useSWRInfinite } from "swr";
import { getImageSize } from "../utils/image";
import { ImagesGrid } from "./images-grid";
import {t as translate} from "../utils/l10n"
import { getSubDomain } from "../utils/global";

const getInitIconType = () => {
    var sub = getSubDomain();
    if (sub == "batteriesdesign")
        return "Batteries";
    else if (sub == "daikindesign")
        return "Daikins";
    else if (sub == "solargrounddesign")
        return "SolarGround";
    else if (sub == "evchargingdesign")
        return "EVCharging";
    else 
        return "Tanks";
}
export const PhotosPanel = ({store}) => {
    const canvas = useRef("canvas");
    const time = useRef();
    const [text, setText] = useState(getInitIconType());

    const {data, error, mutate, size, setSize, isValidating } 
        = useSWRInfinite((page => `https://sandentool.pure-electric.com.au/APIGetIcon.php?icon-type=${text}&per_page=20&page=${page+1}`), {
        revalidateOnFocus: !1
    });

    
    const isLoading = !data && !error ||  ( size > 0 && data && undefined === data[size - 1]);
    var firstData = data == null ? undefined : data[0];
    const ableToLoadMore = 
        firstData === undefined ? undefined:
            data.length == 0 ? undefined :
                data && data[0].total_pages == size;
    var sub = getSubDomain();
    return <div
            style= {{
                height: "100%",
                display: "flex",
                flexDirection: "column"
            }}>
        <Tabs
          large= {!0}
          onChange= {evt => setText(evt)}> 
            { ( sub == "localhost:3000" || sub == "studio" ) && <Tab id= "Tanks"> {translate("sidePanel.Tanks")} </Tab> }
            { ( sub == "localhost:3000" ||  sub == "studio" ) && <Tab id ="HeatPumps"> {translate("Heat Pumps")} </Tab>  }
            { ( sub == "localhost:3000" || sub == "studio" ) && <Tab id ="Miscellaneous"> {translate("sidePanel.Miscellaneous")} </Tab> }

            { sub == "daikindesign" && <Tab id= "Daikins"> {translate("sidePanel.Daikins")} </Tab> }
            { sub == "daikindesign" && <Tab id= "Indoor"> {translate("sidePanel.Indoor")} </Tab> }
            { sub == "daikindesign" && <Tab id= "Outdoor"> {translate("sidePanel.Outdoor")} </Tab> }
            { sub == "daikindesign" && <Tab id ="Miscellaneous"> {translate("sidePanel.Miscellaneous")} </Tab> }

            { sub == "batteriesdesign" && <Tab id= "Batteries"> {translate("sidePanel.Batteries")} </Tab> }
            { sub == "solargrounddesign" && <Tab id= "SolarGround"> {"Solar Ground"} </Tab> }
            { sub == "evchargingdesign" && <Tab id= "EVCharging"> {"EV Charging"} </Tab> }
            { sub == "evchargingdesign" && <Tab id ="Miscellaneous"> {translate("sidePanel.Miscellaneous")} </Tab> }
        </Tabs>
        <ImagesGrid
            images= {null == data ? undefined : data.map((image => image.results)).flat()}
            getPreview= {image => image.urls.small}
            onSelect= {
                async (img, pos, elem) => {
                    var activePage = store.activePage;
                    if (elem && "svg" === elem.type) { 
                        elem.set({
                            maskSrc: img.urls.regular
                        });
                        return
                    }
                    var { width, height} = await getImageSize(img.urls.small); 
                    var ratio = width/height;
                    if ( ratio >= 1 ) {
                        if(width >= activePage.width/4) {
                            width = activePage.width/4;
                            height = width/ratio;
                        }
                    } 

                    if ( ratio < 1 ) {
                        if(height >= activePage.height/4) {
                            height = activePage.height/4;
                            width = height*ratio;
                        }
                    }
                    
                    const x = ((null == pos ? undefined : pos.x) || store.width / 2) - width / 2;
                    const y = ((null == pos ? undefined : pos.y) || store.height / 2) - height / 2;
                    null === activePage || undefined === activePage || activePage.addElement({
                        type: "image",
                        src:img.urls.regular,
                        width: width,
                        height: height,
                        x: x,
                        y: y
                    })
                }
            }
            isLoading= {isLoading}
            loadMore= {() => {
                if(!ableToLoadMore)  setSize(size + 1)
            }}
            getCredit= {props => <span></span>}
        
        />
    </div>
}