import { Button, Menu, MenuItem, Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { observer } from "mobx-react-lite";
import React from "react";
import {t as translate} from "../utils/l10n";
import { getClientRect } from "../utils/math";
import { StoreType, ElementType } from "../model/store";

interface Props {
    store: StoreType;
}

const PositionPicker = observer((({ store } : Props ) => {
    var t;
    const o = store.selectedElements.length > 0,
        l = store.selectedElements[0],
        n = (null === (t = store.activePage) || void 0 === t ? void 0 : t.children.indexOf(l)) || 0,
        r = store.activePage && n < store.activePage.children.length - 1,
        i = n > 0;
    return <Popover2
        disabled= {!o}
        content= {<Menu><Menu.Divider
            title= {translate("toolbar.layering")}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "double-chevron-up"
            text= {translate("toolbar.toForward")}
            disabled= {!r}
            onClick= {() => {
                l.moveTop()
            }}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "chevron-up"
            text= {translate("toolbar.up")}
            disabled= {!r}
            onClick= {() => {
                l.moveUp()
            }}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "chevron-down"
            text= {translate("toolbar.down")}
            disabled= {!i}
            onClick= {() => {
                l.moveDown()
            }}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= {"double-chevron-down"}
            text= {translate("toolbar.toBottom")}
            disabled= {!i}
            onClick= {() => {
                l.moveBottom()
            }}
        /> 
        <Menu.Divider
            title= {translate("toolbar.position")}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "alignment-left"
            text= {translate("toolbar.alignLeft")}
            onClick= {() => {
                let t = store.width;
                store.selectedElements.forEach((e => {
                    t = Math.min(t, getClientRect(e).x)
                })), store.selectedElements.forEach((e => {
                    e.set({
                        x: e.x - t
                    })
                }))
            }}
        /> 
        
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "alignment-vertical-center"
            text= {translate("toolbar.alignCenter")}
            onClick= {() => {
                let t = store.width,
                    o = 0;
                store.selectedElements.forEach((e => {
                    const l = getClientRect(e);
                    t = Math.min(t, l.x), o = Math.max(o, l.x + l.width)
                }));
                const l = o - t,
                    n = store.width / 2 - l / 2 - t;
                store.selectedElements.forEach((e => {
                    e.set({
                        x: e.x + n
                    })
                }))
            }}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "alignment-right"
            text= {translate("toolbar.alignRight")}
            onClick= {() => {
                let t = 0;
                store.selectedElements.forEach((e => {
                    const o = getClientRect(e);
                    t = Math.max(t, o.x + o.width)
                })), 
                store.selectedElements.forEach((o => {
                    getClientRect(o);
                    o.set({
                        x: o.x + store.width - t
                    })
                }))
            }}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "alignment-top"
            text= {translate("toolbar.alignTop")}
            onClick= {() => {
                let t = store.height;
                store.selectedElements.forEach((e => {
                    t = Math.min(t, getClientRect(e).y)
                })), 
                store.selectedElements.forEach((e => {
                    e.set({
                        y: e.y - t
                    })
                }))
            }}
        /> 
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "alignment-horizontal-center"
            text= {translate("toolbar.alignMiddle")}
            onClick= {() => {
                let t = store.height,
                    o = 0;
                store.selectedElements.forEach((e => {
                    const l = getClientRect(e);
                    t = Math.min(t, l.y), o = Math.max(o, l.y + l.height)
                }));
                const l = o - t,
                    n = store.height / 2 - l / 2 - t;
                store.selectedElements.forEach((e => {
                    e.set({
                        y: e.y + n
                    })
                }))
            }}
        />
        <MenuItem
            shouldDismissPopover= {!1}
            icon= "alignment-bottom"
            text= {translate("toolbar.alignBottom")}
            onClick= {() => {
                let t = 0;
                store.selectedElements.forEach((e => {
                    const o = getClientRect(e);
                    t = Math.max(t, o.y + o.height)
                })), store.selectedElements.forEach((o => {
                    getClientRect(o);
                    o.set({
                        y: o.y + store.height - t
                    })
                }))
            }}
        />
        </Menu>
        
        }
        position= {Position.BOTTOM} > 
        <Button
            icon= "layers"
            minimal= {!0}
            text= {translate("toolbar.position")}
            disabled= {!o}
        />
    </Popover2>
}))

export default PositionPicker;