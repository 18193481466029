import { StoreType } from '../model/store';
import React from "react"
import styled from "../utils/styled";
import { observer } from 'mobx-react-lite';
import { Alignment, Divider, Navbar } from '@blueprintjs/core';
import TextToolbar from './text-toolbar';
import ImageToolbar from './image-toolbar';
import { SvgToolbar } from './svg-toolbar';
import { HistoryButtons } from './history-buttons';
import PositionPicker from './position-picker';
import { OpacityPicker } from './opacity-picker';
import { LockButton } from './lock-button';
import { DuplicateButton } from './duplicate-button';
import { DownloadButton } from './download-button';
import { RemoveButton } from './remove-button';

/*type ToolbarProps = {
    store: StoreType;
    downloadButtonEnabled?: Boolean;
    hideTextSpacing?: boolean;
    hideTextEffects?: boolean;
    hideImageFlip?: boolean;
    hideImageEffects?: boolean;
    hideImageCrop?: boolean;
    hideImageFit?: boolean;
    hidePosition?: boolean;
    hideOpacity?: boolean;
    hideDuplicate?: boolean;
    hideLock?: boolean;
    hideRemove?: boolean;
};*/

const ComponentsTypes = {
    text: TextToolbar,
    image: ImageToolbar,
    svg: SvgToolbar
};

export function registerToolbarComponent(e, t) {
    ComponentsTypes[e] = t
}

const NavbarContainer = styled("div")
`
  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`

const NavInner = styled("div")
`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;
export const  Toolbar = observer(({ store,
    downloadButtonEnabled,
    hideTextSpacing: o,
    hideTextEffects: r,
    hideImageFlip: a,
    hideImageEffects: i,
    hideImageCrop,
    hideImagePerspective,
    hideImageFit,
    hidePosition,
    hideOpacity: c,
    hideDuplicate: u,
    hideLock: d,
    hideRemove: s 
}/*: ToolbarProps*/) => {
    const hasSelected = 1 === store.selectedElements.length,
        selectedElement = store.selectedElements[0],
        ToolbarComponent = hasSelected && ComponentsTypes[selectedElement.type];
        const cropMode = hasSelected && selectedElement._cropModeEnabled;
        const perspectiveMode = hasSelected && selectedElement._perspectiveModeEnabled;
    return  <NavbarContainer
        className= "bp3-navbar">
        <NavInner>
            {!cropMode && !perspectiveMode && <HistoryButtons store= {store}/>}
            {ToolbarComponent &&  <ToolbarComponent
                store= {store}
                hideTextSpacing= {o}
                hideTextEffects= {r}
                hideImageFlip= {a}
                hideImageEffects= {i}
                hideImageCrop= {hideImageCrop}
                hideImagePerspective={hideImagePerspective}
                hideImageFit= {hideImageFit}
            />} 
            {!cropMode && !perspectiveMode && <Navbar.Group align= {Alignment.RIGHT}> 
                {!hidePosition && <PositionPicker
                    store= {store}
                />}
                {!c && <OpacityPicker
                    store= {store}
                />}

                {!d && <LockButton
                    store= {store}
                />}
                
                {!u && <DuplicateButton
                    store= {store}
                />} 
                
                {!s && <RemoveButton
                    store= {store}
                />}
                {downloadButtonEnabled && <React.Fragment>
                    <Divider  style= {{ height: "100%", margin: "0 15px" }} />
                    <DownloadButton store= {store}/>
                </React.Fragment>
                }
                </Navbar.Group>
            }
        </NavInner>
            
    </NavbarContainer>
})

export default Toolbar;