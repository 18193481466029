
import { StoreType } from '../model/store';
import React from 'react';
import {t as translate } from "../utils/l10n";
import { Tooltip2 } from '@blueprintjs/popover2';
import { Button } from '@blueprintjs/core';

export const DuplicateButton = ((/*{ store: e }: {
    store: StoreType;
}*/{ store }) => {

    const t = store.selectedElements.length > 0;
    return <Tooltip2
        content= {translate("toolbar.duplicateElements")}
        disabled= {!t}>   
        <Button
        icon= "duplicate"
        minimal= {!0}
        onClick= {() => {
            const t = [];
            store.selectedElements.forEach((e => {
                t.push(e.clone({
                    x: e.x + 50,
                    y: e.y + 50
                }).id)
            })), store.selectElements(t)
        }}
        disabled= {!t}
    /></Tooltip2>
})