import { observer } from 'mobx-react-lite';
import { useSWRInfinite } from 'swr';
import { API } from '../utils/base-url';
import { getKey } from '../utils/validate-key';
import {t} from "../utils/l10n"
import React from "react"
import { useState } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { ImagesGrid } from './images-grid';
//@ts-ignore
const Templates = observer(({sizeQuery, store}) => {
    const { data, error, mutate, size, setSize, isValidating } = 
        useSWRInfinite(
            (index => `${API}/get-templates?${sizeQuery}&per_page=30&page=${index+1}&KEY=${getKey()}`),
            { revalidateOnFocus: false}
        );
    const isLoading = !data && !error || (size > 0 && data && typeof data[size -1] === "undefined")
    var firstData = data ? data[0] : undefined ;
    
    const ableToLoadMore = 
            firstData === undefined ? undefined:
                data.length == 0 ? undefined :
                    data && data[0].totalPages == size
    //@ts-ignore
    return <ImagesGrid
                images= {null == data ? undefined : data.map((e => e.items)).flat()}
                getPreview= {element => element.preview}
                isLoading= {isLoading}
                onSelect={
                    async e => {
                        const r = await fetch(e.json),
                          a = await r.json();
                        store.loadJSON(a, !0)
                    }
                }
                loadMore={
                    () => {
                        if(!ableToLoadMore) setSize(size + 1)
                    }
                }
            />
})
//@ts-ignore
export const TemplatesPanel = observer(({store}) => {
    const currentSize = `${store.width}x${store.height}`;
    const    [format, setFormat] = useState("same-formats");
    return <div
        style= {{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
        <Tabs
            large= {false}
            //@ts-ignore
            onChange=  { format => setFormat(format) }>
            <Tab
                id= "same-formats">
                {currentSize}
            </Tab>
            <Tab
                id= "other-formats">
                {t("sidePanel.otherFormats")}
            </Tab>
        </Tabs>
        {"same-formats" === format && 
            <Templates 
                //@ts-ignore
                store= {store}
                sizeQuery= {"exclude=" + currentSize}
            />
        }
    </div>
})