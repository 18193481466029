import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react"
import { useCallback } from "react";
import { useSWRInfinite } from "swr";
import { ImagesGrid } from "./images-grid";
import { getRecord } from "../utils/global";
import { Tabs, Tab, Button } from "@blueprintjs/core";
import {t as translate} from "../utils/l10n"
import { localFileToURL } from "../utils/file";
import { getImageSize } from "../utils/image";
import { uploadFile } from "../utils/upload";

let uploadFunc = async e => localFileToURL(e);
let filesCache = [];

export const BackgroundPanel = observer(({store}) => {
    const pattern = useRef("pattern"),
        timeoutRef = useRef();
    const {
        data,
        error,
        size,  
        setSize,
        mutate
    } = useSWRInfinite((page => `https://suitecrm.pure-electric.com.au/index.php?entryPoint=APIGetImageFromQuote&pre_install_photos_c=${getRecord()}&per_page=20&page=${page+1}`), {
        revalidateOnFocus: false
    });
    
    const isLoading = !data && !error ||  ( size > 0 && data && undefined === data[size - 1]);
    var firstData = data == null ? undefined : data[0];
    const ableToLoadMore = 
        firstData === undefined ? undefined :
            data.length == null ? undefined :
                data && data[0].total_pages == size
    const m = useCallback((e => {
        pattern.current = e, 
        clearTimeout(o.current), 
        timeoutRef.current = setTimeout((() => {
            mutate()
        }), 500)
      }), []);

    const [text, setText] = useState("photos");
    const [stateFilesCache, setStateFilesCache] = useState(filesCache);
    const [isUploading, setIsUploading] =  useState(false);
    
    useEffect(() => {
        filesCache = stateFilesCache
    }, [stateFilesCache]);

    const  handlePaste = async (e) => {
        if (e.clipboardData.files.length) {
          const fileObject = e.clipboardData.files[0];
          console.log(fileObject);
          const t = await uploadFunc(fileObject);
          await uploadFile(t, 1, false, () => { mutate (); } );
        } else {
          alert(
            "No image data was found in your clipboard. Copy an image first or take a screenshot."
          );
        }
      };

    return <div
        style= {{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}
        onPaste={handlePaste}
        >

        <div
        style= {{
            //height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
        <div style= {{ height: "45px", paddingTop: "5px" }}>
            {translate("sidePanel.uploadTip")}
        </div>
        <div style= {{ marginBottom: "20px" }}>
            <label htmlFor= "input-file">
                <Button
                    icon= "upload"
                    style= {{
                        width: "100%"
                    }}
                    onClick= {() => {
                        var e;
                        null === (e = document.querySelector("#input-file")) || void 0 === e || e.click()
                    }}>
                        {translate("sidePanel.uploadImage")}
                </Button>
                <input
                    type= "file"
                    id= "input-file"
                    style= {{
                        display: "none"
                    }}
                    onChange= {async e => {
                        const {
                            target: t
                        } = e;
                        setIsUploading(true);
                        for (const e of t.files) {
                            const t = await uploadFunc(e);
                            console.log(e);
                            await uploadFile(t, 1, false, () => { mutate (); } );
                            setStateFilesCache((e => e.concat([t])))
                        }
                        
                        setIsUploading(false), 
                        t.value = null
                    }}
                    multiple= {!0}>
                </input>

            </label>
        </div>
        </div>
        

        <ImagesGrid
            images= {null == data ? undefined : data.map((res => res.results)).flat()}
            isLoading= {isUploading}
            getPreview= {img => img.urls.small}
            loadMore= {() => {
                ableToLoadMore || setSize(size + 1)
            }}
            onSelect= {
                async img => {

                    var activePage = store.activePage; 
                    null === activePage || undefined === activePage || activePage.set({
                        background: img.urls.regular
                    })
                    activePage.setSize(img.width, img.height);
                }
                
            }
            getCredit= {cre => null}
        />
        
    </div>
    }
)