import { Button, Menu, MenuItem, Position } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import {t as translation} from "../utils/l10n"
import React from "react"
import Konva from "konva";

function getCenter(shape) {
    var radRotation = Konva.getAngle(shape.rotation);

    return {
      x:
        shape.x +
        (shape.width / 2) * Math.cos(radRotation) +
        (shape.height / 2) * Math.sin(radRotation),
      y:
        shape.y +
        (shape.height / 2) * Math.cos(radRotation) +
        (shape.width / 2) * Math.sin(radRotation),
    };
}

function rotateAroundCenter(shape, deltaRad) {
    const center = getCenter(shape);
    return rotateAroundPoint(shape, deltaRad, center);
}

function rotateAroundPoint(shape, angleRad, point) {
    const x =
      point.x +
      (shape.x - point.x) * Math.cos(angleRad) -
      (shape.y - point.y) * Math.sin(angleRad);
    const y =
      point.y +
      (shape.x - point.x) * Math.sin(angleRad) +
      (shape.y - point.y) * Math.cos(angleRad);
    return {
      //...shape,
      //rotation: shape.rotation + angleRad,// Need More work
      x,
      y,
    };
}



export const RotateButton = ({
    element: e
}) => <Popover2
    content= {
        <Menu>
            <MenuItem
                shouldDismissPopover= {!1}
                icon= "image-rotate-right"
                text= {translation("toolbar.rotateRight")}
                onClick= {() => {
                   
                    var deltaRad = 90 * Math.PI / 180;
                    var shape = rotateAroundCenter(e, deltaRad);
                    
                    e.set({
                        rotation: e.rotation + 90,
                        x: shape.x,
                        y: shape.y
                    })
                    //console.log("rotation:" + e.rotation);
                }}
            /> 
            <MenuItem
                shouldDismissPopover= {!1}
                text= {translation("toolbar.rotateLeft")}
                icon= "image-rotate-left"
                onClick= {() => {
                    var deltaRad = - 90 * Math.PI / 180;
                    var shape = rotateAroundCenter(e, deltaRad);
                    
                    e.set({
                        rotation: e.rotation - 90,
                        x: shape.x,
                        y: shape.y
                    })
                }}
            />
        </Menu>
        }
    position= {Position.BOTTOM}> 
    <Button
        text= {translation("toolbar.rotate")}
        minimal= {!0}
    />
</Popover2>
export default RotateButton;