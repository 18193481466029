import Konva from 'konva';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Arc, Group, Image, Rect, Transformer } from 'react-konva';
import { Portal } from 'react-konva-utils';
import useImage from 'use-image';
import { ImageElementType, StoreType } from '../model/store';
import { decrementLoader, incrementLoader } from '../utils/loader';
import { applyFilter } from './apply-filters';
import { Highlighter } from './highlighter';
import { useFadeIn } from './use-fadein';

const  useFlip = (element, image) => useMemo(
    (() => {
        var context, contextImage;
        const {
            flipX,
            flipY
        } = element, 
        isSVG = "svg" === element.type || element.src.indexOf("data:image/svg+xml") >= 0 || element.src.indexOf(".svg") >= 0;
        if (!flipX && !flipY && !isSVG) return image;
        if (!image) return null;
        const canvas = document.createElement("canvas");
        let scale = 1;
        "svg" === element.type && (scale = Math.max(element.width / image.width * 2, element.height / image.height * 2)), 
        canvas.width = Math.max(image.width * scale, 1), 
        canvas.height = Math.max(image.height * scale, 1);
        let width = flipX ? -canvas.width : 0,
            height = flipY ? -canvas.height : 0;
        null === (context = canvas.getContext("2d")) || undefined === context || context.scale(flipX ? -1 : 1, flipY ? -1 : 1), 
        null === (contextImage = canvas.getContext("2d")) || undefined === contextImage || contextImage.drawImage(image, width, height, canvas.width, canvas.height);
        return canvas
    }), 
    [element.flipX, element.flipY, image, element.width, element.height]
);

function getCrop(element, store) {
    const ratio = store.width / store.height;
    let width, height;
    ratio >= element.width / element.height ? (width = element.width, height = element.width / ratio) : (width = element.height * ratio, height = element.height);
    return {
        x: (element.width - width) / 2,
        y: (element.height - height) / 2,
        width: width,
        height: height
    }
}
let useImageHook = useImage;

const useMask = (element, imageSrc) => {
    const [image] = useImageHook(element.maskSrc, "anonymous");
    return useMemo((() => {
            if (!image) return imageSrc;
            if (!imageSrc) return imageSrc;
            const canvas = document.createElement("canvas");
            canvas.width = Math.max(imageSrc.width, 1), 
            canvas.height = Math.max(imageSrc.height, 1);
            const context = canvas.getContext("2d");
            if (!context) return imageSrc;
            context.drawImage(imageSrc, 0, 0), 
            context.globalCompositeOperation = "source-in";
            const i = getCrop(image, element);
            context.drawImage(image, i.x, i.y, i.width, i.height, 0, 0, imageSrc.width, imageSrc.height)
            return  canvas;
        }), 
        [imageSrc, image, element.width, element.height]
    )
}
const useCropImage = (element, originalImage, croppedRect, cornerRadius = 0) => {
    const canvas = useMemo((() => {
        if (originalImage) return document.createElement("canvas")
    }), [originalImage]);
    useLayoutEffect((() => {
        if (!canvas || !originalImage) return;
        const {
            width,
            height
        } = element;
        canvas.width = Math.max(width, 1), 
        canvas.height = Math.max(height, 1);
        const context = canvas.getContext("2d");
        if (!context) return;
        // Draw boder
        const radius = Math.min(cornerRadius, element.width / 2, element.height / 2);
        radius && (context.beginPath(), 
            context.moveTo(radius, 0), 
            context.lineTo(width - radius, 0), 
            context.arc(width - radius, radius, radius, 3 * Math.PI / 2, 0, !1), 
            context.lineTo(width, height - radius), 
            context.arc(width - radius, height - radius, radius, 0, Math.PI / 2, !1), 
            context.lineTo(radius, height), 
            context.arc(radius, height - radius, radius, Math.PI / 2, Math.PI, !1), 
            context.lineTo(0, radius), 
            context.arc(radius, radius, radius, Math.PI, 3 * Math.PI / 2, !1), 
            context.clip());
            
        context.drawImage(originalImage, croppedRect.x, croppedRect.y, croppedRect.width, croppedRect.height, 0, 0, canvas.width, canvas.height)
    }), [canvas, element.width, element.height, croppedRect.x, croppedRect.y, croppedRect.width, croppedRect.height, cornerRadius])
    return canvas;
}

const useClip = (element, croppedImage, others) => {
    
    const [image] = useImageHook(element.clipSrc, "anonymous"), 
    canvas = useMemo(
        (() => {
            if (croppedImage) return document.createElement("canvas")
        }), [croppedImage]);

    useLayoutEffect((() => {
        if (!image) return;
        if (!croppedImage) return;
        if (!canvas) return;
        canvas.width = Math.max(croppedImage.width, 1), 
        canvas.height = Math.max(croppedImage.height, 1);
        const context = canvas.getContext("2d");
        if (!context) return croppedImage;
        context.drawImage(croppedImage, 0, 0), 
        context.globalCompositeOperation = "destination-in";
        //context.drawImage(image, 0, 0, croppedImage.width, croppedImage.height)
    }), 
    [canvas, croppedImage, image, element.width, element.height, ...others]);

    return image ? canvas : croppedImage
}

const usePlaceholder = ({
    width,
    height,
    image,
    status
}) => {
    const canvas = useMemo((() => image ? null : document.createElement("canvas")), [image]);
    return useMemo((() => {
        if (!canvas) return image;
        canvas.width = Math.max(width, 1), 
        canvas.height = Math.max(height, 1);
        const context = canvas.getContext("2d");
        if (!context) return image;
        let loadingColor = "failed" === status ? "rgba(223, 102, 102, 0.8)" : "rgba(124, 173, 212, 0.8)",
            loadingText = "failed" === status ? "Can not load the image..." : "Loading....";
            context.fillStyle = loadingColor, 
            context.fillRect(0, 0, width, height), 
            context.textAlign = "center", 
            context.textBaseline = "middle";
        const fontSize = Math.min(30, width / loadingText.length);
        context.font = `${fontSize}px Arial`, context.fillStyle = "white", context.fillText(loadingText, width / 2, height / 2);
        return canvas
    }), [width, height, image, status, canvas])
}

const LoadingPlaceholder = observer((({
    //@ts-ignore
    element
}) => {
    const t = Math.min(30, element.width / 4, element.height / 4),
    a = useRef(null);
    useEffect((() => {
        const e = a.current;
        if (!e) return;
        const t = new Konva.Animation((t => {
            e.rotate(((null == t ? void 0 : t.timeDiff) || 0) / 2)
        }), e.getLayer());
        return t.start(), () => {
            t.stop()
        }
    })) 
    return <Group
        x= {element.x}
        y= {element.y}
        rotation= {element.rotation}
        listening= {!1}
        opacity= {element.opacity}> 
        <Rect
            width= {element.width}
            height= {element.height}
            fill= "rgba(124, 173, 212, 0.8)"
        /> 
        <Arc
            ref= {a}
            x= {element.width / 2}
            y= {element.height / 2}
            fill= "white"
            outerRadius= {Math.abs(t)}
            innerRadius= {Math.max(1, t - 5)}
            angle= {270}
        />
    </Group>
}));

const setImageLoaderHook = (hook) => {
    useImageHook = hook
};


/*type ImageProps = {
    store: StoreType;
    element: ImageElementType;
};
*/
export const ImageElement = observer((({
    element, store
}/*: ImageProps*/) => {
    
    const [transformed, setTransformed] = useState(!1), 
    imageRef = useRef(null), 
    originalImageRef = useRef(null), 

    [mouseEnter, setMouseEnter] = useState(!1), 
    selectedElement = store.selectedElements.indexOf(element) >= 0, 

    //@ts-ignore
    [image, status] = useImageHook(element.__finalSrc || element.src, "anonymous");// d - u 
    useEffect((() => {
        "loading" === status ? incrementLoader() : decrementLoader()
    }), [status]);


    const [loading, setLoading] = useState("loading");
    useEffect((() => {
        //@ts-ignore
            setLoading(element.__isLoaded ? status : "loading")
        }), 
        //@ts-ignore
        [element.__isLoaded, status]
    ), 

    useEffect((() => {
        "svg" === element.type && ("loading" === loading ? incrementLoader() : decrementLoader())
    }), [loading]);
    
    const flipElement = useRef();
    useEffect((() => {
        //@ts-ignore
        flipElement.current = image || flipElement.current
    }), [image]);

    const placeHolder = usePlaceholder({
        width: Math.max(element.width, 1),
        height: Math.max(element.height, 1),
        image: image,
        status: status
    })
    const flipSVG = "failed" !== status || "failed" !== status && "svg" === element.type,
        originalImage = useMask(element, useFlip(element, image || flipSVG && flipElement.current)) || placeHolder;
    let {
        cropX,
        cropY,
        cropWidth,
        cropHeight
    } = element;
    "loaded" !== status && (cropX = cropY = 0, cropWidth = cropHeight = 1);
    const b = originalImage.width * cropWidth,
        v = originalImage.height * cropHeight,
        E = element.width / element.height;
    let croppedWidth, cropedHeight;
    const S = b / v,
        C = "svg" === element.type;
    C ? (croppedWidth = b, cropedHeight = v) : E >= S ? (croppedWidth = b, cropedHeight = b / E) : (croppedWidth = v * E, cropedHeight = v);
    //@ts-ignore
    var conerRadius = element.cornerRadius;
    const croppedRect = {
            x: originalImage.width * cropX,
            y: originalImage.height * cropY,
            width: croppedWidth,
            height: cropedHeight
        }
        
    const conerRadiusStd = null !== conerRadius && undefined !== conerRadius ? conerRadius : 0;
    var mainImage = useClip(element, useCropImage(element, originalImage, croppedRect, conerRadiusStd), [croppedRect, conerRadiusStd]);
    useFadeIn(imageRef);
    const scaleRatio = Math.max(element.width / croppedWidth, element.height / cropedHeight);
    
    useEffect((() => {
        var image = imageRef.current
        if (!element._cropModeEnabled) return;
        const stage = null === image || undefined === image ? undefined : image.getStage();

        function clickOnOriginalImage(t) {
            element._cropModeEnabled && t.target !== originalImageRef.current && element.toggleCropMode(!1)
        }

        function clickOnElement(t) {
            element._cropModeEnabled && "CANVAS" !== t.target.nodeName && element.toggleCropMode(!1)
        }
        document.body.addEventListener("click", clickOnElement), 
        null == stage || stage.on("click", clickOnOriginalImage), 
        null == stage || stage.on("tap", clickOnOriginalImage);
        return () => {
            document.body.removeEventListener("click", clickOnElement), 
            document.body.removeEventListener("touchstart", clickOnElement), 
            null == stage || stage.off("click", clickOnOriginalImage), 
            null == stage || stage.off("click", clickOnOriginalImage)
        }
    }), [element._cropModeEnabled]), 
    
    useLayoutEffect((() => {
        if (transformed || element._cropModeEnabled) return;
            applyFilter(imageRef.current, element);
            return autorun((() => {
                applyFilter(imageRef.current, element)
            }), {
                delay: 100
            })
        }), 
        [originalImage, transformed, cropWidth, cropHeight, element._cropModeEnabled]
    );
    
    
    useLayoutEffect((() => {
        var  image = imageRef.current
        transformed || element._cropModeEnabled ? null === image || undefined === image || image.clearCache() : applyFilter(imageRef.current, element)
        }), 
        [transformed, element.width, element.height, element._cropModeEnabled]
    )
    
    useEffect((() => {
        applyFilter(imageRef.current, element)
        }), 
        [element.shadowEnabled, element.shadowBlur]
    );

    
    const cropRectRef = useRef(null),
        backTransformRef = useRef(null),
        frontTransformRef = useRef(null);

    useLayoutEffect((() => {
        if(element._cropModeEnabled ) { 
            backTransformRef.current.nodes([cropRectRef.current]);
            frontTransformRef.current.nodes([originalImageRef.current]);
        }
        }), 
        [element._cropModeEnabled]
    );
    
    const dragTransform = t => {
            Math.round(t.target.x()) > 0 && (t.target.x(0), t.target.scaleX(1)), 
            Math.round(t.target.y()) > 0 && (t.target.y(0), t.target.scaleY(1));
            const a = t.target.width() * t.target.scaleX(),
                r = t.target.height() * t.target.scaleY(),
                o = Math.min(1, croppedWidth / a),
                i = Math.min(1, cropedHeight / r),
                n = 1 - o,
                h = Math.min(n, Math.max(0, Math.round(-t.target.x()) / a)),
                c = 1 - i,
                l = Math.min(c, Math.max(0, Math.round(-t.target.y()) / r));
            t.target.setAttrs({
                x: -h * originalImage.width,
                y: -l * originalImage.height,
                scaleX: 1,
                scaleY: 1
            }), element.set({
                cropX: h,
                cropY: l,
                cropWidth: o,
                cropHeight: i
            })
        };
    const doubleClickHandler = () => {
        "svg" !== element.type && (element.locked || setTimeout((() => {
            element.toggleCropMode(!0)
        })))
    },
    
    isSVG = "svg" === element.type && flipElement.current,
    isLoading = "loading" === status && !isSVG,
    cropRef = useRef({
        cropX: 0,
        cropY: 0,
        cropWidth: 0,
        cropHeight: 0
    });

    return <Fragment> 

        {isLoading && <LoadingPlaceholder
            //@ts-ignore
            element = {element}
        />}

        <Image
            ref= {imageRef}
            name= "element"
            image= {mainImage}
            x= {element.x}
            y= {element.y}
            width= {element.width || 1}
            height= {element.height || 1}
            rotation= {element.rotation}
            opacity= {isLoading ? 0 : element.opacity}
            shadowEnabled= {element.shadowEnabled}
            shadowBlur= {element.shadowBlur}
            customCrop= {croppedRect}

            /*perspectTopLeft= {element.perspectTopLeft?element.perspectTopLeft:{}}
            perspectTopRight= {element.perspectTopRight?element.perspectTopRight:{}}
            perspectBottomLeft= {element.perspectBottomLeft?element.perspectBottomLeft:{}}
            perspectBottomRight= {element.perspectBottomRight?element.perspectBottomRight:{}}
            */
            draggable= {!element.locked}
            onMouseEnter= {() => {
                setMouseEnter(!0)
            }}
            onMouseLeave= {() => {
                setMouseEnter(!1)
            }}
            onDragStart= {() => {
                store.history.startTransaction()
            }}
            onDragMove= {ele => {
                element.set({
                    x: ele.target.x(),
                    y: ele.target.y()
                })
            }}
            onDragEnd= {ele => {
                element.set({
                    x: ele.target.x(),
                    y: ele.target.y()
                });
                store.history.endTransaction();
            }}
            id= {element.id}
            onDblClick= {doubleClickHandler}
            onDblTap= {doubleClickHandler}
            onTransformStart= {() => {
                setTransformed(!0), 
                store.history.startTransaction(), 
                cropRef.current = {
                    cropX: element.cropX,
                    cropY: element.cropY,
                    cropWidth: element.cropWidth,
                    cropHeight: element.cropHeight
                }
            }}
            onTransform= {newAttrs => {
                if(element._perspectiveModeEnabled) return;
                var a;
                const r = newAttrs.currentTarget,
                    o = Math.abs(r.scaleX() - 1) < 1e-7 ? 1 : r.scaleX(),
                    i = Math.abs(r.scaleY() - 1) < 1e-7 ? 1 : r.scaleY();
                r.scaleX(1), r.scaleY(1);
                const n = null === (a = newAttrs.target.getStage()) || undefined === a ? undefined : a.findOne("Transformer"),
                    h = 1 - croppedWidth / originalImage.width,
                    c = Math.min(h, Math.max(0, element.cropX)),
                    l = 1 - cropedHeight / originalImage.height,
                    d = Math.min(l, Math.max(0, element.cropY)),
                    u = n.getActiveAnchor(),
                    s = !(u.indexOf("middle") >= 0 || u.indexOf("center") >= 0),
                    g = !s && o < 1 && cropRef.current.cropHeight > cropedHeight / originalImage.height;
                let f = s ? element.cropWidth : element.cropWidth * o;
                g && (f = element.cropWidth);
                const m = !s && i < 1 && cropRef.current.cropWidth > croppedWidth / originalImage.width;
                let _ = s ? element.cropHeight : element.cropHeight * i;
                m && (_ = element.cropHeight), C && (f = element.cropWidth, _ = element.cropHeight), element.set({
                    cropX: c,
                    cropY: d,
                    x: r.x(),
                    y: r.y(),
                    width: r.width() * o,
                    height: r.height() * i,
                    rotation: newAttrs.target.rotation(),
                    cropWidth: Math.min(f, 1 - c),
                    cropHeight: Math.min(_, 1 - d)
                })
            }}
            onTransformEnd= {elem => {
                const target = elem.currentTarget;
                element.set({
                    width: target.width(),
                    height: target.height(),
                    x: target.x(),
                    y: target.y(),
                    rotation: elem.target.rotation(),
                    cropWidth: croppedWidth / originalImage.width,
                    cropHeight: cropedHeight / originalImage.height
                }), 
                setTransformed(!1), 
                store.history.endTransaction()
            }}
        /> 
        {/* Draw Border */}
        <Rect
            x= {element.x}
            y= {element.y}
            width= {element.width - element.borderSize}
            height= {element.height - element.borderSize}
            offsetX= {-element.borderSize / 2}
            offsetY= {-element.borderSize / 2}
            stroke= {element.borderColor}
            strokeWidth= {element.borderSize}
            listening= {false}
            visible= {!!element.borderSize}
            rotation= {element.rotation}
            cornerRadius= {Math.max(0, conerRadiusStd - element.borderSize)}
        />

        {/** Draw crop enable */}
        
        {element._cropModeEnabled && <Portal
            selector= ".page-abs-container"
            enabled= {!0}>
            <Rect
                x= {-2500}
                y= {-2500}
                width= {9500}
                height= {9500}
                fill= "rgba(0,0,0,0.3)"
            /> 
            <Image
                listening= {!1}
                image= {mainImage}
                x= {element.x}
                y= {element.y}
                width= {element.width}
                height= {element.height}
                rotation= {element.rotation}
                shadowEnabled= {element.shadowEnabled}
                shadowBlur= {element.shadowBlur}
            /> 
            <Group
                x= {element.x}
                y= {element.y}
                rotation= {element.rotation}
                scaleX= {scaleRatio}
                scaleY= {scaleRatio}> 
                <Image
                    image= {originalImage}
                    ref= {originalImageRef}
                    opacity= {.4}
                    draggable= {!0}
                    x= {-element.cropX * originalImage.width}
                    y= {-element.cropY * originalImage.height}
                    onDragMove= {dragTransform}
                    onTransform= {dragTransform}
                /> 
                <Transformer
                    ref= {frontTransformRef}
                    anchorSize= {20}
                    enabledAnchors= {["top-left", "top-right", "bottom-left", "bottom-right"]}
                    boundBoxFunc= {(e, t) => t.width < 5 || t.height < 5 ? e : t}
                    rotateEnabled= {!1}
                    borderEnabled= {!1}
                    anchorCornerRadius= {10}
                    anchorStrokeWidth= {2}
                    borderStrokeWidth= {2}
                /> 
                <Rect
                    width= {croppedWidth}
                    height= {cropedHeight}
                    ref= {cropRectRef}
                    listening= {!1}
                    onTransform= {t => {
                        t.target.x() < -element.cropX * originalImage.width - 1e-9 && 
                            (t.target.x(-element.cropX * originalImage.width), t.target.scaleX(1)), 
                        t.target.y() < -element.cropY * originalImage.height - 1e-9 && 
                            (t.target.y(-element.cropY * originalImage.height),  t.target.scaleY(1));

                        const a = Math.min(1, Math.max(0, element.cropX + t.target.x() / originalImage.width)),
                            r = Math.min(1, Math.max(0, t.target.y() / originalImage.height + element.cropY)),
                            o = t.target.width() * t.target.scaleX(),
                            i = t.target.height() * t.target.scaleY(),
                            n = Math.min(1 - a, o / originalImage.width),
                            h = Math.min(1 - r, i / originalImage.height),
                            c = t.target.getAbsolutePosition(t.target.getParent().getParent());
                        t.target.scale({
                            x: 1,
                            y: 1
                        }), 
                        t.target.position({
                            x: 0,
                            y: 0
                        }), 
                        element.set({
                            x: c.x,
                            y: c.y,
                            cropX: a,
                            cropY: r,
                            cropWidth: n,
                            cropHeight: h,
                            width: Math.min(o * scaleRatio, originalImage.width * (1 - a) * scaleRatio),
                            height: Math.min(i * scaleRatio, originalImage.height * (1 - r) * scaleRatio)
                        })
                    }}
                /> 
                <Transformer
                    ref= {backTransformRef}
                    enabledAnchors= {["top-left", "top-right", "bottom-left", "bottom-right"]}
                    boundBoxFunc= {(e, t) => t.width < 5 || t.height < 5 ? e : t}
                    keepRatio= {!1}
                    rotateEnabled= {!1}
                    anchorFill= {"rgb(240, 240, 240)"}
                    anchorStrokeWidth= {2}
                    borderStrokeWidth= {2}
                />
            </Group>
        </Portal>
    }
    {!selectedElement && mouseEnter && <Highlighter
            element= {element}
        />
    }
    </Fragment>
}))
