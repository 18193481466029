import { Button } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import Konva from "konva";
import { action } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React, { Fragment, useEffect, useRef, useState } from "react";
import useImage from "use-image";
import { getCrop } from "../utils/crop";
import Element from "./element";
import { useSnap } from "./use-transformer-snap";
import { Stage, Layer, Text, Rect, Image, Group, Transformer, PerspectiveTransformer, Shape } from 'react-konva';
import { ElementType, PageType, StoreType } from "../model/store";

const DEFAULT_TRANSFORMER_ATTRIBUTES = {
    enabledAnchors: ["top-left", "top-center", "top-right", "middle-left", "bottom-left", "bottom-right", "bottom-center", "middle-right"],
    rotateEnabled: !0,
    rotationSnaps: [0, 45, 90, 135, 180, 225, 270, 315],
    ignoreStroke: !0,
    anchorStrokeWidth: 2,
    borderStrokeWidth: 2
};

const transformerAttributes = {
    text: {
        enabledAnchors: ["top-left", "top-right", "middle-left", "bottom-left", "bottom-right", "middle-right"]
    },
    svg: {
        enabledAnchors: ["top-left", "top-right", "bottom-left", "bottom-right"]
    },
    many: {
        enabledAnchors: ["top-left", "top-right", "bottom-left", "bottom-right"]
    }
};

export function registerTransformerAttrs(e, t) {
    transformerAttributes[e] = transformerAttributes[e] || t, Object.assign(transformerAttributes[e], t);
}
//@ts-ignore
const Background = e => <Rect
    {...e} 
    fill= "#e8e8e8"
    preventDefault= {!1}
/>

const  PageBackground = ({background, ...rest}) => {
    const backgr = background,
    isImageSource = background.indexOf("http") >= 0 || background.indexOf(".png") >= 0 || background.indexOf(".jpg") >= 0, 
    [image] = useImage(isImageSource ? background : "", "anonymous"),
    ImageOrRect = isImageSource ? Image : Rect, 
    cropAttrs = isImageSource && image ? getCrop(image, {
        width: background.width,
        height: background.height
    }, "center-middle") : {};
    return <Fragment>
        <Rect
            x= {-1}
            y= {-1}
            width= {background.width + 2}
            height= {background.height + 2}
            stroke= "lightgrey"
            strokeWidth= {2}
            listening= {!1}
        />
        <ImageOrRect  image= {image} {...rest}  fill= {isImageSource ? "white" : backgr} {...cropAttrs} />
    </Fragment>
}
/*type SelectionProps = {
    selection:any
};*/

const Selection = observer((({
    selection
}/*: SelectionProps*/) => selection.visible ? <Rect
    name= "selection"
    x= {Math.min(selection.x1, selection.x2)}
    y= {Math.min(selection.y1, selection.y2)}
    width= {Math.abs(selection.x1 - selection.x2)}
    height= {Math.abs(selection.y1 - selection.y2)}
    fill= "rgba(0, 161, 255, 0.3)"
/> : null))

/*type ElementsProps = {
    elements: [ElementType],
    store: StoreType;

}
*/
const Elements = observer(
    ({ elements, store }/*: ElementsProps */) => <Fragment> 
        {elements.map((e => <Element
            key= {e.id}
            store= {store}
            element= {e}
            onClick= {() => {
                console.warn("Polotno warning: onClick callback is deprecated. Just stop using it. Polotno will do selection automatically.")
            }}
        />))
        }
    </Fragment>
);

let onDomDrop = null;
export const registerNextDomDrop = e => {
    onDomDrop = e
};

/*type PageProps = {
    store: StoreType;
    page: PageType;
    xPadding: number;
    yPadding: number;
    width: number;
    height: number;
    pageControlsEnabled?: boolean;
};
*/
export default observer((
    { store, page, dimension, pageControlsEnabled }/*: PageProps*/) => {

    const transformerRef = useRef(null), perspectiveTransformerRef = useRef(null),
        stageRef = useRef(null),
        containerRef = useRef(null),
        isPageControlled = null == pageControlsEnabled || pageControlsEnabled,
        hasMorePage = store.pages.length > 1,
        indexOfPage = store.pages.indexOf(page),
        elementCropModeEnabled = store.selectedElements.find((e => e._cropModeEnabled)),
        elementPerspectiveModeEnabled = store.selectedElements.find((e => e._perspectiveModeEnabled)),
        isLocked = store.selectedElements.filter((e => e.locked)).length > 0;

    const [xPadding, setXPadding] = useState(100);
    const [yPadding, setYPadding] = useState(100)
    // Effect when change the background .width .height
    useEffect( () => {
        const scaleWidth = (dimension.width - 10) / page.width,
        scaleHeight = (dimension.height - 90) / page.height,
        scaleRatio = Math.min(scaleWidth, scaleHeight);

        setXPadding(Math.max(5, (dimension.width - page.width * scaleRatio) / 2));
        setYPadding(Math.max(45, (dimension.height - page.height * scaleRatio) / 2));
        page.setScale(scaleRatio);
        page._setScaleToFit(scaleRatio);
    }, [page.width, page.height, page.background]);

    useEffect(
        (() => {

        if(elementPerspectiveModeEnabled) return;
        var firstSelectedElement = store.selectedElements[0];
        var transformerRefLayer = transformerRef.current.getLayer();

        if (!transformerRef.current) return;
        const currentStage = transformerRef.current.getStage();
        const currentSelected = store.selectedElements.map(e => {
            return (e._cropModeEnabled ) ? null : currentStage.findOne("#" + e.id);
        }).filter((e => e));
        const elementType = 1 === store.selectedElements.length && (null === firstSelectedElement || undefined === firstSelectedElement ? undefined : firstSelectedElement.type) || "many";
        if(isLocked) {
            transformerRef.current.enabledAnchors([]), transformerRef.current.rotateEnabled(!1)
        } else if (transformerAttributes[elementType])
        {
            transformerRef.current.setAttrs( {...{...DEFAULT_TRANSFORMER_ATTRIBUTES, ...transformerAttributes[elementType]}}), 
            "svg" !== elementType || store.selectedElements[0].keepRatio || transformerRef.current.setAttrs({
                enabledAnchors: DEFAULT_TRANSFORMER_ATTRIBUTES.enabledAnchors
            })
        } else 
            transformerRef.current.setAttrs(DEFAULT_TRANSFORMER_ATTRIBUTES); 
        transformerRef.current.nodes(currentSelected), null === transformerRefLayer || void 0 === transformerRefLayer || transformerRefLayer.batchDraw()
    }), 
    [store.selectedElements, elementCropModeEnabled, elementPerspectiveModeEnabled, isLocked]);
    
    // Perspecitve mode
    useEffect(
        (() => {

        if(!elementPerspectiveModeEnabled)  return;
        //var firstSelectedElement = store.selectedElements[0];

        var perspectiveTransformerRefLayer = perspectiveTransformerRef.current.getLayer();

        if (!perspectiveTransformerRef.current) return;
        
        const currentStage = perspectiveTransformerRef.current.getStage();
        const currentSelected = store.selectedElements.map(
            e => {
                return (!e._perspectiveModeEnabled ) ? null : currentStage.findOne("#" + e.id) 
            }
        ).filter(e => e);
        //const elementType = 1 === store.selectedElements.length && (null === firstSelectedElement || undefined === firstSelectedElement ? undefined : firstSelectedElement.type) || "many";
        perspectiveTransformerRef.current.setAttrs( transformerAttributes["many"] )
        perspectiveTransformerRef.current.nodes(currentSelected);
        // batch Draw
        null === perspectiveTransformerRefLayer || undefined === perspectiveTransformerRefLayer || perspectiveTransformerRefLayer.batchDraw()
    }), 
    [store.selectedElements, elementCropModeEnabled, elementPerspectiveModeEnabled, isLocked]);

    const g = useLocalObservable((() => ({
            visible: !1,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0
        }))),
        p = useRef(!1),
        handleStageMouseDown = action((r => {
            var targetStage = r.target.getStage();
            var stage = r.target.getStage();
            p.current = !1, store.activePage !== page && page.select();
            const a = r.target.findAncestor(".elements-container"),
                l = r.target.findAncestor("Transformer"),
                i = r.target.findAncestor(".page-abs-container");
            if (a || l || i) return;
            const c = targetStage?.getPointerPosition();
            c && (g.visible = !0, g.x1 = c.x, g.y1 = c.y, g.x2 = c.x, g.y2 = c.y, 
                stage?.getPointersPositions().length >= 2 && (g.visible = !1))
        }));
    useEffect((() => {
        const t = action((e => {
                var t, r = stageRef.current;
                if (!g.visible) return;
                null === (t = stageRef.current) || undefined === t || t.setPointersPositions(e);
                let n = (null === r || undefined === r ? void 0 : r.getPointerPosition()) || {
                    x: g.x2,
                    y: g.y2
                };
                g.x2 = n.x, g.y2 = n.y
            })),
            r = action((() => {
                if (!g.visible) return;
                if (!stageRef.current) return;
                const t = stageRef.current.findOne(".selection"),
                    r = t ? t.getClientRect() : {
                        width: 0,
                        height: 0,
                        x: 0,
                        y: 0
                    };
                if (r.width && r.height) {
                    const t = [];
                    stageRef.current.find(".element").forEach((n => {
                        const o = n.getClientRect(),
                            a = store.getElementById(n.id()),
                            l = null == a ? void 0 : a.locked;
                        Konva.Util.haveIntersection(r, o) && !l && t.push(n.id())
                    })), store.selectElements(t)
                }
                g.visible = !1, p.current = !0
            }));
        return window.addEventListener("mousemove", t), window.addEventListener("touchmove", t), window.addEventListener("mouseup", r), window.addEventListener("touchend", r), () => {
            window.removeEventListener("mousemove", t), window.removeEventListener("touchmove", t), window.removeEventListener("mouseup", r), window.removeEventListener("touchend", r)
        }
    }), []);
    const handleStageClick = t => {
        if (p.current) return;
        const r = t.evt.ctrlKey || t.evt.metaKey || t.evt.shiftKey,
            elementContainer = t.target.findAncestor(".elements-container"),
            pageAbsContainer = t.target.findAncestor(".page-abs-container"),
            transformer = t.target.findAncestor("Transformer");
        if (!(r || elementContainer || transformer || pageAbsContainer)) {
            store.selectElements([])
            return
        }
        const l = t.target.findAncestor(".element", !0),
            selectedIndex = store.selectedElementsIds.indexOf(l?.id()) >= 0;
        l && r && !selectedIndex && store.selectElements(store.selectedElementsIds.concat([l.id()])), 
        l && r && selectedIndex && store.selectElements(store.selectedElementsIds.filter((e => e !== l.id()))), 
        !l || r || selectedIndex || store.selectElements([l.id()])
    };
    useSnap(transformerRef)
    return <div
        ref= {containerRef}
        onDragOver= {e => e.preventDefault()}
        onDrop= {t => {
            if (t.preventDefault(), !stageRef.current) return;
            stageRef.current.setPointersPositions(t);
            const elementContainer = stageRef.current.findOne(".elements-container").getRelativePointerPosition(),
                stagePointer = stageRef.current.getPointerPosition(),
                stageIntersection = stageRef.current.getIntersection(stagePointer),
                ancest = stageIntersection && stageIntersection.findAncestor(".element", !0),
                ancestElement = ancest ? store.getElementById(ancest.id()) : void 0;
            onDomDrop && (onDomDrop(elementContainer, ancestElement), onDomDrop = null)
        }}
        style= {{
            position: "relative",
            width: dimension.width + "px"
        }}
    > 
    <Stage
        ref= {stageRef}
        width= {dimension.width}
        height= {dimension.height}
        onClick= {handleStageClick}
        onTap= {handleStageClick}
        onMouseDown= {handleStageMouseDown}
        onDragStart= {t => {
            // Need more work! 
               
            if (t.target.hasName("element")) {
                const tagetElementId = t.target.id();
                if(!(store.selectedElementsIds.indexOf(tagetElementId) >= 0) && tagetElementId){
                    store.selectElements([tagetElementId]);
                    var transformer = elementPerspectiveModeEnabled ?
                        perspectiveTransformerRef.current: transformerRef.current;
                    transformer?.startDrag(t)
                }
            }
        }}
        pageId= {page.id}
        style= {{
            position: "relative"
        }}> 
        <Layer> 
            <Background width= {dimension.width} height= {dimension.height} /> 
            <Group
                x= {xPadding}
                y= {yPadding}
                scaleX= {page.scale}
                scaleY= {page.scale}
                name= "page-container" > 
                <PageBackground
                    width= {page.width}
                    height= {page.height}
                    background= {page.background}
                    shadowBlur= {10}
                    shadowColor= "lightgrey"
                    name= "page-background"
                    preventDefault= {!1}
                /> 
                <Group
                    clipX= {0}
                    clipY= {0}
                    clipWidth= {page.width}
                    clipHeight= {page.height}
                    name= "elements-container">
                    <Elements
                        elements= {page.children}
                        store= {store}
                    />
                </Group>
            </Group> 

            <Group
                x= {xPadding}
                y= {yPadding}
                scaleX= {page.scale}
                scaleY= {page.scale}
                name= "page-abs-container">
                {!elementPerspectiveModeEnabled && <Transformer
                    ref= {transformerRef}
                    boundBoxFunc= {(oldBox, newBox) => {
                        const isNewBox = newBox.width < 1 || newBox.height < 1,
                            isOldBox = oldBox.width < 1 || oldBox.height < 1;
                        return isNewBox && !isOldBox ? oldBox : newBox
                    }}
                />}
                {elementPerspectiveModeEnabled && <PerspectiveTransformer
                    ref= {perspectiveTransformerRef}
                    borderDash = {[10,10]}
                    boundBoxFunc= {(oldBox, newBox) => {
                        const isNewBox = newBox.width < 1 || newBox.height < 1,
                            isOldBox = oldBox.width < 1 || oldBox.height < 1;
                        return isNewBox && !isOldBox ? oldBox : newBox
                    }}
                />}

            </Group> 
            <Selection
                selection= {g}
            /> 
            </Layer>
        </Stage>

        {isPageControlled && <div
            style= {{
                position: "absolute",
                top: yPadding - 40 + "px",
                right: xPadding + "px"
            }}>  
            {hasMorePage && <Tooltip2 
                content= "Move up"
                disabled= {0 === indexOfPage}> 
                <Button
                    icon= "chevron-up"
                    minimal= {!0}
                    disabled= {0 === indexOfPage}
                    onClick= {() => {
                        page.setZIndex(indexOfPage - 1)
                    }}
                />
            </Tooltip2>
            }

            {hasMorePage && <Tooltip2
                content= "Move down"
                disabled= {indexOfPage === store.pages.length - 1}>
                <Button
                    icon= "chevron-down"
                    minimal= {!0}
                    disabled= {indexOfPage === store.pages.length - 1}
                    onClick= {() => {
                        const r = store.pages.indexOf(page);
                        page.setZIndex(r + 1)
                    }}
                />
                </Tooltip2>
            }
            
            <Tooltip2
                content= "Duplicate page"
            ><Button
                icon= "duplicate"
                minimal= {!0}
                onClick= {() => {
                    page.clone()
                }}
            /></Tooltip2> 
            
            {hasMorePage && <Tooltip2 content= "Remove page">
                <Button
                    icon= "trash"
                    minimal= {!0}
                    onClick= {() => {
                        store.deletePages([page.id])
                    }}
                />
                </Tooltip2>
            }
            
            <Tooltip2 content= "Add new page">
                <Button
                icon= "insert"
                minimal= {!0}
                onClick= {() => {
                    //@ts-ignore
                    const r = store.addPage(),
                        n = store.pages.indexOf(page);
                    r.setZIndex(n + 1)
                }}
                /></Tooltip2>
            </div>
        }
    </div>
})