import { Button, Tab, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { isMobile } from "../utils/screen";
import styled from "../utils/styled"
import swr from "swr"
import { API } from "../utils/base-url";
import { getKey } from "../utils/validate-key";
import {t} from "../utils/l10n"
import { ImagesGrid } from "./images-grid";
import { registerNextDomDrop } from "../canvas/page";

const Container  = styled('div')
`
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;

  .bp3-dark & .polotno-text-preview-plain {
    filter: invert(1);
  }
`;

const toBase64 = ele => new Promise(((resolve, reject) => {
    const fileReader = new FileReader;
    fileReader.readAsDataURL(ele); 
    fileReader.onload = () => resolve(fileReader.result), 
    fileReader.onerror = error => reject(error);
}))

const FontItem = observer(({
    onSelect, onRemove, font
  }  ) => <div
    style= {{
      height: "100px",
      cursor: "pointer",
      boxShadow: "0 0 5px rgba(16, 22, 26, 0.3)",
      borderRadius: "5px",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      position: "relative",
      fontFamily: font.fontFamily,
      fontSize: 25,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "column",
      textAlign: "center",
      color: "white",
      marginBottom: "10px"
    }}
    onClick= {onSelect}> { font.fontFamily} {" text"}
    <Button
    style= {{
      position: "absolute",
      right: 0,
      bottom: 0
    }}
    minimal= {!0}
    icon= {"trash"}
    onClick= {evt => {
      evt.stopPropagation(), 
      onRemove()
    }}
    ></Button>
    </div>
)

const DragButton = ({onSelect, ...rest }) => {
    return <Button 
      {...rest}
      draggable= {!0}
      className= "polotno-close-panel"
      onClick= {() => onSelect()}
      onDragStart= {() => {
        registerNextDomDrop((({
          x: e,
          y: o
        }) => {
          onSelect({
            x: e,
            y: o
          })
        }))
      }}
      onDragEnd= { e => {
        registerNextDomDrop(null)
      }}
    />
  };

export const TextPanel = observer(({ store } ) => {
    useEffect((() => {
      store.loadFont("Roboto")
    }), []);
    const selectHandler = t => {
      var o;
      const a = t.width || store.width / 2,
        n = ((null == t ? void 0 : t.x) || store.width / 2) - a / 2,
        l = ((null == t ? void 0 : t.y) || store.height / 2) - t.fontSize / 2,
        i = null === (o = store.activePage) || void 0 === o ? void 0 : o.addElement(Object.assign(Object.assign({
          type: "text",
          fontFamily: "Roboto"
        }, t), {
          x: n,
          y: l,
          width: a
        }));
      isMobile() || null == i || i.toggleEditMode(!0)
    }

    useEffect((() => {
      store.fonts.forEach((st => store.loadFont(st.fontFamily)))
    }), [store.fonts]);
    
    const {
      data,
      error
    } = swr(`${API}/get-text-templates?KEY=${getKey()}`);
    const [text, setText] = useState("text");
    return <div
      style= {{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Tabs
          large= {!0}
          //@ts-ignore
          onChange= {evt => setText(evt)}> 
          <Tab id= "text"> {t("sidePanel.text")} </Tab> 
          <Tab id ="font"> {t("sidePanel.myFonts")} </Tab> 
      </Tabs>
        {"text" === text && <Container>
            <DragButton
              style= {{
                  marginBottom: "5px",
                  width: "100%",
                  fontSize: "25px",
                  fontFamily: "Roboto"
              }}
              minimal= {!0}
              onSelect= {elm => {
              selectHandler(
                {...elm, ...{
                  fontSize: 125,
                  text: "HEADER TEXT",
                  fontFamily: "Roboto"
                }}
              )}}> 
              {"Create HEADER"}
            </DragButton> 

            <DragButton
            style= {{
                marginBottom: "5px",
                width: "100%",
                fontSize: "18px",
                fontFamily: "Roboto"
            }}
            minimal= {!0}
            onSelect= {e => {
              selectHandler(Object.assign(Object.assign({}, e), {
                fontSize: 90,
                text: "SUB HEADER TEXT",
                fontFamily: "Roboto"
                }))
            }}> 
              {"Create SUB HEADER"}
            </DragButton> 
            
            <DragButton 
            style= {{
                marginBottom: "5px",
                width: "100%",
                fontSize: "14px",
                fontFamily: "Roboto"
            }}
            minimal= {!0}
            onSelect= {e => {
              selectHandler(Object.assign(Object.assign({}, e), {
                fontSize: 80,
                text: "Body text",
                fontFamily: "Roboto"
                }))
            }}> 
                {"Create body text"}
            </DragButton>
            {/* disabled this function */}
            {false && <ImagesGrid
                shadowEnabled = {!1}
                images= {null == data ? undefined : data.items}
                getPreview= {e => e.preview}
                getImageClassName= {e => e.json.indexOf("plain") >= 0 ? "polotno-text-preview-plain" : ""}
                isLoading= {!data}
                onSelect= {async (t, o) => {
                  const a = await fetch(t.json),
                    n = await a.json(),
                    l = o ? o.x - n.width / 2 : store.width / 2 - n.width / 2,
                    i = o ? o.y - n.height / 2 : store.height / 2 - n.height / 2;
                  store.history.transaction((() => {
                    const t = n.pages[0].children,
                      o = [];
                    t.forEach((t => {
                      var a;
                      delete t.id;
                      const {
                        id: n
                      } = null === (a = store.activePage) || void 0 === a ? void 0 : a.addElement(Object.assign(Object.assign({}, t), {
                        x: t.x + l,
                        y: t.y + i
                      }));
                      o.push(n)
                    })), store.selectElements(o)
                  }))
                }}
            /> 
            }
        </Container>
        }
        
        {"font" === text && <div
          style= {{
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 50px)"
          }}> 
          <label htmlFor= "polotno-font-upload" > 
          <Button
          icon= "upload"
          style= {{
              width: "100%"
          }}
          onClick= {() => {
              var e;
              null === (e = document.querySelector("#polotno-font-upload")) || void 0 === e || e.click()
          }}> {t("sidePanel.uploadFont")}</Button> 
          <input
          type= {"file"}
          accept= {".ttf, .otf, .woff, .woff2, .eot"}
          id= "polotno-font-upload"
          style= {{
              display: "none"
          }}
          onChange= {async ({target}) => {
              
              for (const t of target.files) {
                  const o = await toBase64(t),
                      a = t.name.split(".")[0];
                  store.addFont({
                      fontFamily: a,
                      url: target
                  })
              }
              target.value = null
          }}
          />
          </label> 
          
          <div
              style= {{
                  paddingTop: "20px",
                  overflow: "auto",
                  height: "100%"
              }}> 
              {store.fonts.map(((o, a) => <FontItem
                  font= {o}
                  key= {a}
                  onSelect= {() => {
                    selectHandler({
                      fontSize: 80,
                      text: "Cool curved text",
                      fontFamily: o.fontFamily
                      })
                  }}
                  onRemove= {() => {
                      store.removeFont(o.fontFamily)
                  }}
              />
              ))
              }
            </div>
        </div>
        }
    </div>
  }
)