import { nanoid } from "nanoid";
import { applySnapshot, cast, destroy, detach, getParentOfType, getSnapshot, Instance, isAlive, onSnapshot, types } from "mobx-state-tree";
import  UndoManager  from "./undo-manager";

import { fixSize, getColors, replaceColors, urlToString } from "../utils/svg";
import { validateKey } from "../utils/validate-key";
import { stages } from "konva/lib/Stage";
import { downloadFile } from "../utils/download";
import { uploadFile } from "../utils/upload";
import { getJsPDF } from "../utils/pdf";
import { whenLoaded } from "../utils/loader";
import { globalFonts, injectCustomFont, injectGoogleFont, loadFont } from "../utils/fonts";

const Point = types.model("Point", {
    x: 0,
    y: 0
})

export const Element = types.model("Element", {
    id: types.identifier,
    type: "none",
    x: 0,
    y: 0,
    rotation: 0,
    opacity: 0.6,
    locked: !1,
    blurEnabled: !1,
    blurRadius: 10,
    brightnessEnabled: !1,
    brightness: 0,
    sepiaEnabled: !1,
    grayscaleEnabled: !1,
    shadowEnabled: !1,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: "black",
    custom: types.frozen()
}).preProcessSnapshot((snapshot ) => {
    const newSnapshot  = {
        ...snapshot,
        x: snapshot.x || 1,
        y: snapshot.y || 1
    }
    if("width" in snapshot) newSnapshot.width = snapshot.width || 1; 
    if("height" in snapshot) newSnapshot.height = snapshot.height || 1;
    return newSnapshot;
}).postProcessSnapshot((snapshot) => {
    const newSnapshot= { ...snapshot };
    const returnSnapshot = {};
    for (var snap in newSnapshot) if ("_" !== snap[0])  returnSnapshot[snap] = snapshot[snap];
    return returnSnapshot
}).views(self => ({
    get page() {
        return getParentOfType(self, Page)
    },
    get store() {
        return getParentOfType(self, Store)
    }
}))
.actions( (self ) => ({
    toJSON : () =>    ({...(getSnapshot(self))})
}))
.actions( (self ) => ({
    clone(attrs){
        const jsonElement = self.toJSON();
        attrs.id = attrs.id || nanoid(10)
        Object.assign(jsonElement, attrs)
        return self.page.addElement(jsonElement)
    },
    set(element ) {
        Object.assign(self, element)
    },
    moveUp() {
        self.page.moveElementUp(self.id)
    },
    moveTop() {
        self.page.moveElementTop(self.id)
    },
    moveDown() {
        self.page.moveElementDown(self.id)
    },
    moveBottom() {
        self.page.moveElementBottom(self.id)
    },
    beforeDestroy() {
        self.store.history.endTransaction()
    }
}))


export const TextElement = Element
.named("Text")
.props({
    type: "text",
    text: "",
    placeholder: "",
    fontSize: 14,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "",
    fill: "black",
    align: "center",
    width: 100,
    height: 14,
    strokeWidth: 0,
    stroke: "black",
    lineHeight: types.optional(types.union(types.number, types.string), 1.2),
    letterSpacing: 0,
    _editModeEnabled: !1
}).actions(self => ({
    toggleEditMode(mode) {
        self._editModeEnabled = null != mode ? mode : !self._editModeEnabled, 
        self._editModeEnabled ? self.store.history.startTransaction() : self.store.history.endTransaction()
    }
}))

export const ImageElement = Element
.named("Image")
.props({
    type: "image",
    width: 100,
    height: 100,
    src: "",
    cropX: 0,
    cropY: 0,
    cropWidth: 1,
    cropHeight: 1,
    flipX: !1,
    flipY: !1,
    clipSrc: "",
    borderColor: "black",
    borderSize: 0,
    _cropModeEnabled: !1,
    _perspectiveModeEnabled: !1,
    /*perspectTopLeft: types.reference(Point),
    perspectTopRight: types.reference(Point),
    perspectBottomLeft: types.reference(Point),
    perspectBottomRight: types.reference(Point),*/
}).actions(self => ({
    toggleCropMode(cropped) {
        self._cropModeEnabled = null != cropped ? cropped : !self._cropModeEnabled, 
        self._cropModeEnabled ? self.store.history.startTransaction() : self.store.history.endTransaction()
    },
    togglePerspectiveMode(perspectived) {
        self._perspectiveModeEnabled = null != perspectived ? perspectived : !self._perspectiveModeEnabled, 
        self._perspectiveModeEnabled ? self.store.history.startTransaction() : self.store.history.endTransaction()
    }
}));

const SVGElement = Element
.named("SVG")
.props({
    type: "svg",
    src: "",
    maskSrc: "",
    __svgString: "",
    cropX: 0,
    cropY: 0,
    cropWidth: 1,
    cropHeight: 1,
    keepRatio: !0,
    flipX: !1,
    flipY: !1,
    width: 100,
    height: 100,
    borderColor: "black",
    borderSize: 0,
    colorsReplace: types.map(types.string)
}).preProcessSnapshot(snapshot => ({...{ ...snapshot, ...{
        src: snapshot.src || snapshot.svgSource
}}}))
.views(self => {
    return {
        get colors() {
            return self.__svgString ? getColors(self.__svgString) : []
        },
        get __finalSrc() {
            return self.__svgString ? replaceColors(self.__svgString, self.colorsReplace) : this.src
        },
        get __isLoaded() {
            if (!self.__svgString) return !1;
            return !(Array.from(self.colorsReplace.keys()).length > 0) || this.__finalSrc !== self.src
        }
    }
})
.actions(self => {
    let onSnap = () => {};
    return {
        async _loadSVG() {
            if (!self.src) return;
            const urlString = await urlToString(self.src);
            isAlive(self) && self.store.history.ignore((() => {
                self.set({
                    __svgString: fixSize(urlString)
                })
            }))
        },
        async afterCreate() {
            this._loadSVG();
            let src = self.src;
            onSnap = onSnapshot(self, (snap => {
                snap.src === src && self.__svgString || (this._loadSVG(), src = self.src)
            }))
        },
        beforeDestroy() {
            onSnap()
        },
        replaceColor(oldColor, newColor) {
            self.colorsReplace.set(oldColor, newColor)
        }
    }
})



const TYPES_MAP = {
    svg: SVGElement,
    text: TextElement,
    image: ImageElement
},
ADDITIONAL_TYPES = [];

export function registerShapeModel(defaultAttributes) {
    const type = defaultAttributes.type;
    if (!type) throw new Error('You must pass "type" attribute to custom model.');
    const props = Element.named(type).props(defaultAttributes);
    TYPES_MAP[type] = props;
    ADDITIONAL_TYPES.push(props);
}

const additionalTypesUnion = 
        [...new Array(20)].map((index, type) => types.late(() => ADDITIONAL_TYPES[type]));

const ElementTypes = types.union({ dispatcher: element => TYPES_MAP[element.type] }, 
    SVGElement, TextElement, ImageElement, ...additionalTypesUnion );

const ChildrenType = types.array(ElementTypes);

export const Page = types.model("Page", {
    id: types.identifier,
    children: ChildrenType,
    background: "white",
    custom: types.frozen(), 
    width: 1920,
    height: 1080,
    scale: 1,
    scaleToFit: 1,
}).views((self => ({
    get store() {
        return getParentOfType(self, Store)
    }
}))).actions((self => ({
    toJSON: () => ({...(getSnapshot(self))}),
    clone(attrs) {
        const children = self.children.map((element => {
                const childrenJson = element.toJSON();
                childrenJson.id = nanoid(10);
                return childrenJson
            }));
        const page = {...{...{
                id: nanoid(10),
                children: children,
                background: self.background
            }, ...attrs}}
        const  addedPage = self.store.addPage(page);
        const  indexedPage = self.store.pages.indexOf(self);
        addedPage.setZIndex(indexedPage + 1);
        addedPage.select();
    },
    setZIndex(zIndex) {
        self.store.setPageZIndex(self.id, zIndex);
    },
    set(attrs) {
        Object.assign(self, attrs);
    },
    select() {
        self.store.selectPage(self.id)
    },
    addElement(attrs) {
        const type = TYPES_MAP[attrs.type];
        if (!type) return void console.error("Can not find model with type " + attrs.type);
        const newEl = type.create({...{
            id: nanoid(10)
        }, ...attrs});
        self.children.push(newEl);
        self.store.selectElements([newEl.id]);
        return newEl;
    },
    moveElementUp(id) {
        const childIndex = self.children.findIndex((elem => elem.id === id)),
            child = self.children[childIndex];
        detach(child), 
        self.children.remove(child), 
        self.children.splice(childIndex + 1, 0, child)
    },
    moveElementDown(id) {
        const childIndex = self.children.findIndex((e => e.id === id)),
            child = self.children[childIndex];
        detach(child), 
        self.children.remove(child), 
        self.children.splice(childIndex - 1, 0, child)
    },
    moveElementTop(id) {
        const childIndex = self.children.findIndex((e => e.id === id)),
            child = self.children[childIndex];
        detach(child), 
        self.children.remove(child), 
        self.children.push(child)
    },
    moveElementBottom(id) {
        const childIndex = self.children.findIndex((e => e.id === id)),
            child = self.children[childIndex];
        detach(child), self.children.remove(child), 
        self.children.splice(0, 0, child);
    },
    setSize(newWidth, newHeight, magicScale) {
        if (magicScale) {
            const scaleX = newWidth / self.width,
                scaleY = newHeight / self.height;
            Math.min(scaleX, scaleY);
            for (const t of self.pages)
                for (const e of t.children) e.set({
                    x: e.x * scaleX,
                    y: e.y * scaleY
                }), "text" === e.type ? e.set({
                    fontSize: e.fontSize * scaleX,
                    width: Math.max(e.width * scaleX, 2)
                }) : e.set({
                    width: Math.max(e.width * scaleX, 2),
                    height: Math.max(e.height * scaleY, 2)
                })
        }
        
        self.width = newWidth, self.height = newHeight
    },

    setScale(t) {
        self.scale = t
    },
    _setScaleToFit(t) {
        self.scaleToFit = t
    },
})))

export const Font = types.model("Font", {
    fontFamily: types.string,
    url: types.optional(types.string, ""),
    styles: types.frozen()
}).preProcessSnapshot(snapshot =>  
    ({ ...snapshot, ...{ fontFamily: snapshot.fontFamily || snapshot.name }})
);

export const Store = types.model("Store", {
    pages: types.array(Page),
    fonts: types.array(Font),
    width: 1920,
    height: 1080,
    scale: 1,
    scaleToFit: 1,
    selectedElementsIds: types.array(types.string),
    history: types.optional(UndoManager, {
        targetPath: "../pages"
    }),
    _showCredit: !1,
    _activePageId: ""
}).views((self => ({
    get selectedElements() {
        return self.selectedElementsIds.map((t => {
            for (const o of self.pages)
                for (const e of o.children)
                    if (e.id === t) return e
        })).filter((e => !!e))
    },
    get activePage() {
        return self.pages.slice().find((t => t.id === self._activePageId)) || (self.pages.length ? self.pages[0] : null)
    }
}))).actions((self => ({
    async __checkKey(t, o) {
        const r = await validateKey(t);
        //@ts-ignore
        self.showCredit(r, o)
    },
    showCredit(t, o) {
        self._showCredit = !t || o
    },
    getElementById(t) {
        for (const o of self.pages)
            for (const e of o.children)
                if (e.id === t) return e
    },
    addPage(attrs) {
        console.log("add Page");
        const page = Page.create({...{...{
            id: nanoid(10)
        }, ...attrs}}
        );
        self.pages.push(page), 
        self._activePageId = page.id 
        return page;
    },
    selectPage(t) {
        self._activePageId = t
    },
    selectElements(t) {
        self.selectedElementsIds = cast(t)
    },
    setScale(t) {
        self.scale = t
    },
    _setScaleToFit(t) {
        self.scaleToFit = t
    },
    setSize(t, o, r) {
        if (r) {
            const r = t / self.width,
                s = o / self.height;
            Math.min(r, s);
            for (const t of self.pages)
                for (const e of t.children) e.set({
                    x: e.x * r,
                    y: e.y * s
                }), "text" === e.type ? e.set({
                    fontSize: e.fontSize * r,
                    width: Math.max(e.width * r, 2)
                }) : e.set({
                    width: Math.max(e.width * r, 2),
                    height: Math.max(e.height * s, 2)
                })
        }
        self.width = t, self.height = o
    },
    setPageZIndex(t, o) {
        const r = self.pages.find((e => e.id === t));
        r && (detach(r), self.pages.remove(r), self.pages.splice(o, 0, r))
    },
    deletePages(t) {
        t.forEach((t => {
            const o = self.pages.find((e => e.id === t));
            destroy(o)
        }))
    },
    deleteElements(t) {
        t.forEach((t => {
            self.pages.forEach((e => {
                const o = e.children.find((e => e.id === t));
                o && destroy(o)
            }))
        })), self.selectedElementsIds = cast([])
    },
    on(t, o) {
        if ("change" === t) return onSnapshot(self, (e => {
            o(e)
        }))
    },
    toDataURL({ pixelRatio,
        ignoreBackground,
        page,
        mimeType
    }) {

        const pixRatio = pixelRatio || 1,
        stage = stages.find((e => e.getAttr("pageId") === page.id)) || stages[0];
        if (!stage) throw new Error("Export is failed. Looks like <Workspace /> component is not mounted, but it is required in order to process the export.");
        const pageContainer = stage.findOne(".page-container");
        stage.find("Transformer").forEach((e => e.visible(!1)));
        pageContainer.findOne(".page-background").shadowEnabled(!1);
        pageContainer.findOne(".page-background").strokeEnabled(!1); 
        pageContainer.find(".highlighter").forEach((e => e.visible(!1)));
        ignoreBackground && pageContainer.findOne(".page-background").hide();

        const d = pageContainer.toDataURL({
            x: pageContainer.x(),
            y: pageContainer.y(),
            width: page.width * pageContainer.scaleX(),
            height: page.height * pageContainer.scaleY(),
            pixelRatio: 2 * pixRatio, //1 / pageContainer.scaleX() * pixRatio,
            mimeType: mimeType
        });
        if(ignoreBackground) pageContainer.findOne(".page-background").show(); 
        pageContainer.findOne(".page-background").shadowEnabled(!0), 
        pageContainer.findOne(".page-background").strokeEnabled(!0), 
        stage.find("Transformer").forEach((e => e.visible(!0))), 
        pageContainer.find(".highlighter").forEach((e => e.visible(!0)));
        return d;
    },
    saveAsImage({fileName, ...rest } = {}) {
        const mimeType = rest.mimeType || "image/png",
        ext = mimeType.split("/")[1];
        downloadFile(this.toDataURL(rest), fileName || "pure-electric." + ext, mimeType)
    },

    async uploadAsImage({fileName, ...rest } = {}) {
        const mimeType = rest.mimeType || "image/png",
        ext = mimeType.split("/")[1];
        const { page } = rest;
        const id = page.id;
        await uploadFile(this.toDataURL(rest), page.id, mimeType);
    },
    async _toPDF(t) {
        //@ts-ignore
        var o = new(await getJsPDF())({
                unit: "px",
                orientation: self.width > self.height ? "landscape" : "portrait",
                format: [self.width, self.height]
            }),
            r = o.internal.pageSize.getWidth(),
            s = o.internal.pageSize.getHeight();
        self.pages.forEach(((a, n) => {
            0 !== n && o.addPage(), o.addImage(this.toDataURL(Object.assign(Object.assign({}, t), {
                pageId: a.id
            })), 0, 0, r, s, void 0, "SLOW")
        })); 
        return  o
    },
    //@ts-ignore
    toPDFDataURL: async t => (await self._toPDF(Object.assign({
        mimeType: "jpg"
    }, t))).output("datauristring"),

    async saveAsPDF({fileName, ...rest } = {} ) {
        //@ts-ignore
        (await self._toPDF({...{ mimeType: "jpg"}, ...rest}))
                .save(fileName || "polotno.pdf");
    },
    async waitLoading() {
        await whenLoaded()
    },
    toJSON: () => ({
        width: self.width,
        height: self.height,
        fonts: getSnapshot(self.fonts),
        pages: getSnapshot(self.pages)
    }),
    loadJSON(t, o = !1) {
        var firstPage = t.pages[0];
        self.pages.forEach((e => e.children.forEach((e => detach(e))))), self.pages = cast([]), 
        t._activePageId = null === firstPage || undefined === firstPage ? undefined : firstPage.id, 

        t.scale = self.scale, t._isKeyValid = self._isKeyValid, 
        //@ts-ignore
        o && (t.history = self.history.toJSON()),
        applySnapshot(self, t)
    },
    addFont(t) {
        this.removeFont(t.fontFamily), 
        self.fonts.push(t), this.loadFont(t.fontFamily)
    },
    removeFont(t) {
        self.fonts.filter((e => e.fontFamily === t)).forEach((e => destroy(e)))
    },
    async loadFont(t) {
        const o = self.fonts.find((e => e.fontFamily === t)) || globalFonts.find((e => e.fontFamily === t));
        o ? injectCustomFont(o) : injectGoogleFont(t), await loadFont(t)
    }
})))

export function createStore({key,showCredit} = {
    key: "",
    showCredit: !1
}) {
    const store = Store.create();
    store.__checkKey(key, showCredit); 
    return store;
}

/*export type StoreType = Instance<typeof Store>;
export type ElementType = Instance<typeof Element>;
export type TextElementType = Instance<typeof TextElement>;
export type ImageElementType = Instance<typeof ImageElement>;
export type SVGElementType = Instance<typeof SVGElement>;
export type PageType = Instance<typeof Page>;
*/