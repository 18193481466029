import { observer } from 'mobx-react-lite';
import { StoreType } from '../model/store';
import React, { Fragment, useEffect, useRef } from "react";
import { Alignment, Button, Navbar } from '@blueprintjs/core';
import FlipButton from './flip-button';
import FiltersPicker from './filters-picker';
import {t as translation } from "../utils/l10n";
import RotateButton from './rotate-button';
/*type Props = {
    store: StoreType;
    hideImageFlip?: boolean;
    hideImageEffects?: boolean;
    hideImageCrop?: boolean;
    hideImageFit?: boolean;
};
*/
export const ImageToolbar = observer(({
    store,
    hideImageFlip,
    hideImageEffects,
    hideImageRotate,
    hideImageCrop,
    hideImageFit,
    hideImagePerspective
}/*: Props*/) => {
    const selectedElement = store.selectedElements[0],
        cropModeEnabled = selectedElement._cropModeEnabled,
        perspectiveModeEnabled = selectedElement._perspectiveModeEnabled,
        cropInfo = useRef({}),
        perspectiveInfo = useRef({});
    useEffect((() => {
        cropModeEnabled && (cropInfo.current = {
            x: selectedElement.x,
            y: selectedElement.y,
            width: selectedElement.width,
            height: selectedElement.height,
            cropX: selectedElement.cropX,
            cropY: selectedElement.cropY,
            cropWidth: selectedElement.cropWidth,
            cropHeight: selectedElement.cropHeight
        })
    }), [cropModeEnabled]);

    useEffect((() => {
        perspectiveModeEnabled && (perspectiveInfo.current = {
            // Store Current in case cancel 
        })
    }), [perspectiveModeEnabled]);
   
    return  <Navbar.Group
        align= {Alignment.LEFT}> 
    {!cropModeEnabled && !perspectiveModeEnabled && <Fragment> 

        {!hideImageRotate && <RotateButton
            element= {selectedElement}
            store= {store}
        />}

        {!hideImageFlip && <FlipButton  element= {selectedElement} />}
        
        {!hideImageEffects && <FiltersPicker
            element= {selectedElement}
            store= {store}
        />}
        
        {!hideImageFit && <Button
            text= {translation("toolbar.fitToBackground")}
            minimal= {!0}
            onClick= {() => {
                selectedElement.set({
                    x: 0,
                    y: 0,
                    width: store.width,
                    height: store.height,
                    rotation: 0
                })
            }}
        />
        } 
        
        {!hideImageCrop && <Button
            text= {translation("toolbar.crop")}
            minimal= {!0}
            onClickCapture= {e => {
                e.stopPropagation(), 
                selectedElement.toggleCropMode(true)
            }}
        />}

        {!hideImagePerspective && <Button
            text= {"Perspective"}
            minimal= {!0}
            onClickCapture= {e => {
                e.stopPropagation(), 
                selectedElement.togglePerspectiveMode(true)
            }}
        />}
        
        </Fragment>
    } 
    
    {cropModeEnabled && <Fragment> <Button
            text= {translation("toolbar.cropDone")}
            minimal= {!0}
            icon= "tick"
            onClickCapture= {e => {
                selectedElement.toggleCropMode(!1)
            }}
        /> 
        <Button
            text= {translation("toolbar.cropCancel")}
            minimal= {!0}
            icon= "cross"
            onClickCapture= {e => {
                selectedElement.set({...cropInfo.current}), 
                selectedElement.toggleCropMode(!1)
            }}
        />
        </Fragment>
    }

    {perspectiveModeEnabled && <Fragment> <Button
            text= {translation("toolbar.cropDone")}
            minimal= {!0}
            icon= "tick"
            onClickCapture= {e => {
                selectedElement.togglePerspectiveMode(!1)
            }}
        /> 
        <Button
            text= {translation("toolbar.cropCancel")}
            minimal= {!0}
            icon= "cross"
            onClickCapture= {e => {
                selectedElement.set({...perspectiveInfo.current}), 
                selectedElement.togglePerspectiveMode(!1)
            }}
        />
        </Fragment>
    }

    </Navbar.Group>
})

export default ImageToolbar;