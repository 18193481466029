/*!
Copyright 2021 Adonmo  https://www.adonmo.com/
Copyright 2010 futomi  http://www.html5.jp/
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
This file was modified by Fabien LOISON <http://www.flozz.fr/>
This file was further modified by Adonmo https://www.adonmo.com/
*/
export default class Perspective {
    constructor(ctxd, image) {
        if (!ctxd || !ctxd.strokeStyle) {
            return;
        }
        if (!image || !image.width || !image.height) {
            return;
        }
        let cvso = document.createElement("canvas");
        cvso.width = Math.round(typeof image.width === "number" ? image.width : image.width.baseVal.value);
        cvso.height = Math.round(typeof image.height === "number" ? image.height : image.height.baseVal.value);
        let ctxo = cvso.getContext("2d");
        ctxo.drawImage(image, 0, 0, cvso.width, cvso.height);
        let cvst = document.createElement("canvas");
        cvst.width = ctxd.canvas.width;
        cvst.height = ctxd.canvas.height;
        let ctxt = cvst.getContext("2d");
        this.ctxd = ctxd;
        this.cvso = cvso;
        this.ctxo = ctxo;
        this.ctxt = ctxt;
    }
    draw(q) {
        let { topLeftX, topLeftY, topRightX, topRightY, bottomRightX, bottomRightY, bottomLeftX, bottomLeftY, } = q;
        let dims = [
            Math.sqrt(Math.pow(topLeftX - topRightX, 2) + Math.pow(topLeftY - topRightY, 2)),
            Math.sqrt(Math.pow(topRightX - bottomRightX, 2) +
                Math.pow(topRightY - bottomRightY, 2)),
            Math.sqrt(Math.pow(bottomRightX - bottomLeftX, 2) +
                Math.pow(bottomRightY - bottomLeftY, 2)),
            Math.sqrt(Math.pow(bottomLeftX - topLeftX, 2) +
                Math.pow(bottomLeftY - topLeftY, 2)),
        ];
        let ow = this.cvso.width;
        let oh = this.cvso.height;
        let base_index = 0;
        let max_scale_rate = 0;
        let zero_num = 0;
        for (let i = 0; i < 4; i++) {
            let rate = 0;
            if (i % 2) {
                rate = dims[i] / ow;
            }
            else {
                rate = dims[i] / oh;
            }
            if (rate > max_scale_rate) {
                base_index = i;
                max_scale_rate = rate;
            }
            if (dims[i] == 0) {
                zero_num++;
            }
        }
        if (zero_num > 1) {
            return;
        }
        let step = 2;
        let cover_step = step * 5;
        let ctxo = this.ctxo;
        let ctxt = this.ctxt;
        ctxt.canvas.height = Math.max(topLeftY, topRightY, bottomLeftY, bottomRightY) - Math.min(topLeftY, topRightY, bottomLeftY, bottomRightY, 0);
        ctxt.canvas.width = Math.max(topLeftX, topRightX, bottomLeftX, bottomRightX) - Math.min(topLeftX, topRightX, bottomLeftX, bottomRightX, 0);
        ctxt.clearRect(0, 0, ctxt.canvas.width, ctxt.canvas.height);
        if (base_index % 2 == 0) {
            let ctxl = this.create_canvas_context(ow, cover_step);
            ctxl.globalCompositeOperation = "copy";
            let cvsl = ctxl.canvas;
            for (let y = 0; y < oh; y += step) {
                let r = y / oh;
                let sx = topLeftX + (bottomLeftX - topLeftX) * r;
                let sy = topLeftY + (bottomLeftY - topLeftY) * r;
                let ex = topRightX + (bottomRightX - topRightX) * r;
                let ey = topRightY + (bottomRightY - topRightY) * r;
                let ag = Math.atan((ey - sy) / (ex - sx));
                let sc = Math.sqrt(Math.pow(ex - sx, 2) + Math.pow(ey - sy, 2)) / ow;
                ctxl.setTransform(1, 0, 0, 1, 0, -y);
                ctxl.drawImage(ctxo.canvas, 0, 0);
                ctxt.translate(sx, sy);
                ctxt.rotate(ag);
                ctxt.scale(sc, sc);
                ctxt.drawImage(cvsl, 0, 0);
                ctxt.setTransform(1, 0, 0, 1, 0, 0);
            }
        }
        else if (base_index % 2 == 1) {
            let ctxl = this.create_canvas_context(cover_step, oh);
            ctxl.globalCompositeOperation = "copy";
            let cvsl = ctxl.canvas;
            for (let x = 0; x < ow; x += step) {
                let r = x / ow;
                let sx = topLeftX + (topRightX - topLeftX) * r;
                let sy = topLeftY + (topRightY - topLeftY) * r;
                let ex = bottomLeftX + (bottomRightX - bottomLeftX) * r;
                let ey = bottomLeftY + (bottomRightY - bottomLeftY) * r;
                let ag = Math.atan((sx - ex) / (ey - sy));
                let sc = Math.sqrt(Math.pow(ex - sx, 2) + Math.pow(ey - sy, 2)) / oh;
                ctxl.setTransform(1, 0, 0, 1, -x, 0);
                ctxl.drawImage(ctxo.canvas, 0, 0);
                ctxt.translate(sx, sy);
                ctxt.rotate(ag);
                ctxt.scale(sc, sc);
                ctxt.drawImage(cvsl, 0, 0);
                ctxt.setTransform(1, 0, 0, 1, 0, 0);
            }
        }
        this.ctxd.save();
        this.ctxd.drawImage(ctxt.canvas, 0, 0);
        this.ctxd.restore();
    }
    create_canvas_context(w, h) {
        let canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        let ctx = canvas.getContext("2d");
        return ctx;
    }
    _applyMask(ctx, q) {
        ctx.beginPath();
        ctx.moveTo(q.topLeftX, q.topLeftY);
        ctx.lineTo(q.topRightX, q.topRightY);
        ctx.lineTo(q.bottomRightX, q.bottomRightY);
        ctx.lineTo(q.bottomLeftX, q.bottomLeftY);
        ctx.closePath();
        ctx.globalCompositeOperation = "destination-in";
        ctx.fill();
        ctx.globalCompositeOperation = "source-over";
    }
}
