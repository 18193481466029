const url = "https://unpkg.com/jspdf@2.2.0/dist/jspdf.umd.min.js";
let jsPDF;

export function getJsPDF() {
    return jsPDF ? Promise.resolve(jsPDF) : new Promise((resolve => {
        var script = document.createElement("script");
        script.onload = function () {
            // @ts-ignore
            jsPDF = window.jspdf.jsPDF;
            resolve(jsPDF)
        }
        script.src = url; 
        document.head.appendChild(script)
    }))
}