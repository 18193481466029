import { StoreType } from '../model/store';

export const handleHotkey= (e:any, t:any) => {
    var o, l, d:any;
    if ("INPUT" === (null === (o = document.activeElement) || void 0 === o ? void 0 : o.tagName) || "TEXTAREA" === (null === (l = document.activeElement) || void 0 === l ? void 0 : l.tagName) || "true" === (null === (d = document.activeElement) || void 0 === d ? void 0 : d.contentEditable)) return;
    46 !== e.keyCode && 8 !== e.keyCode || t.deleteElements(t.selectedElementsIds);
    const n = e.ctrlKey || e.metaKey,
        r = e.shiftKey;
    n && "KeyZ" === e.code && (e.preventDefault(), t.history.undo()), n && r && "KeyZ" === e.code && (e.preventDefault(), t.history.redo()), "ArrowDown" === e.code && (e.preventDefault(), t.selectedElements.forEach(((e:any) => {
        e.set({
            y: e.y + 1
        })
    }))), "ArrowUp" === e.code && (e.preventDefault(), t.selectedElements.forEach(((e:any) => {
        e.set({
            y: e.y - 1
        })
    }))), "ArrowLeft" === e.code && (e.preventDefault(), t.selectedElements.forEach(((e:any) => {
        e.set({
            x: e.x - 1
        })
    }))), "ArrowRight" === e.code && (e.preventDefault(), t.selectedElements.forEach(((e:any) => {
        e.set({
            x: e.x + 1
        })
    })))
}