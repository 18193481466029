import { Alignment, Button, NumericInput, Position, Slider, Switch } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { ElementType, StoreType } from "../model/store";
import {t as translate} from "../utils/l10n"
import { ColorPicker } from "./color-picker";
const limit = (e, t, r) => Math.max(t, Math.min(r, e));

export const FiltersPicker  = observer(({element, store }) => {
    const r = "text" === element.type,
        a = "image" === element.type,
        l = "svg" === element.type;
    return <Popover2
        content= {<div
            style= {{
                padding: "15px",
                width: "200px"
            }}> 
            <Switch
                checked= {element.blurEnabled}
                label= {translate("toolbar.blur")}
                onChange= {t => {
                    element.set({
                        //@ts-ignore
                        blurEnabled: t.target.checked
                    })
                }}
                alignIndicator= {Alignment.RIGHT}
            /> 
            {element.blurEnabled && <Slider
                value= {element.blurRadius}
                labelStepSize= {5}
                onChange= {t => {
                    element.set({
                        blurRadius: t
                    })
                }}
                min= {0}
                max= {20}
                showTrackFill= {!1}
            />} 
            
            <Switch
                checked= {element.brightnessEnabled}
                label= {translate("toolbar.brightness")}
                onChange= {t => {
                    element.set({
                        //@ts-ignore
                        brightnessEnabled: t.target.checked
                    })
                }}
                alignIndicator= {Alignment.RIGHT}
                style= {{
                    marginTop: "20px"
                }}
            /> 
            {element.brightnessEnabled && <Slider
                value= {100 * element.brightness + 100}
                onChange= {t => {
                    element.set({
                        brightness: (t - 100) / 100
                    })
                }}
                min= {0}
                max= {200}
                showTrackFill= {!1}
                labelStepSize= {50}
            />} 
            
            <Switch
                checked= {element.sepiaEnabled}
                label= {translate("toolbar.sepia")}
                onChange= {t => {
                    element.set({
                        //@ts-ignore
                        sepiaEnabled: t.target.checked
                    })
                }}
                alignIndicator= {Alignment.RIGHT}
                style= {{
                    marginTop: "20px"
                }}
            /> 
            <Switch
                checked= {element.grayscaleEnabled}
                label= {translate("toolbar.grayscale")}
                onChange= {t => {
                    element.set({
                        //@ts-ignore
                        grayscaleEnabled: t.target.checked
                    })
                }}
                alignIndicator= {Alignment.RIGHT}
                style= {{
                    marginTop: "20px"
                }}
            /> 
            {r && <Switch
            //@ts-ignore
                checked= {!!element.strokeWidth}
                label= {translate("toolbar.textStroke")}
                onChange= {t => {
                    element.set({
                        //@ts-ignore
                        strokeWidth: t.target.checked ? 2 : 0
                    })
                }}
                alignIndicator= {Alignment.RIGHT}
                style= {{
                    marginTop: "20px"
                }}
            />}
            {/*@ts-ignore*/}
            {!!element.strokeWidth && <Fragment> <div
                style= {{ display: "flex", justifyContent: "space-between" }}> 
                {/*@ts-ignore*/}
                <ColorPicker
                //@ts-ignore
                    value= {element.stroke}
                    size= {30}
                    onChange= {t => {
                        element.set({
                            stroke: t
                        })
                    }}
                    store= {store}
                />
                <NumericInput
                //@ts-ignore
                    defaultValue= {element.strokeWidth}
                    onValueChange= {t => {
                        element.set({
                            strokeWidth: limit(t, 1, 30)
                        })
                    }}
                    style= {{
                        width: "80px"
                    }}
                    min= {1}
                    max= {30}
                /></div>
                </Fragment>
            }

            {(a || l) && <Switch
            //@ts-ignore
                checked= {!!element.borderSize}
                label= {translate("toolbar.border")}
                onChange= {t => {
                    element.set({
                        //@ts-ignore
                        borderSize: t.target.checked ? 2 : 0
                    })
                }}
                alignIndicator= {Alignment.RIGHT}
                style= {{
                    marginTop: "20px"
                }}
            />}
            {/*@ts-ignore*/}
            {!!element.borderSize && <Fragment> 
                <div style= {{
                    display: "flex",
                    justifyContent: "space-between"
                }}> 
                {/*@ts-ignore*/}
                <ColorPicker   
                //@ts-ignore
                    value= {element.borderColor}
                    size= {30}
                    onChange= {t => {
                        element.set({
                            borderColor: t
                        })
                    }}
                    store= {store}
                /> 
                <NumericInput
                //@ts-ignore
                    defaultValue= {element.borderSize}
                    onValueChange= {t => {
                        element.set({
                            borderSize: limit(t, 1, 30)
                        })
                    }}
                    style= {{
                        width: "80px"
                    }}
                    min= {1}
                    max= {30}
                /></div>
                </Fragment>
            }
            
            <Switch
                checked= {element.shadowEnabled}
                label= {translate("toolbar.shadow")}
                onChange= {t => {
                    element.set({
                        //@ts-ignore
                        shadowEnabled: t.target.checked
                    })
                }}
                alignIndicator= {Alignment.RIGHT}
                style= {{
                    marginTop: "20px"
                }}
            /> 
            {element.shadowEnabled && <Fragment>
            <Slider
                value= {element.shadowBlur}
                onChange= {t => {
                    element.set({
                        shadowBlur: t
                    })
                }}
                min= {0}
                max= {50}
                showTrackFill= {!1}
                labelRenderer= {!1}
            /></Fragment>}

        </div>}
        position= {Position.BOTTOM}
        > 
        <Button
            icon= "left-join"
            text= {translate("toolbar.effects")}
            minimal= {!0}
        />
        
        </Popover2>
})

export default FiltersPicker;