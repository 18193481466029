import queryString from 'query-string'

export function getRecord(){
    const value = queryString.parse(location.search);
    const record= value.record;
    return record;
}
export function getQuoteId(){
    const value = queryString.parse(location.search);
    const record= value.quote_id;
    return record;
}
export function getSubDomain(){
    var full = window.location.host
    var parts = full.split('.')
    var sub = parts[0];
    return sub;
}
