import { Icon } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react"
import { t } from "../utils/l10n";
import { PhotosPanel } from "./photos-panel";
import { SectionTab } from "./tab-button";
import { TemplatesPanel } from "./templates-panel";
import FaShapes from '@meronex/icons/fa/FaShapes';
import { ElementsPanel } from "./elements-panel";
import { UploadPanel } from "./upload-panel";
import { BackgroundPanel } from "./background-panel";
import FdPageMultiple from '@meronex/icons/fd/FdPageMultiple';
import styled  from "../utils/styled";
import { useMobile } from "../utils/screen";
import { SizePanel } from "./size-panel";
import { PagesPanel } from "./pages-panel";
import { TextPanel } from "./text-panel";
export const TemplatesSection = {
    name: "templates",
    Tab: observer((props) => (
        //@ts-ignore
        <SectionTab
            name={t("sidePanel.templates")}
            {...props}>
            <Icon icon = "control"></Icon>
        </SectionTab>
    )),
    //@ts-ignore
    Panel: ({store}) => <TemplatesPanel store={store} />
}

export const TextSection = {
    name: "text",
    Tab: observer((props) => (
        //@ts-ignore
        <SectionTab
            name={t("sidePanel.text")}
            {...props}>
            <Icon icon = "new-text-box"></Icon>
        </SectionTab>
    )),
    //@ts-ignore
    Panel: ({store}) => <TextPanel store={store} />
}

export const PhotosSection = {
    name: "photos",
    //@ts-ignore
    Tab: observer(e => <SectionTab 
        {...{...{
            name: t("sidePanel.photos")
        }, ...e}}> 
        <Icon icon= "media" />
        </SectionTab>),
    Panel: ({
      store
    }) => <PhotosPanel 
      store = {store}
    />
}

export const ElementsSection = {
    name: "elements",
    Tab: observer((e => 
        //@ts-ignore
        <SectionTab 
            name= {t("sidePanel.elements")}
            iconSize= {16}
            {...e}>
            <FaShapes />
        </SectionTab>)),
    Panel: ({ store }) => <ElementsPanel store= {store} />
}

export const UploadSection = {
    name: "upload",
    
    Tab: observer(e => 
        //@ts-ignore
        <SectionTab
            name= {t("sidePanel.upload")}
            {...e}>
            <Icon icon= "cloud-upload"/>
        </SectionTab>),
    Panel: ({
        store: e
        }) => <UploadPanel
        store= {e}
    />
}

export const BackgroundSection = {
    name: "background",
    //@ts-ignore
    Tab: observer(e => <SectionTab
        name= {t("sidePanel.background")}
        {...e}>
            <Icon icon= "layout-grid" />
        </SectionTab>),
        //@ts-ignore
    Panel: ({ store: e  }) => <BackgroundPanel store= {e} />
  }
export const PagesSection = {
    name: "pages",
    //@ts-ignore
    Tab: observer((e => <SectionTab 
        name= {t("sidePanel.pages")}
        {...e}> 
        <FdPageMultiple /></SectionTab>)),
        //@ts-ignore
    Panel: ({ store }) => <PagesPanel store = {e} />
    
  }

export const SizeSection = {
    name: "size",
    //@ts-ignore
    Tab: observer(e => <SectionTab
      name= {t("sidePanel.resize")}
        {...e}> <Icon
            icon= "fullscreen"
    /></SectionTab>),
    Panel: ({
      store: e
    }) => <SizePanel
    //@ts-ignore
      store= {e}
    />
  }

export const DEFAULT_SECTIONS = [TemplatesSection, TextSection, PhotosSection, ElementsSection, UploadSection, BackgroundSection, SizeSection];

const SidePanelContainer = styled("div")
`
  display: flex;
  height: 100%;
  padding: 0px;

  @media screen and (max-width: 500px) {
    height: auto;
    width: 100%;
    position: relative;
  }
`, TabsWrap = styled("div")
`
  @media screen and (max-width: 500px) {
    width: 100%;
    overflow: auto;
  }
`, TabsContainer = styled("div")
`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    flex-direction: row;
    min-width: min-content;
  }
`, PanelContainer = styled("div")
`
  padding: 10px;
  width: 100%;
  height: 100% !important;

  @media screen and (max-width: 500px) {
    position: absolute;
    bottom: 54px;
    z-index: 100;
    height: 50vh !important;
  }
`, MobileOverlay = styled("div")
`
  display: none;

  @media screen and (max-width: 500px) {
    position: absolute;
    bottom: 72px;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
//@ts-ignore
const SidePanel = observer((({ store: e, sections: t, defaultSection: a }) => {
    var n;
    const [r, o] = useState(a || "photos"),
    l = t || DEFAULT_SECTIONS, i = null === (n = l.find((e => e.name === r))) || void 0 === n ? void 0 : n.Panel, s = useMobile();
    useEffect((() => {
      o(s ? "" : a || "photos")
    }), [s]);

    return <SidePanelContainer className= "bp3-navbar polotno-side-panel"> 
      <TabsWrap>
        <TabsContainer
            className= "polotno-side-tabs-container">
            {l.map((({
                name: e,
                Tab: t
                }) => React.createElement(t, {
                key: e,
                active: e === r,
                onClick: () => {
                    o(e)
                }
                })
            ))
            }
        </TabsContainer> 
        </TabsWrap>
        {i && <PanelContainer
            className= "bp3-navbar polotno-panel-container"
            onClick= {e => {
                //@ts-ignore
                e.target.closest(".polotno-close-panel") && s && o("")
            }}
            > 
            {React.createElement(i, {
            store: e
            })}
        </PanelContainer>
        } 
                
        {r && React.createElement(MobileOverlay, {
            onClick: () => o("")
            })
        }
        
  </SidePanelContainer>
}))

export default SidePanel ;